import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { getAreaDetails } from '../../../services/area'
import { searchUser } from '../../../services/user'
import { CLASS_LEVEL } from '../../../constants'
import { EVENTS_UI_CONFIG } from '../../../data/event'

export default class BasicInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			name: props.userDetails.name || '',
			initiatedName: props.userDetails.initiatedName || '',
			email: props.userDetails.email || '',
			phone: props.userDetails.phone || '',
			connectedAreaId: props.userDetails.connectedAreaId,
			facilitator: props.userDetails.facilitator,
			chantingRounds: props.userDetails.chantingRounds || 0,
			userCategory: props.userDetails.userCategory || 'YOUTH',
			sadhnaGroup:
				props.userDetails.userCategory === 'YOUTH'
					? props.userDetails.sadhnaGroup || 1
					: 0,
			eventId: props.eventId,
		}
		this.disabled = !!props.userDetails.email
	}

	componentDidMount() {
		const { userDetails } = this.props
		if (userDetails?.connectedAreaId) {
			getAreaDetails({ id: userDetails.connectedAreaId }).then((res) => {
				this.setState({
					connectedAreaId: res?.data?.data && res.data.data[0],
					facilitator:
						res?.data?.data &&
						res.data.data[0]?.facilitators.find(
							(f) => f._id === userDetails.facilitator
						),
				})
			})
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.userDetails.name !== prevProps.userDetails.name) {
			this.setState({
				name: this.props.userDetails.name,
				initiatedName: this.props.userDetails.initiatedName,
				email: this.props.userDetails.email,
				phone: this.props.userDetails.phone,
				connectedAreaId: this.props.userDetails.connectedAreaId,
				facilitator: this.props.userDetails.facilitator,
				chantingRounds: this.props.userDetails.chantingRounds,
				userCategory: this.props.userDetails.userCategory,
			})
		}
	}

	onInputChange = (event, value, type, reason) => {
		const { mentor, facilitator, connectedAreaId } = this.state
		//response from api
		if (reason !== 'clear') {
			switch (type) {
				case 'connectedAreaId':
					if (mentor?._id || mentor) {
						getAreaDetails({
							facilitator: facilitator?._id || facilitator,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					} else {
						getAreaDetails({ per_page: 100 }).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					}
					break
				case 'facilitator':
					if (connectedAreaId?._id || connectedAreaId) {
						searchUser({
							q: value,
							userRole: '3,4',
							areaId: connectedAreaId?._id || connectedAreaId,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					} else {
						searchUser({
							q: value,
							userRole: '3,4',
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					}
					break
				case 'mentor':
					searchUser({ q: value, userRole: 3, per_page: 100 }).then(
						(res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						}
					)
					break
				default:
					getAreaDetails({ per_page: 100 }).then((res) => {
						this.setState({
							[`options-${type}`]: res.data.data,
						})
					})
			}
		} else {
			const resetObj = {
				'options-mentor': [],
				'options-facilitator': [],
				'options-connectedAreaId': [],
			}
			if (type === 'connectedAreaId') {
				this.setState({
					...resetObj,
					facilitator: '',
				})
			} else if (type === 'facilitator') {
				this.setState({
					...resetObj,
					connectedAreaId: '',
				})
			} else {
				this.setState(resetObj)
			}
		}
	}

	onChange = (name, value) => {
		const { handleChange } = this.props
		this.setState({
			[name]: value,
		})
		handleChange({
			target: { name, value },
		})
	}

	render() {
		const {
			name,
			initiatedName,
			email,
			phone,
			connectedAreaId,
			facilitator,
			chantingRounds,
			userCategory,
			sadhnaGroup,
			eventId,
		} = this.state
		const { errors, touched, handleBlur, userDetails } = this.props
		return (
			<>
				<TextField
					label="Name"
					variant="outlined"
					defaultValue={name}
					TabPa
					value={name}
					name="name"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					onBlur={(e) => handleBlur(e)}
					error={touched.name && errors.name}
					helperText={
						touched.name &&
						errors.name && <span>{errors.name}</span>
					}
					fullWidth
				/>
				<TextField
					label="Initiated Name"
					variant="outlined"
					defaultValue={initiatedName}
					TabPa
					value={initiatedName}
					name="initiatedName"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					onBlur={(e) => handleBlur(e)}
					error={touched.initiatedName && errors.initiatedName}
					helperText={
						touched.initiatedName &&
						errors.initiatedName && (
							<span>{errors.initiatedName}</span>
						)
					}
					fullWidth
				/>
				<TextField
					label="Email"
					variant="outlined"
					defaultValue={email}
					value={email}
					name="email"
					disabled={this.disabled}
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					onBlur={(e) => handleBlur(e)}
					error={touched.email && errors.email}
					helperText={
						touched.email &&
						errors.email && <span>{errors.email}</span>
					}
					fullWidth
				/>
				<TextField
					label="Phone"
					variant="outlined"
					defaultValue={phone}
					value={phone}
					name="phone"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					onBlur={(e) => handleBlur(e)}
					error={touched.phone && errors.phone}
					helperText={
						touched.phone &&
						errors.phone && <span>{errors.phone}</span>
					}
					type="number"
					fullWidth
				/>

				<Autocomplete
					options={this.state[`options-connectedAreaId`] || []}
					getOptionLabel={(option) => option.name || ''}
					defaultValue={connectedAreaId}
					filterSelectedOptions
					onInputChange={(e, val, reason) =>
						this.onInputChange(e, val, 'connectedAreaId', reason)
					}
					value={connectedAreaId}
					onChange={(e, newValue) => {
						this.onChange('connectedAreaId', newValue)
					}}
					onBlur={(e, newValue) => {
						handleBlur({
							target: {
								name: 'connectedAreaId',
								value: newValue,
							},
						})
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Connected Area"
							placeholder="Start typing Area Name"
							error={
								touched.connectedAreaId &&
								errors.connectedAreaId
							}
							helperText={
								touched.connectedAreaId &&
								errors.connectedAreaId && (
									<span>{errors.connectedAreaId}</span>
								)
							}
						/>
					)}
				/>
				<Autocomplete
					options={this.state[`options-facilitator`] || []}
					getOptionLabel={(option) =>
						option.initiatedName || option.name
							? `${option.initiatedName || option.name}` +
							  (option.phone ? ` (${option.phone})` : '')
							: option.initiatedName || option.name
					}
					defaultValue={facilitator}
					filterSelectedOptions
					onInputChange={(e, val, reason) =>
						this.onInputChange(e, val, 'facilitator', reason)
					}
					value={facilitator}
					onChange={(e, newValue) => {
						this.onChange('facilitator', newValue)
					}}
					onBlur={(e, newValue) => {
						handleBlur({
							target: { name: 'facilitator', value: newValue },
						})
					}}
					disabled={!connectedAreaId}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Facilitator"
							placeholder="Start typing name"
							error={touched.facilitator && errors.facilitator}
							helperText={
								touched.facilitator &&
								errors.facilitator && (
									<span>{errors.facilitator}</span>
								)
							}
						/>
					)}
				/>
				<span>
					Please note that your chanting rounds must be atleast 2
					rounds/day to register in Viplava
				</span>
				<TextField
					label="Chanting Rounds (Eligibility: altleast 2 rounds daily)"
					variant="outlined"
					defaultValue={chantingRounds}
					TabPa
					value={chantingRounds}
					name="chantingRounds"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					onBlur={(e) => handleBlur(e)}
					type="number"
					error={touched.chantingRounds && errors.chantingRounds}
					helperText={
						touched.chantingRounds &&
						errors.chantingRounds && (
							<span>{errors.chantingRounds}</span>
						)
					}
					fullWidth
				/>
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="user-type-select-label">
						User Type
					</InputLabel>
					<Select
						labelId="user-type-select-label"
						id="user-type-select"
						value={userCategory}
						label="User Type"
						onChange={(e) => {
							this.onChange('userCategory', e.target.value)
							if (e.target.value !== 'YOUTH') {
								this.onChange('sadhnaGroup', 0)
							} else {
								this.onChange('sadhnaGroup', 1)
							}
						}}
					>
						<MenuItem value={'YOUTH'}>YOUTH</MenuItem>
						<MenuItem value={'BRAHMACHARI'}>BRAHMACHARI</MenuItem>
						{/* <MenuItem value={'PARENTS'}>PARENTS</MenuItem> */}
					</Select>
				</FormControl>
				{userCategory === 'YOUTH' && (
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="demo-simple-select-outlined-label">
							Class Level
						</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							value={sadhnaGroup}
							onChange={(e) =>
								this.onChange(e.target.name, e.target.value)
							}
							label="Class Level"
							name="sadhnaGroup"
						>
							{Object.keys(CLASS_LEVEL).map((key) => (
								<MenuItem value={key}>
									{CLASS_LEVEL[key]}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="demo-simple-select-outlined-label">
						Camp
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={eventId}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						label="Camp"
						name="eventId"
					>
						{EVENTS_UI_CONFIG.map((key) => (
							<MenuItem value={key.uuid}>{key.title}</MenuItem>
						))}
					</Select>
				</FormControl>
				<Button
					variant="contained"
					color="primary"
					onClick={() =>
						this.props.onSubmit({
							name,
							initiatedName,
							email,
							phone,
							connectedAreaId:
								connectedAreaId?._id || connectedAreaId,
							facilitator: facilitator?._id || facilitator,
							chantingRounds,
							userRole: userDetails.userRole || 0,
							userCategory: userDetails.userCategory,
							sadhnaGroup: userDetails.sadhnaGroup,
							eventId,
						})
					}
				>
					NEXT
				</Button>
			</>
		)
	}
}
