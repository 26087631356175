import {
	GET_USERS_STARTED,
	GET_USERS_SUCCESS,
	GET_USERS_FAILED,
	UPDATE_USER_STARTED,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILED,
	DELETE_USER_STARTED,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAILED,
} from "../constants/actions";
import { ALERT } from "../constants";

let initialState = {
	users: [],
	loading: false,
	error: null,
	alert: null,
	limit: 10000,
};
const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_USERS_STARTED:
			return {
				...state,
				loading: true,
			};
		case GET_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				users: action.payload,
				total_results: action.total_results,
			};
		case GET_USERS_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			};
		case UPDATE_USER_STARTED:
		case DELETE_USER_STARTED:
			return {
				...state,
				loading: true,
			};
		case UPDATE_USER_SUCCESS: {
			const users = state.users.map((user) =>
				user._id === action.payload._id ? { ...user, ...action.payload } : user
			);
			return {
				...state,
				loading: false,
				users,
			};
		}
		case DELETE_USER_SUCCESS: {
			const users = state.users.filter((user) =>
				user._id !== action.payload._id
			);
			return {
				...state,
				loading: false,
				users,
			};	
		}
		case UPDATE_USER_FAILED:
		case DELETE_USER_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			};
		default:
			return state;
	}
};

export default userReducer;
