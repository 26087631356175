import { InputAdornment, Paper, TextField } from '@material-ui/core'
import { DataGrid, GridSearchIcon } from '@material-ui/data-grid'
import React from 'react'
import './style.scss'

class Table extends React.Component {
	render() {
		const {
			classes,
			data,
			header,
			searchTerm,
			actionButtons: ActionButtons,
		} = this.props
		return (
			<div className="table-container">
				<Paper className={classes.tableHeader}>
					<div className="table-header-container">
						{searchTerm && (
							<TextField
								id="input-with-icon-textfield"
								label="Search"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<GridSearchIcon />
										</InputAdornment>
									),
								}}
								onChange={(e) => searchTerm(e.target.value)}
								className="search-field"
							/>
						)}
						<ActionButtons />
					</div>
				</Paper>
				<div
					style={{ height: 400, width: '100%', background: 'white' }}
				>
					<DataGrid
						rows={data}
						columns={header.map((col) => ({
							...col,
							disableClickEventBubbling: true,
						}))}
						pageSize={5}
						rowsPerPageOptions={[5, 10, 20]}
						pagination
						filterModel={{ items: [] }}
					/>
				</div>
			</div>
		)
	}
}

export default Table
