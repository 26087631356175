import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { FEST_TYPE } from "../../constants";
import {
	createStaticContent,
	updateStaticContent,
} from "../../services/static";
import { extractYoutubeID } from "../../utils";
import "./style.scss";

class UpdateContentPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fileId: props.fileId || "",
			url: props.url || null,
			festType: props.festType || null,
			name: props.name || "",
			options: [],
		};
	}

	onChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	submitFileData = () => {
		const { fileId, url, festType, name } = this.state;
		const { closeHandler, fileType } = this.props;
		if (name) {
			if (fileId) {
				//update static content
				updateStaticContent(fileId, {
					name: name,
					url: fileType === 2 ? extractYoutubeID(url) : url,
					festType: festType,
				}).then((res) => {
					closeHandler();
				});
			} else {
				//create static content
				createStaticContent({
					name,
					url: fileType === 2 ? extractYoutubeID(url) : url,
					festType,
					fileType,
				}).then((res) => {
					closeHandler();
				});
			}
		}
	};

	render() {
		const { fileId, url, festType, name } = this.state;
		return (
			<form noValidate autoComplete="off">
				<Typography variant="h5">
					{fileId ? "Update File" : "Create File"}
				</Typography>
				<TextField
					className="m-top-16"
					label="Name"
					variant="outlined"
					defaultValue={name}
					value={name}
					name="name"
					onChange={(e) => this.onChange(e.target.name, e.target.value)}
					fullWidth
				/>
				<TextField
					className="m-top-16"
					label="URL"
					variant="outlined"
					defaultValue={url}
					value={url}
					name="url"
					onChange={(e) => this.onChange(e.target.name, e.target.value)}
					fullWidth
				/>
				<FormControl variant="outlined" className="m-top-16" fullWidth>
					<InputLabel id="demo-simple-select-outlined-label">
						Fest Type
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={festType}
						onChange={(e) => this.onChange(e.target.name, e.target.value)}
						label="Fest Type"
						name="festType"
					>
						{Object.keys(FEST_TYPE).map((fest) => (
							<MenuItem value={fest}>{FEST_TYPE[fest]}</MenuItem>
						))}
					</Select>
				</FormControl>
				<Button
					className="m-top-16"
					variant="contained"
					color="primary"
					onClick={this.submitFileData}
				>
					{fileId ? "UPDATE FILE" : "CREATE FILE"}
				</Button>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.areaReducer.loading,
	alert: state.areaReducer.alert,
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(UpdateContentPopup);
