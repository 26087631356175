import { Button, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Component } from "react";
import { searchUser } from "../../services/user";
import { createAreaAction, updateAreaAction } from "../../actions/area";
import "./style.scss";
import { connect } from "react-redux";
import { isGenSecretary } from "../../utils";

class UpdateAreaPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			areaId: props.areaId || "",
			areaLeader: props.areaLeader || null,
			assistantDirector: props.assistantDirector || null,
			facilitators: props.facilitators || [],
			frontliners: props.frontliners || [],
			areaName: props.areaName || "",
			areaCode: props.areaCode || "",
			options: [],
		};
	}

	onInputChange = (event, value) => {
		//response from api
		if (event) {
			searchUser({ q: value, userRole: "1,2,3,4,5,6,7" }).then((res) => {
				this.setState({
					options: res.data.data,
				});
			});
		}
	};

	onChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	submitAreaData = () => {
		const {
			areaId,
			areaLeader,
			assistantDirector,
			facilitators,
			frontliners,
			areaName,
			areaCode,
		} = this.state;
		const { closeHandler, updateAreaAction, createAreaAction } = this.props;
		// const { updateTableDataHandler } = this.props;
		if (areaName && areaCode) {
			if (areaId) {
				//update area
				updateAreaAction({
					areaId,
					name: areaName,
					code: areaCode,
					areaLeader: (areaLeader && areaLeader._id) || areaLeader,
					assistantDirector:
						(assistantDirector && assistantDirector._id) || assistantDirector,
					facilitators: facilitators && facilitators.map((f) => f._id),
					frontliners: frontliners && frontliners.map((f) => f._id),
				}).then((res) => {
					// updateTableDataHandler();
					closeHandler();
				});
			} else {
				//create area
				createAreaAction({
					name: areaName,
					code: areaCode,
					areaLeader: (areaLeader && areaLeader._id) || areaLeader,
					assistantDirector:
						(assistantDirector && assistantDirector._id) || assistantDirector,
					facilitators: facilitators && facilitators.map((f) => f._id),
					frontliners: frontliners && frontliners.map((f) => f._id),
				}).then((res) => {
					closeHandler();
				});
			}
		}
	};

	render() {
		const {
			areaId,
			areaLeader,
			assistantDirector,
			facilitators,
			frontliners,
			areaName,
			areaCode,
			options,
		} = this.state;
		const notGenSecretary = !isGenSecretary();
		return (
			<form noValidate autoComplete="off">
				<Typography>{areaId ? "Update Area" : "Create Area"}</Typography>
				<TextField
					className="m-top-16"
					label="Area Code"
					variant="outlined"
					defaultValue={areaCode}
					value={areaCode}
					name="areaCode"
					onChange={(e) => this.onChange(e.target.name, e.target.value)}
					disabled={notGenSecretary}
					fullWidth
				/>
				<TextField
					className="m-top-16"
					label="Area Name"
					variant="outlined"
					defaultValue={areaName}
					value={areaName}
					name="areaName"
					onChange={(e) => this.onChange(e.target.name, e.target.value)}
					disabled={notGenSecretary}
					fullWidth
				/>
				<Autocomplete
					className="m-top-16"
					options={options}
					getOptionLabel={(option) => option.initiatedName || option.name || ""}
					defaultValue={areaLeader}
					filterSelectedOptions
					onInputChange={this.onInputChange}
					onChange={(e, newValue) => {
						this.onChange("areaLeader", newValue);
					}}
					value={areaLeader}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Area Leader"
							placeholder="Start typing Name/Email"
						/>
					)}
					disabled={notGenSecretary}
				/>
				<Autocomplete
					className="m-top-16"
					options={options}
					getOptionLabel={(option) => option.initiatedName || option.name || ""}
					defaultValue={assistantDirector}
					filterSelectedOptions
					onInputChange={this.onInputChange}
					onChange={(e, newValue) => {
						this.onChange("assistantDirector", newValue);
					}}
					value={assistantDirector}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Assistant Director"
							placeholder="Start typing Name/Email"
						/>
					)}
					disabled={notGenSecretary}
				/>
				<Autocomplete
					multiple
					className="m-top-16"
					options={options.filter(
						(opt) => !facilitators.map((f) => f._id).includes(opt._id)
					)}
					getOptionLabel={(option) =>
						option.initiatedName || option.name
							? `${option.initiatedName || option.name}` +
							  (option.phone ? ` (${option.phone})` : "")
							: option.initiatedName || option.name
					}
					defaultValue={facilitators}
					filterSelectedOptions
					onInputChange={this.onInputChange}
					value={facilitators}
					onChange={(e, newValue) => {
						this.onChange("facilitators", newValue);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Facilitators"
							placeholder="Start typing Name/Email"
						/>
					)}
				/>
				<Autocomplete
					multiple
					className="m-top-16"
					options={options}
					getOptionLabel={(option) =>
						option.initiatedName || option.name
							? `${option.initiatedName || option.name}` +
							  (option.phone ? ` (${option.phone})` : "")
							: option.initiatedName || option.name
					}
					defaultValue={frontliners}
					filterSelectedOptions
					onInputChange={this.onInputChange}
					value={frontliners}
					onChange={(e, newValue) => {
						this.onChange("frontliners", newValue);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Frontliners"
							placeholder="Start typing Name/Email"
						/>
					)}
				/>
				<Button
					className="m-top-16"
					variant="contained"
					color="primary"
					onClick={this.submitAreaData}
				>
					{areaId ? "UPDATE AREA" : "CREATE AREA"}
				</Button>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.areaReducer.loading,
	alert: state.areaReducer.alert,
});

const mapDispatchToProps = {
	updateAreaAction,
	createAreaAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAreaPopup);
