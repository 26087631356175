const Terms = () => {
	return (
		<div className='static-page-container'>
            <h2>Terms and Conditions</h2>
			The website sp-3c (The “SITE”) is fully owned by the
			International Society of Krishna Consciousness (SP3C), Your
			access or use of the Site signifies that you (hereinafter referred
			to as “User”) agree to be bound by these Terms of Use and the
			Privacy Policy. SP3C reserves the right. Your use of the Site is
			governed by the terms, and conditions set forth below.
			<p>
				Please read the following information carefully. By using this
				Site SP3C, you indicate your acceptance of, and agreement to
				be bound by, the terms set forth below. If you do not agree to
				these terms and conditions, please do not use this Site.
			</p>
			<p>
				To revise the Terms and Conditions from time to time, at its
				absolute discretion, without notice, and such revisions shall be
				binding on the User.
			</p>
			<p>
				The Site and Site Material shall not be reproduced, duplicated,
				copied, sold, resold, or otherwise exploited for any commercial
				purpose whatsoever by the User without the express written
				consent of SP3C;
			</p>
			<p>
				Any Site Material copied, downloaded or/and printed by the User
				for personal purposes shall not be modified in any manner
				whatsoever, and is used entirely at the User’s risk;
			</p>
			<p>
				From time to time, the Site may also include links to other
				websites. These links are provided for your convenience and
				enhanced usability. By accessing the Site the User hereby waives
				any claim the User might have against the SP3C with respect
				to such linked websites and third party Site Material.
			</p>
			<p>
				These links do not signify that SP3C endorses the website(s)
				or have any responsibility for the content or functioning of the
				linked website(s) and SP3C shall not be held responsible by
				the User for any claim or loss that occurred or that may occur
				to the User arising out of such linked websites and third party
				Site Material;
			</p>
			<p>
				SP3C shall not be held liable in respect of any claim or loss
				that occurs or may occur to the User or any third party arising
				out of the User’s usage of the Site and Site Material.
			</p>
			<p>Use of SP3C’s Trademarks</p>
			The User shall seek the prior written consent of SP3C before
			using SP3C’s trademarks, including, but not limited to:
			<p>
				These terms and conditions may be changed by SP3C in the
				future. It is your responsibility as a user to periodically
				return to this page to review the terms and conditions for
				amendments. The amended terms shall take effect automatically
				the day they are posted on the site. Your continued use of the
				website following any amendments will constitute agreement to
				such amendments. Your continued usage of the site shall be in
				acceptance of the terms that may be updated in the future with
				retrospective effect. In case you do not agree to any terms your
				sole remedy is to stop using the website.
			</p>
			<h2>Terms & Conditions of Donation</h2>
			<p>
				The website sp-3c (The “SITE”) is fully owned by the
				International Society of Krishna Consciousness (SP3C), East
				of Kailash, Delhi, INDIA.
			</p>
			<p>
				Please read the following information carefully. By using this
				Site or donating through the site, you indicate your acceptance
				of, and agreement to be bound by, the terms set forth below. If
				you do not agree to these terms and conditions, please do not
				use this Site.
			</p>
			<p>
				These terms and conditions may be changed by SP3C in the
				future. It is your responsibility as a user to periodically
				return to this page to review the terms and conditions for
				amendments. The amended terms shall take effect automatically
				the day they are posted on the site. Your continued use of the
				website following any amendments will constitute agreement to
				such amendments. Terms of Service were last revised on July
				28th, 2022.
			</p>
			<p>
				All donations and/or payment made through the Site, the
				acceptance of the same is at the sole discretion of the temple
				management and its trustees. The management reserves its sole
				rights to accept or cancel any donation and/or payment without
				assigning any reason thereof.
			</p>
			<p>
				Please note that donations once made cannot be charged back,
				except in cases of fraudulent use, once investigation is carried
				out by necessary governmental / Bank authorities and proofs
				substantiated. Incase any donation receipt is issued against the
				same, that shall stand cancelled, and any income tax benefit so
				derived shall be null and void.
			</p>
			<p>
				Your continued usage of the site shall be in acceptance of the
				terms that may be updated in the future with retrospective
				effect. In case you do not agree to any terms your sole remedy
				is to stop using the website.
			</p>{' '}
		</div>
	)
}

export default Terms
