/* eslint-disable jsx-a11y/anchor-is-valid */
import { useGoogleLogin } from 'react-google-login'
import { ROUTE } from '../../../../constants/route'
import { useEffect, useRef, useState } from 'react'
import { getQueryObj, serialize } from '../../../../utils'
import { GOOGLE_CLIENT_ID } from '../../../../constants/config'
import { loginByToken } from '../../../../services/auth'
import { getUserByUid } from '../../../../services/viplava'
import { showAlert } from '../../../../actions'
import { ALERT } from '../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import { getEventDetailsAction } from '../../../../actions/event'
import { EVENTS_UI_CONFIG } from '../../../../data/event'
import './css/98-layout.css';
import './css/classic-themes.min.css';
import './css/frontend.css';
import './css/print.css';
import './css/rs6.css';
import './css/style.css';
import './css/style.min.css';
import './css/wpcdt-public.css';
import './css/zn_dynamic.css';
import './css/znb_frontend.css';

const JagannathPuriMayapurCamp = ({ history, location, match }) => {
	const counterRef = useRef(null)
	const [counter, setCounter] = useState({
		days: 0,
		hours: 0,
		min: 0,
		sec: 0,
	})
	let selectedEventId = ''
	const dispatch = useDispatch()
	const onLoginSuccess = (res, eventId) => {
		localStorage.setItem(
			'USER_DETAILS',
			JSON.stringify({
				email: res.profileObj.email,
				name: res.profileObj.name,
				imageUrl: res.profileObj.imageUrl,
			})
		)
		dispatch(
			showAlert({
				message: 'Please wait. Redirecting you to appropriate page.',
				severity: ALERT.INFO,
				duration: 2000,
			})
		)
		getUserByUid({ email: res.profileObj.email })
			.then(({ result }) => {
				if (result.roles.some((role) => role >= 3)) {
					localStorage.setItem('isViplavaFacilitator', 'yes')
					history.push(`${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventId}`)
				} else {
					localStorage.setItem('isViplavaFacilitator', 'no')
					history.push(
						`${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventId}?email=${res.profileObj.email}`
					)
					// setTimeout(function(){
					// 	document.location.href = `${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventId}?email=${res.profileObj.email}`;
					// },250);
					window.location.href = `${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventId}?email=${res.profileObj.email}`
				}
			})
			.catch(() => {
				localStorage.setItem('isViplavaFacilitator', 'no')
				// history.push(`${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventId}?email=${res.profileObj.email}`)
				window.location.href = `${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventId}?email=${res.profileObj.email}`
			})
	}

	const { signIn, loaded } = useGoogleLogin({
		clientId: GOOGLE_CLIENT_ID,
		onSuccess: (res) => onLoginSuccess(res, selectedEventId),
		onFailure: (e) => {
			alert(
				"It seems cookies are not enabled in your browser. Please enable third party cookies and allow popups in browser settings. (One possible reason could be that you're trying to access website in incognito or please check your browser settings)"
			)
		},
	})

	const handleRegisterBtnClick = (eventId, ...args) => {
		const data = getQueryObj(location?.search)
		selectedEventId = eventId
		const userProfile = localStorage.getItem('UserProfile')
		if (
			userProfile &&
			JSON.parse(userProfile).roles.some((role) => role >= 3)
		) {
			localStorage.setItem('isViplavaFacilitator', 'yes')
			history.push(
				`${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventId}${serialize({
					token: data.token,
				})}`
			)
		} else if (data.token) {
			// window.location.href = `${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventId}?token=${data.token}`
			localStorage.setItem('isViplavaFacilitator', 'no')
			history.push(
				`${ROUTE.EVENT_REGISTER_PAGE.path}/${eventId}?token=${data.token}`
			)
		} else {
			signIn(...args)
		}
	}

	useEffect(() => {
		const date = new Date('02/16/2023')
		counterRef.current = setInterval(() => {
			const now = new Date()
			const diff = date - now
			if (typeof setCounter === 'function')
				setCounter((c) => ({
					days: Math.floor(diff / 1000 / 60 / 60 / 24),
					hours: Math.floor((diff / 1000 / 60 / 60) % 24),
					min: Math.floor((diff / 1000 / 60) % 60),
					sec: Math.floor((diff / 1000) % 60),
				}))
		}, 1000)
		const data = getQueryObj(location?.search)
		if (data.token) {
			loginByToken(data.token).then((res) => {
				const userProfile = res.data[0]
				userProfile.loggedIn = true
				localStorage.setItem('UserProfile', JSON.stringify(userProfile))
			})
		}
		return () => {
			clearInterval(counterRef.current)
		}
	}, [])

	const events = useSelector((state) => state.eventReducer)?.events
	useEffect(() => {
		if (!events?.length) {
			dispatch(getEventDetailsAction())
		}
	}, [])
	const campEvents = events.filter((event) => event.status)

	return (
		<div>
			<div className="login_register_stuff" />
			{/* end login register stuff */} <div id="fb-root" />
			<div
				id="zn-nav-overlay"
				className="znNavOvr znNavOvr--layoutS1 znNavOvr--animation1 znNavOvr--theme-light"
			>
				<div className="znNavOvr-inner is-empty">
					<div className="znNavOvr-menuWrapper" />
				</div>
				<a
					href="#"
					className="znNavOvr-close znNavOvr-close--trSmall"
					id="znNavOvr-close"
				>
					<span />
					<svg
						x="0px"
						y="0px"
						width="54px"
						height="54px"
						viewBox="0 0 54 54"
					>
						<circle
							fill="transparent"
							stroke="#656e79"
							strokeWidth={1}
							cx={27}
							cy={27}
							r={25}
							strokeDasharray="157 157"
							strokeDashoffset={157}
						/>
					</svg>
				</a>
			</div>
			<div id="page_wrapper">
				<header
					id="header"
					className="site-header  style5  header--follow    sticky-resize headerstyle--image_color site-header--absolute nav-th--light siteheader-classic siteheader-classic-normal sheader-sh--light"
				>
					<div className="site-header-wrapper sticky-top-area">
						<div className="kl-top-header site-header-main-wrapper clearfix  header-no-top  sh--light">
							<div className="container siteheader-container header--oldstyles">
								<div className="fxb-row fxb-row-col-sm">
									<div className="fxb-col fxb-basis-auto">
										<div className="fxb-row site-header-row site-header-main ">
											<div className="fxb-col fxb fxb-start-x fxb-center-y fxb-basis-auto site-header-col-left site-header-main-left"></div>
											<div className="fxb-col fxb fxb-center-x fxb-center-y fxb-basis-auto site-header-col-center site-header-main-center"></div>
											<div className="fxb-col fxb fxb-end-x fxb-center-y fxb-basis-auto site-header-col-right site-header-main-right">
												<div className="fxb-col fxb fxb-end-x fxb-center-y fxb-basis-auto site-header-main-right-top">
													<div
														className="sh-component main-menu-wrapper"
														role="navigation"
														itemScope="itemscope"
														itemType="https://schema.org/SiteNavigationElement"
													>
														<div className="zn-res-menuwrapper">
															<a
																href="#"
																className="zn-res-trigger zn-menuBurger zn-menuBurger--3--s zn-menuBurger--anim1 "
																id="zn-res-trigger"
															>
																<span />
																<span />
																<span />
															</a>
														</div>
														{/* end responsive menu */}
														<div
															id="main-menu"
															className="main-nav mainnav--overlay mainnav--active-text mainnav--pointer-dash nav-mm--light zn_mega_wrapper "
														>
															<ul
																id="menu-event-conference-menu"
																className="main-menu main-menu-nav zn_mega_menu "
															>
																<li
																	id="menu-item-24"
																	className="main-menu-item menu-item menu-item-type-custom menu-item-object-custom menu-item-24  main-menu-item-top  menu-item-even menu-item-depth-0"
																>
																	<a
																		href="#aboutviplava"
																		className=" main-menu-link main-menu-link-top"
																	>
																		<span>
																			ABOUT
																			EVENT
																		</span>
																	</a>
																</li>
																<li
																	id="menu-item-25"
																	className="main-menu-item menu-item menu-item-type-custom menu-item-object-custom menu-item-25  main-menu-item-top  menu-item-even menu-item-depth-0"
																>
																	<a
																		href="#eventsviplava"
																		className=" main-menu-link main-menu-link-top"
																	>
																		<span>
																			EVENTS
																		</span>
																	</a>
																</li>
																<li
																	id="menu-item-27"
																	className="main-menu-item menu-item menu-item-type-custom menu-item-object-custom menu-item-27  main-menu-item-top  menu-item-even menu-item-depth-0"
																>
																	<a
																		href="#details"
																		className=" main-menu-link main-menu-link-top"
																	>
																		<span>
																			DETAILS
																		</span>
																	</a>
																</li>
																<li
																	id="menu-item-95"
																	className="main-menu-item menu-item menu-item-type-custom menu-item-object-custom menu-item-95  main-menu-item-top  menu-item-even menu-item-depth-0"
																>
																	<a
																		href="https://sp-3c/event/jagannath-puri-mayapur-camp"
																		className=" main-menu-link main-menu-link-top"
																	>
																		<span>
																			REGISTER
																			NOW
																		</span>
																	</a>
																</li>
															</ul>
														</div>{' '}
													</div>
													{/* end main_menu */}
												</div>
											</div>
										</div>
										{/* /.site-header-main */}
									</div>
								</div>
								<div className="separator site-header-separator " />{' '}
							</div>
							{/* /.siteheader-container */}
						</div>
						{/* /.site-header-main-wrapper */}
					</div>
					{/* /.site-header-wrapper */}
				</header>
				<div
					className="zn_pb_wrapper clearfix zn_sortable_content"
					data-droplevel={0}
				>
					{' '}
					<section
						className="zn_section eluidf73c7c54   zn_parallax  section-sidemargins    zn_section--relative section--no "
						id="eluidf73c7c54"
					>
						<div className="zn-bgSource zn-bgSource-imageParallax js-znParallax">
							<div
								className="zn-bgSource-image"
								style={{
									backgroundImage:
										'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Jagannath-Puri-and-Mayapur.jpg)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
								}}
							/>
							<div
								className="zn-bgSource-overlay"
								style={{ backgroundColor: 'rgba(5,0,62,0.7)' }}
							/>
						</div>
						<div className="zn_section_size container zn-section-height--custom_height zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid3151d108            col-md-12 col-sm-12   znColumnElement"
									id="eluid3151d108"
								>
									<div className="znColumnElement-innerWrapper-eluid3151d108 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<p
												className="eluid08c6f97a  dn-heading"
												id="eluid08c6f97a"
												itemProp="headline"
											>
												Annual Yatra 2023
											</p>
											<h1
												className="eluid142ad9e4  dn-heading"
												id="eluid142ad9e4"
												itemProp="headline"
											>
												MAYAPUR &amp; JAGANNATH PURI
											</h1>
											<h1
												className="eluid51c10575  dn-heading"
												id="eluid51c10575"
												itemProp="headline"
											>
												16 - 25 FEB 2023
											</h1>
											<div
												id="eluid483ea049"
												className="zn_buttons_element eluid483ea049 text-center "
											>
												<a
													href="#JMYatra"
													id="eluid483ea0490"
													className="eluid483ea0490 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
												>
													<span>BOOK NOW</span>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluide5ee4e5d     section-sidemargins    section--no "
						id="eluide5ee4e5d"
					>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid93c3ea30            col-md-12 col-sm-12   znColumnElement"
									id="eluid93c3ea30"
								>
									<div className="znColumnElement-innerWrapper-eluid93c3ea30 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluid6c367734  dn-heading"
												id="eluid6c367734"
												itemProp="headline"
											>
												DAYS REMAINING:
											</h3>
											<div
												id="eluid8e6e3cba"
												className="ud_counter kl-counter kl-font-alt eluid8e6e3cba text-center "
											>
												{' '}
												<ul className="th-counter kl-counterlist">
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.days}
														</span>
														<span className="kl-counter-unit ">
															DAYS
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.hours}
														</span>
														<span className="kl-counter-unit ">
															HOURS
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.min}
														</span>
														<span className="kl-counter-unit ">
															MIN
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.sec}
														</span>
														<span className="kl-counter-unit ">
															SEC
														</span>
													</li>{' '}
												</ul>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluide9a224b7     section-sidemargins    section--no "
						id="aboutviplava"
					>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid18fdb7ce            col-md-6 col-sm-6   znColumnElement"
									id="eluid18fdb7ce"
								>
									<div className="znColumnElement-innerWrapper-eluid18fdb7ce znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluidd418f44a  dn-heading"
												id="eluidd418f44a"
												itemProp="headline"
											>
												ABOUT
											</h3>
											<h3
												className="eluidb5d49c25  dn-heading"
												id="eluidb5d49c25"
												itemProp="headline"
											>
												ABOUT YATRA
											</h3>
											<h3
												className="eluidf78ba658  dn-heading"
												id="eluidf78ba658"
												itemProp="headline"
											>
												These excursions and retreats
												are blend of educational thought
												provoking discussions and joyful
												experience of connecting with
												nature. Spiritual activities in
												the serene setting of nature
												gives whole new perspective and
												deeper vision to see our life
											</h3>
											<div
												id="eluidabc0d9b0"
												className="zn_buttons_element eluidabc0d9b0 text-left "
											>
												<a
													href="#JMYatra"
													id="eluidabc0d9b00"
													className="eluidabc0d9b00 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
												>
													<span>Book Now</span>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
								<div
									className="eluid45521b11            col-md-6 col-sm-6   znColumnElement"
									id="eluid45521b11"
								>
									<div className="znColumnElement-innerWrapper-eluid45521b11 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="media-container eluidd816dbd6  media-container--type-btn kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Mayapur-Jagannath-Puri-Yatra.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(5,0,62,0.7)',
														}}
													/>
												</div>{' '}
												<a
													className="media-container__link media-container__link--btn media-container__link--style-circle "
													href="https://www.youtube.com/watch?v=eCnOtWbxWOs"
													data-lightbox="iframe"
												>
													<div className="circleanim-svg">
														<svg
															height={108}
															width={108}
															xmlns="http://www.w3.org/2000/svg"
														>
															<circle
																strokeOpacity="0.1"
																fill="#FFFFFF"
																strokeWidth={5}
																cx={54}
																cy={54}
																r={48}
																className="circleanim-svg__circle-back"
															/>
															<circle
																strokeWidth={5}
																fill="#FFFFFF"
																cx={54}
																cy={54}
																r={48}
																className="circleanim-svg__circle-front"
																transform="rotate(50 54 54) "
															/>
															<path
																d="M62.1556183,56.1947505 L52,62.859375 C50.6192881,63.7654672 49.5,63.1544098 49.5,61.491212 L49.5,46.508788 C49.5,44.8470803 50.6250889,44.2383396 52,45.140625 L62.1556183,51.8052495 C64.0026693,53.0173767 63.9947588,54.9878145 62.1556183,56.1947505 Z"
																fill="#FFFFFF"
															/>
														</svg>
													</div>{' '}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluide9b9c256     section-sidemargins    section--no "
						id="eventsviplava"
					>
						<div className="zn_section_size full_width zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluidb10bd2a5            col-md-3 col-sm-3   znColumnElement"
									id="eluidb10bd2a5"
								>
									<div className="znColumnElement-innerWrapper-eluidb10bd2a5 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-center znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluidae1898ac  dn-heading"
												id="eluidae1898ac"
												itemProp="headline"
											>
												ACTIVITIES
											</h3>
											<h3
												className="eluide74a2c16  dn-heading"
												id="eluide74a2c16"
												itemProp="headline"
											>
												ACTIVITES
											</h3>
											<h3
												className="eluid072fa61e  dn-heading"
												id="eluid072fa61e"
												itemProp="headline"
											>
												Temple &amp; Places Visits
												<br />
												Sea Beach Kirtan
												<br />
												Pastimes
												<br />
												Anand Bazaar
												<br />
												Ganga Snan
												<br />
											</h3>
											<h3
												className="eluidc1846163  dn-heading"
												id="eluidc1846163"
												itemProp="headline"
											>
												<a
													href="#"
													className="dn-headingLink"
													target="_self"
													itemProp="url"
												>
													<strong>Special:</strong>
													<br /> 600 Devotees in
													Train, Mangal Aarti
												</a>
											</h3>{' '}
											<div className="media-container eluid1a07545b  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Anand-Bazaar.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'left center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(168,39,170,0.5)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluidbb62d562            col-md-12 col-sm-12   znColumnElement"
															id="eluidbb62d562"
														>
															<div className="znColumnElement-innerWrapper-eluidbb62d562 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluid5aefb679  dn-heading"
																		id="eluid5aefb679"
																		itemProp="headline"
																	>
																		ANAND
																		BAZAAR
																	</h3>
																	<h3
																		className="eluid4745c165  dn-heading"
																		id="eluid4745c165"
																		itemProp="headline"
																	>
																		AT
																		JAGANNATH
																		TEMPLE
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="eluid0298d50d            col-md-3 col-sm-3   znColumnElement"
									id="eluid0298d50d"
								>
									<div className="znColumnElement-innerWrapper-eluid0298d50d znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="media-container eluid395cb513  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/322882368_886721009134473_5305355409320506696_n.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(53,53,53,0.65)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluid7df5957e            col-md-12 col-sm-12   znColumnElement"
															id="eluid7df5957e"
														>
															<div className="znColumnElement-innerWrapper-eluid7df5957e znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluid0f822b88  dn-heading"
																		id="eluid0f822b88"
																		itemProp="headline"
																	>
																		DARSHAN
																	</h3>
																	<h3
																		className="eluidd36876a5  dn-heading"
																		id="eluidd36876a5"
																		itemProp="headline"
																	>
																		Puri
																		&amp;
																		Mayapur
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="media-container eluiddab10e3d  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89789309_1305434062982058_2412510360255856640_n.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(54,81,237,0.45)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluid26947b3d            col-md-12 col-sm-12   znColumnElement"
															id="eluid26947b3d"
														>
															<div className="znColumnElement-innerWrapper-eluid26947b3d znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluid1f006f84  dn-heading"
																		id="eluid1f006f84"
																		itemProp="headline"
																	>
																		GANGA
																		SNAN
																	</h3>
																	<h3
																		className="eluid6b496da4  dn-heading"
																		id="eluid6b496da4"
																		itemProp="headline"
																	>
																		at
																		MAYAPUR
																		DHAM
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="eluidf30da713            col-md-3 col-sm-3   znColumnElement"
									id="eluidf30da713"
								>
									<div className="znColumnElement-innerWrapper-eluidf30da713 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="media-container eluidef0e0627  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/88346884_1303488143176650_498134301309665280_n.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(36,80,226,0.48)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluid0884976f            col-md-12 col-sm-12   znColumnElement"
															id="eluid0884976f"
														>
															<div className="znColumnElement-innerWrapper-eluid0884976f znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluidfcc1562c  dn-heading"
																		id="eluidfcc1562c"
																		itemProp="headline"
																	>
																		SEA
																		BEACH
																		KIRTAN
																	</h3>
																	<h3
																		className="eluid9c07119e  dn-heading"
																		id="eluid9c07119e"
																		itemProp="headline"
																	>
																		AT PURI
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="media-container eluidadd8186c  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89377416_1302123626646435_8385315473775919104_n.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(10,0,0,0.36)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluid7cba8ea1            col-md-12 col-sm-12   znColumnElement"
															id="eluid7cba8ea1"
														>
															<div className="znColumnElement-innerWrapper-eluid7cba8ea1 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluidf6c97c2e  dn-heading"
																		id="eluidf6c97c2e"
																		itemProp="headline"
																	>
																		Mangal
																		Aarti
																		&amp;
																		Kirtan
																	</h3>
																	<h3
																		className="eluid73294505  dn-heading"
																		id="eluid73294505"
																		itemProp="headline"
																	>
																		In Train
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="eluide4e3a836            col-md-3 col-sm-3   znColumnElement"
									id="eluide4e3a836"
								>
									<div className="znColumnElement-innerWrapper-eluide4e3a836 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="media-container eluid724aac0e  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Sundar-Gopal-Das.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(249,130,2,0.35)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluidd7c57827            col-md-12 col-sm-12   znColumnElement"
															id="eluidd7c57827"
														>
															<div className="znColumnElement-innerWrapper-eluidd7c57827 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluidf7bbfac1  dn-heading"
																		id="eluidf7bbfac1"
																		itemProp="headline"
																	>
																		LECTURES
																		&amp;
																		PASTIMES
																	</h3>
																	<h3
																		className="eluid4629c824  dn-heading"
																		id="eluid4629c824"
																		itemProp="headline"
																	>
																		by
																		Eminent
																		Personalities
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="media-container eluidc8ad12bc  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89770983_1303477599844371_4374074950005293056_n.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(255,218,56,0.41)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluid3fc6667d            col-md-12 col-sm-12   znColumnElement"
															id="eluid3fc6667d"
														>
															<div className="znColumnElement-innerWrapper-eluid3fc6667d znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluid5d493883  dn-heading"
																		id="eluid5d493883"
																		itemProp="headline"
																	>
																		And{' '}
																		<br />
																		Many
																		More
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluidd0d1dfd3     section-sidemargins    section--no "
						id="details"
					>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row ">
								<div
									className="eluidb1c8ee5f            col-md-4 col-sm-4   znColumnElement"
									id="eluidb1c8ee5f"
								>
									<div className="znColumnElement-innerWrapper-eluidb1c8ee5f znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="image-boxes imgbox-simple eluida94919f9 ">
												<a
													href="#Mayapur"
													className="image-boxes-link imgboxes-wrapper u-mb-0  "
													target="_self"
													itemProp="url"
												>
													<div className="image-boxes-img-wrapper img-align-center">
														<img
															className="image-boxes-img img-responsive "
															src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/IYF-Delhi-SP3C-Mayapur-Yatra-2023.jpg"
															alt=""
															title="IYF Delhi - SP3C Mayapur Yatra 2023"
														/>
													</div>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
								<div
									className="eluidbf264391            col-md-4 col-sm-4   znColumnElement"
									id="eluidbf264391"
								>
									<div className="znColumnElement-innerWrapper-eluidbf264391 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="image-boxes imgbox-simple eluid6c35cf93 ">
												<a
													href="#JMYatra"
													className="image-boxes-link imgboxes-wrapper u-mb-0  "
													target="_self"
													itemProp="url"
												>
													<div className="image-boxes-img-wrapper img-align-center">
														<img
															className="image-boxes-img img-responsive "
															src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/IYF-Delhi-SP3C-Jgannath-Puri-Mayapur-Yatra-2023.jpg"
															alt=""
															title="IYF Delhi - SP3C Jgannath Puri Mayapur Yatra 2023"
														/>
													</div>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
								<div
									className="eluidc96d085b            col-md-4 col-sm-4   znColumnElement"
									id="eluidc96d085b"
								>
									<div className="znColumnElement-innerWrapper-eluidc96d085b znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="image-boxes imgbox-simple eluid74525342 ">
												<a
													href="#Puri"
													className="image-boxes-link imgboxes-wrapper u-mb-0  "
													target="_self"
													itemProp="url"
												>
													<div className="image-boxes-img-wrapper img-align-center">
														<img
															className="image-boxes-img img-responsive "
															src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/IYF-Delhi-SP3C-Jgannath-Puri-Yatra-2023.jpg"
															alt=""
															title="IYF Delhi - SP3C Jgannath Puri Yatra 2023"
														/>
													</div>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluidbbe439a1   zn_parallax  section-sidemargins    zn_section--relative section--no "
						id="Mayapur"
					>
						<div className="zn-bgSource zn-bgSource-imageParallax js-znParallax">
							<div
								className="zn-bgSource-image"
								style={{
									backgroundImage:
										'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Mayapur.jpg)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
								}}
							/>
							<div
								className="zn-bgSource-overlay"
								style={{ backgroundColor: 'rgba(5,0,62,0.7)' }}
							/>
						</div>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid8147b945            col-md-12 col-sm-12   znColumnElement"
									id="eluid8147b945"
								>
									<div className="znColumnElement-innerWrapper-eluid8147b945 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluid3f9d1ca5  dn-heading"
												id="eluid3f9d1ca5"
												itemProp="headline"
											>
												16TH - 21st February 2023
											</h3>
											<h3
												className="eluideff4d654  dn-heading"
												id="eluideff4d654"
												itemProp="headline"
											>
												Mayapur
											</h3>
											<h3
												className="eluid62394b78  dn-heading"
												id="eluid62394b78"
												itemProp="headline"
											>
												Only
											</h3>
											<h3
												className="eluid18084898  dn-heading"
												id="eluid18084898"
												itemProp="headline"
											>
												Donation
											</h3>
											<h3
												className="eluidb9002e00  dn-heading"
												id="eluidb9002e00"
												itemProp="headline"
											>
												Charges
											</h3>
											<h3
												className="eluidccba8740  dn-heading"
												id="eluidccba8740"
												itemProp="headline"
											>
												<strong>
													Dormatory with
													Transportation
												</strong>{' '}
												₹4700/-
												<br />
												<strong>
													Dormatory without
													Transportation
												</strong>{' '}
												₹2300/-
												<br />
												<strong>
													Room with Transportation
												</strong>{' '}
												₹5200/-
												<br />
												<strong>
													Room without Transportation
												</strong>{' '}
												₹2800/-
												<br />
											</h3>
											<div
												id="eluidbda8a035"
												className="zn_buttons_element eluidbda8a035 text-center "
											>
												<a
													href="#"
													id="eluidbda8a0350"
													className="eluidbda8a0350 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
													onClick={(
														...args
													) =>
														handleRegisterBtnClick(
															EVENTS_UI_CONFIG[2].uuid,
															...args
														)
													}
												>
													<span>BOOK NOW</span>
												</a>
												{/* <a
													href="http://wa.me/919650041101"
													id="eluidbda8a0351"
													className="eluidbda8a0351 btn-element btn-element-1 btn  btn-lined btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_blank"
													rel="noopener"
													itemProp="url"
												>
													<span>KNOW MORE</span>
												</a> */}
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluided10d5cf   zn_parallax  section-sidemargins    zn_section--relative section--no "
						id="Puri"
					>
						<div className="zn-bgSource zn-bgSource-imageParallax js-znParallax">
							<div
								className="zn-bgSource-image"
								style={{
									backgroundImage:
										'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Jagannath-Puri.jpg)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
								}}
							/>
							<div
								className="zn-bgSource-overlay"
								style={{ backgroundColor: 'rgba(5,0,62,0.7)' }}
							/>
						</div>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluidfb9c7bb7            col-md-12 col-sm-12   znColumnElement"
									id="eluidfb9c7bb7"
								>
									<div className="znColumnElement-innerWrapper-eluidfb9c7bb7 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluid68957eef  dn-heading"
												id="eluid68957eef"
												itemProp="headline"
											>
												22nd - 25th February 2023
											</h3>
											<h3
												className="eluid2b9586ee  dn-heading"
												id="eluid2b9586ee"
												itemProp="headline"
											>
												Jagannath Puri
											</h3>
											<h3
												className="eluiddf693a30  dn-heading"
												id="eluiddf693a30"
												itemProp="headline"
											>
												Only
											</h3>
											<h3
												className="eluid2ea60000  dn-heading"
												id="eluid2ea60000"
												itemProp="headline"
											>
												Donation
											</h3>
											<h3
												className="eluid4004c775  dn-heading"
												id="eluid4004c775"
												itemProp="headline"
											>
												Charges
											</h3>
											<h3
												className="eluid5e69742d  dn-heading"
												id="eluid5e69742d"
												itemProp="headline"
											>
												<strong>
													Dormatory with
													Transportation
												</strong>{' '}
												₹3700/-
												<br />
												<strong>
													Dormatory without
													Transportation
												</strong>{' '}
												₹1600/-
												<br />
												<strong>
													Room with Transportation
												</strong>{' '}
												₹4200/-
												<br />
												<strong>
													Room without Transportation
												</strong>{' '}
												₹2000/-
												<br />
											</h3>
											<div
												id="eluid81774b32"
												className="zn_buttons_element eluid81774b32 text-center "
											>
												<a
													href="#"
													id="eluid81774b320"
													className="eluid81774b320 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
													onClick={(
														...args
													) =>
														handleRegisterBtnClick(
															EVENTS_UI_CONFIG[1].uuid,
															...args
														)
													}
												>
													<span>BOOK NOW</span>
												</a>
												{/* <a
													href="http://wa.me/919650041101"
													id="eluid81774b321"
													className="eluid81774b321 btn-element btn-element-1 btn  btn-lined btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_blank"
													rel="noopener"
													itemProp="url"
												>
													<span>KNOW MORE</span>
												</a> */}
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluiddf2ab0a1   zn_parallax  section-sidemargins    zn_section--relative section--no "
						id="JMYatra"
					>
						<div className="zn-bgSource zn-bgSource-imageParallax js-znParallax">
							<div
								className="zn-bgSource-image"
								style={{
									backgroundImage:
										'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Jagannath-Puri-and-Mayapur.jpg)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
								}}
							/>
							<div
								className="zn-bgSource-overlay"
								style={{ backgroundColor: 'rgba(5,0,62,0.7)' }}
							/>
						</div>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid86334088            col-md-12 col-sm-12   znColumnElement"
									id="eluid86334088"
								>
									<div className="znColumnElement-innerWrapper-eluid86334088 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluid93328213  dn-heading"
												id="eluid93328213"
												itemProp="headline"
											>
												16th - 25th February 2023
											</h3>
											<h3
												className="eluid9d55fb0f  dn-heading"
												id="eluid9d55fb0f"
												itemProp="headline"
											>
												Mayapur &amp; Jagannath Puri
											</h3>
											<h3
												className="eluid9801d19a  dn-heading"
												id="eluid9801d19a"
												itemProp="headline"
											>
												Both
											</h3>
											<h3
												className="eluidfe336434  dn-heading"
												id="eluidfe336434"
												itemProp="headline"
											>
												Donation
											</h3>
											<h3
												className="eluid34f8816b  dn-heading"
												id="eluid34f8816b"
												itemProp="headline"
											>
												Charges
											</h3>
											<h3
												className="eluid552154e1  dn-heading"
												id="eluid552154e1"
												itemProp="headline"
											>
												<strong>
													Dormatory with
													Transportation
												</strong>{' '}
												₹7100/-
												<br />
												<strong>
													Dormatory without
													Transportation
												</strong>{' '}
												₹4200/-
												<br />
												<strong>
													Room with Transportation
												</strong>{' '}
												₹8000/-
												<br />
												<strong>
													Room without Transportation
												</strong>{' '}
												₹4700/-
												<br />
											</h3>
											<div
												id="eluidfc53bff6"
												className="zn_buttons_element eluidfc53bff6 text-center "
											>
												<a
													href="#"
													id="eluidfc53bff60"
													className="eluidfc53bff60 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
													onClick={(
														...args
													) =>
														handleRegisterBtnClick(
															EVENTS_UI_CONFIG[0].uuid,
															...args
														)
													}
												>
													<span>BOOK NOW</span>
												</a>
												{/* <a
													href="http://wa.me/919650041101"
													id="eluidfc53bff61"
													className="eluidfc53bff61 btn-element btn-element-1 btn  btn-lined btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_blank"
													rel="noopener"
													itemProp="url"
												>
													<span>KNOW MORE</span>
												</a> */}
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div
						className=" eluid1a5666ac gridPhotoGallery mfp-gallery mfp-gallery--misc gridPhotoGallery--ratio-square gridPhotoGallery--cols-4 gpg-gutter--5   gridPhotoGallery--scheme-light"
						data-cols={4}
						data-layout="masonry"
					>
						<div className="gridPhotoGallery__item gridPhotoGallery__item--sizer" />
						<div className="gridPhotoGallery__item gridPhotoGalleryItem--w1 gridPhotoGallery-item--scheme- ">
							<a
								title
								className="gridPhotoGalleryItem--h1 gridPhotoGallery__link kl-fontafter-alt gridPhotoGallery__link-anim--none"
								data-lightbox="mfp"
								data-mfp="image"
								href="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Sundar-Gopal-Das.jpg"
							>
								<span className="gridPhotoGallery__imgAnimWrapper">
									<img
										className="gridPhotoGallery__img cover-fit-img"
										src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Sundar-Gopal-Das.jpg"
										width={1116}
										height={1280}
										alt=""
										title="Sundar Gopal Das"
									/>
									<i className="kl-icon glyphicon glyphicon-search circled-icon ci-large" />
								</span>
							</a>
						</div>
						<div className="gridPhotoGallery__item gridPhotoGalleryItem--w1 gridPhotoGallery-item--scheme- ">
							<a
								title
								className="gridPhotoGalleryItem--h1 gridPhotoGallery__link kl-fontafter-alt gridPhotoGallery__link-anim--none"
								data-lightbox="mfp"
								data-mfp="image"
								href="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89802201_1303489536509844_5889723575383883776_n.jpg"
							>
								<span className="gridPhotoGallery__imgAnimWrapper">
									<img
										className="gridPhotoGallery__img cover-fit-img"
										src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89802201_1303489536509844_5889723575383883776_n.jpg"
										width={1920}
										height={1280}
										alt=""
										title="89802201_1303489536509844_5889723575383883776_n"
									/>
									<i className="kl-icon glyphicon glyphicon-search circled-icon ci-large" />
								</span>
							</a>
						</div>
						<div className="gridPhotoGallery__item gridPhotoGalleryItem--w1 gridPhotoGallery-item--scheme- ">
							<a
								title
								className="gridPhotoGalleryItem--h1 gridPhotoGallery__link kl-fontafter-alt gridPhotoGallery__link-anim--none"
								data-lightbox="mfp"
								data-mfp="image"
								href="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Anand-Bazaar.jpg"
							>
								<span className="gridPhotoGallery__imgAnimWrapper">
									<img
										className="gridPhotoGallery__img cover-fit-img"
										src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Anand-Bazaar.jpg"
										width={1260}
										height={1190}
										alt=""
										title="Anand Bazaar"
									/>
									<i className="kl-icon glyphicon glyphicon-search circled-icon ci-large" />
								</span>
							</a>
						</div>
						<div className="gridPhotoGallery__item gridPhotoGalleryItem--w1 gridPhotoGallery-item--scheme- ">
							<a
								title
								className="gridPhotoGalleryItem--h1 gridPhotoGallery__link kl-fontafter-alt gridPhotoGallery__link-anim--none"
								data-lightbox="mfp"
								data-mfp="image"
								href="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/88346884_1303488143176650_498134301309665280_n.jpg"
							>
								<span className="gridPhotoGallery__imgAnimWrapper">
									<img
										className="gridPhotoGallery__img cover-fit-img"
										src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/88346884_1303488143176650_498134301309665280_n.jpg"
										width={1920}
										height={1280}
										alt=""
										title="88346884_1303488143176650_498134301309665280_n"
									/>
									<i className="kl-icon glyphicon glyphicon-search circled-icon ci-large" />
								</span>
							</a>
						</div>
						<div className="gridPhotoGallery__item gridPhotoGalleryItem--w1 gridPhotoGallery-item--scheme- ">
							<a
								title
								className="gridPhotoGalleryItem--h1 gridPhotoGallery__link kl-fontafter-alt gridPhotoGallery__link-anim--none"
								data-lightbox="mfp"
								data-mfp="image"
								href="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89789309_1305434062982058_2412510360255856640_n.jpg"
							>
								<span className="gridPhotoGallery__imgAnimWrapper">
									<img
										className="gridPhotoGallery__img cover-fit-img"
										src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89789309_1305434062982058_2412510360255856640_n.jpg"
										width={1920}
										height={1280}
										alt=""
										title="89789309_1305434062982058_2412510360255856640_n"
									/>
									<i className="kl-icon glyphicon glyphicon-search circled-icon ci-large" />
								</span>
							</a>
						</div>
						<div className="gridPhotoGallery__item gridPhotoGalleryItem--w1 gridPhotoGallery-item--scheme- ">
							<a
								title
								className="gridPhotoGalleryItem--h1 gridPhotoGallery__link kl-fontafter-alt gridPhotoGallery__link-anim--none"
								data-lightbox="mfp"
								data-mfp="image"
								href="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89377416_1302123626646435_8385315473775919104_n.jpg"
							>
								<span className="gridPhotoGallery__imgAnimWrapper">
									<img
										className="gridPhotoGallery__img cover-fit-img"
										src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89377416_1302123626646435_8385315473775919104_n.jpg"
										width={1920}
										height={1280}
										alt=""
										title="89377416_1302123626646435_8385315473775919104_n"
									/>
									<i className="kl-icon glyphicon glyphicon-search circled-icon ci-large" />
								</span>
							</a>
						</div>
						<div className="gridPhotoGallery__item gridPhotoGalleryItem--w1 gridPhotoGallery-item--scheme- ">
							<a
								title
								className="gridPhotoGalleryItem--h1 gridPhotoGallery__link kl-fontafter-alt gridPhotoGallery__link-anim--none"
								data-lightbox="mfp"
								data-mfp="image"
								href="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89770983_1303477599844371_4374074950005293056_n.jpg"
							>
								<span className="gridPhotoGallery__imgAnimWrapper">
									<img
										className="gridPhotoGallery__img cover-fit-img"
										src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/89770983_1303477599844371_4374074950005293056_n.jpg"
										width={1920}
										height={1280}
										alt=""
										title="89770983_1303477599844371_4374074950005293056_n"
									/>
									<i className="kl-icon glyphicon glyphicon-search circled-icon ci-large" />
								</span>
							</a>
						</div>
						<div className="gridPhotoGallery__item gridPhotoGalleryItem--w1 gridPhotoGallery-item--scheme- ">
							<a
								title
								className="gridPhotoGalleryItem--h1 gridPhotoGallery__link kl-fontafter-alt gridPhotoGallery__link-anim--none"
								data-lightbox="mfp"
								data-mfp="image"
								href="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Jagannath-Puri-and-Mayapur.jpg"
							>
								<span className="gridPhotoGallery__imgAnimWrapper">
									<img
										className="gridPhotoGallery__img cover-fit-img"
										src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Jagannath-Puri-and-Mayapur.jpg"
										width={1920}
										height={1080}
										alt=""
										title="Jagannath-Puri-and-Mayapur"
									/>
									<i className="kl-icon glyphicon glyphicon-search circled-icon ci-large" />
								</span>
							</a>
						</div>{' '}
					</div>
					<div className="clearfix" />
					<section
						className="zn_section eluiddba4b12d     section-sidemargins    section--no "
						id="eluiddba4b12d"
					>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row ">
								<div
									className="eluidf6880a23            col-md-12 col-sm-12   znColumnElement"
									id="eluidf6880a23"
								>
									<div className="znColumnElement-innerWrapper-eluidf6880a23 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluidaa08ff3b  dn-heading"
												id="eluidaa08ff3b"
												itemProp="headline"
											>
												ALL IMAGES
											</h3>
											<h3
												className="eluid83c749b6  dn-heading"
												id="eluid83c749b6"
												itemProp="headline"
											>
												VIEW ALL IMAGES
											</h3>
											<div
												id="eluidd9fcfb72"
												className="zn_buttons_element eluidd9fcfb72 text-center "
											>
												<a
													href="https://www.facebook.com/media/set/?set=a.1302122313313233&type=3"
													id="eluidd9fcfb720"
													className="eluidd9fcfb720 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
												>
													<span>ALBUM 01</span>
												</a>
												<a
													href="https://www.facebook.com/media/set/?set=a.1303476553177809&type=3"
													id="eluidd9fcfb721"
													className="eluidd9fcfb721 btn-element btn-element-1 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
												>
													<span>ALBUM 02</span>
												</a>
												<a
													href="https://www.facebook.com/media/set/?set=a.1305398896318908&type=3"
													id="eluidd9fcfb722"
													className="eluidd9fcfb722 btn-element btn-element-2 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
												>
													<span>ALBUM 03</span>
												</a>
												<a
													href="https://www.facebook.com/media/set/?set=a.1306275182897946&type=3"
													id="eluidd9fcfb723"
													className="eluidd9fcfb723 btn-element btn-element-3 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
												>
													<span>ALBUM 04</span>
												</a>
												<a
													href="https://www.facebook.com/media/set/?set=a.1307704899421641&type=3"
													id="eluidd9fcfb724"
													className="eluidd9fcfb724 btn-element btn-element-4 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
												>
													<span>ALBUM 05</span>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>{' '}
				<footer
					id="footer"
					className="site-footer"
					role="contentinfo"
					itemScope="itemscope"
					itemType="https://schema.org/WPFooter"
				>
					<div className="container">
						<div className="row">
							<div className="col-sm-12">
								<div className="bottom site-footer-bottom clearfix">
									<div className="zn_footer_nav-wrapper">
										<ul
											id="menu-event-conference-menu-1"
											className="footer_nav"
										>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-24">
												<a href="#aboutviplava">
													ABOUT EVENT
												</a>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-25">
												<a href="#eventsviplava">
													EVENTS
												</a>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-27">
												<a href="#details">DETAILS</a>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-95">
												<a href="https://sp-3c/event/jagannath-puri-mayapur-camp">
													REGISTER NOW
												</a>
											</li>
										</ul>
									</div>
									<div className="copyright footer-copyright">
										<p className="footer-copyright-text">
											© 2022. All rights reserved.{' '}
											<a href="https://iyfdelhi.com">
												IYF Delhi
											</a>
											.
										</p>{' '}
									</div>
									{/* end copyright */}
								</div>
								{/* end bottom */}
							</div>
						</div>
						{/* end row */}
					</div>
				</footer>
			</div>
			{/* end page_wrapper */}
		</div>
	)
}

export default JagannathPuriMayapurCamp
