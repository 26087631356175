import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import DateFnsUtils from '@date-io/date-fns'
import React from 'react'
import { getAreaDetails } from '../../services/area'
import { searchUser } from '../../services/user'
import {
	CLASS_LEVEL,
	EXPERTISE_AREAS,
	GENDER,
	MARITAL_STATUS,
	PROFESSIONS,
} from '../../constants'
import './style.scss'
import { isMobileScreen } from '../../utils'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { format } from 'date-fns'

export class BasicInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			name: props.userDetails.name || '',
			initiatedName: '',
			email: props.userDetails.email || '',
			phone: '',
			connectedArea: '',
			facilitator: '',
			sadhnaGroup: '',
			dob: new Date(),
			mentor: '',
		}
		this.disabled = !!props.userDetails.email
	}
	onInputChange = (event, value, type, reason) => {
		const { mentor, facilitator, connectedArea } = this.state
		//response from api
		if (reason !== 'clear') {
			switch (type) {
				case 'connectedArea':
					if (mentor?._id || mentor) {
						getAreaDetails({
							facilitator: facilitator?._id || facilitator,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					} else {
						getAreaDetails({ per_page: 100 }).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					}
					break
				case 'facilitator':
					if (connectedArea?._id || connectedArea) {
						searchUser({
							q: value,
							userRole: 3,
							areaId: connectedArea?._id || connectedArea,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					} else {
						searchUser({
							q: value,
							userRole: 3,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					}
					break
				case 'mentor':
					searchUser({ q: value, userRole: 3, per_page: 100 }).then(
						(res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						}
					)
					break
				default:
					getAreaDetails({ per_page: 100 }).then((res) => {
						this.setState({
							[`options-${type}`]: res.data.data,
						})
					})
			}
		} else {
			const resetObj = {
				'options-mentor': [],
				'options-facilitator': [],
				'options-connectedArea': [],
			}
			if (type === 'connectedArea') {
				this.setState({
					...resetObj,
					facilitator: '',
				})
			} else if (type === 'facilitator') {
				this.setState({
					...resetObj,
					connectedArea: '',
				})
			} else {
				this.setState(resetObj)
			}
		}
	}

	onChange = (name, value) => {
		this.setState({
			[name]: value,
		})
	}

	render() {
		const {
			name,
			initiatedName,
			email,
			phone,
			connectedArea,
			facilitator,
			sadhnaGroup,
			dob,
			mentor,
		} = this.state
		return (
			<>
				<TextField
					label="Name"
					variant="outlined"
					defaultValue={this.state.name}
					TabPa
					value={this.state.name}
					name="name"
					disabled={this.disabled}
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					fullWidth
				/>
				<TextField
					label="Initiated Name"
					variant="outlined"
					defaultValue={this.state.initiatedName}
					value={this.state.initiatedName}
					name="initiatedName"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					fullWidth
				/>
				<TextField
					label="Email"
					variant="outlined"
					defaultValue={this.state.email}
					value={this.state.email}
					name="email"
					disabled={this.disabled}
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					fullWidth
				/>
				<TextField
					label="Phone"
					variant="outlined"
					defaultValue={this.state.phone}
					value={this.state.phone}
					name="phone"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					fullWidth
				/>
				<Autocomplete
					options={this.state[`options-connectedArea`] || []}
					getOptionLabel={(option) => option.name || ''}
					defaultValue={this.state.connectedArea}
					filterSelectedOptions
					onInputChange={(e, val, reason) =>
						this.onInputChange(e, val, 'connectedArea', reason)
					}
					value={this.state.connectedArea}
					onChange={(e, newValue) => {
						this.onChange('connectedArea', newValue)
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Connected Area"
							placeholder="Start typing Area Name"
						/>
					)}
				/>
				<Autocomplete
					options={this.state[`options-facilitator`] || []}
					getOptionLabel={(option) =>
						option.initiatedName || option.name
							? `${option.initiatedName || option.name}` +
							  (option.phone ? ` (${option.phone})` : '')
							: option.initiatedName || option.name
					}
					defaultValue={this.state.facilitator}
					filterSelectedOptions
					onInputChange={(e, val, reason) =>
						this.onInputChange(e, val, 'facilitator', reason)
					}
					value={this.state.facilitator}
					onChange={(e, newValue) => {
						this.onChange('facilitator', newValue)
					}}
					disabled={!this.state.connectedArea}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Facilitator"
							placeholder="Start typing name"
						/>
					)}
				/>
				<Autocomplete
					options={this.state[`options-mentor`] || []}
					getOptionLabel={(option) =>
						option.initiatedName || option.name
							? `${option.initiatedName || option.name}` +
							  (option.phone ? ` (${option.phone})` : '')
							: option.initiatedName || option.name
					}
					defaultValue={this.state.mentor}
					filterSelectedOptions
					onInputChange={(e, val, reason) =>
						this.onInputChange(e, val, 'mentor', reason)
					}
					value={this.state.mentor}
					onChange={(e, newValue) => {
						this.onChange('mentor', newValue)
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Connected by"
							placeholder="Start typing name"
						/>
					)}
				/>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						id="date-picker-dialog"
						label="Date of Birth"
						format="dd/MM/yyyy"
						value={this.state.dob}
						inputVariant="outlined"
						onChange={(date) => {
							this.onChange('dob', date)
						}}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</MuiPickersUtilsProvider>
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="demo-simple-select-outlined-label">
						Class level
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={this.state.sadhnaGroup}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						label="Class level"
						name="sadhnaGroup"
					>
						{Object.keys(CLASS_LEVEL).map((level) => (
							<MenuItem value={level}>
								{CLASS_LEVEL[level]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Button
					variant="contained"
					color="primary"
					onClick={() =>
						this.props.onSubmit({
							name,
							initiatedName,
							email,
							phone,
							connectedAreaId:
								connectedArea?._id || connectedArea,
							facilitator: facilitator?._id || facilitator,
							userRole: 0,
						})
					}
				>
					NEXT
				</Button>
			</>
		)
	}
}

export class AdditionalInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			residenceArea: '',
			expertiseArea: [],
			chantingRounds: '',
			maritalStatus: '',
			gender: '',
			profession: '',
		}
	}
	a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	onChange = (name, value) => {
		this.setState({
			[name]: value,
		})
	}

	render() {
		return (
			<>
				<TextField
					label="Residence Area"
					variant="outlined"
					defaultValue={this.state.residenceArea}
					TabPa
					value={this.state.residenceArea}
					name="residenceArea"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					fullWidth
				/>
				<TextField
					label="Chanting Rounds"
					variant="outlined"
					defaultValue={this.state.chantingRounds}
					value={this.state.chantingRounds}
					name="chantingRounds"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					fullWidth
				/>
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="demo-simple-select-outlined-label">
						Gender
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={this.state.gender}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						label="Gender"
						name="gender"
					>
						{Object.keys(GENDER).map((gen) => (
							<MenuItem value={gen}>{GENDER[gen]}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="demo-simple-select-outlined-label">
						Marital Status
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={this.state.maritalStatus}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						label="Marital Status"
						name="maritalStatus"
					>
						{Object.keys(MARITAL_STATUS).map((marStat) => (
							<MenuItem value={marStat}>
								{MARITAL_STATUS[marStat]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="demo-simple-select-outlined-label">
						Profession
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={this.state.profession}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						label="Profession"
						name="profession"
					>
						{PROFESSIONS.map((prof) => (
							<MenuItem value={prof}>{prof}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="demo-simple-select-outlined-label">
						Expertise Area
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={this.state.expertiseArea}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						label="Expertise Area"
						name="expertiseArea"
						multiple
					>
						{EXPERTISE_AREAS.map((expArea) => (
							<MenuItem value={expArea}>{expArea}</MenuItem>
						))}
					</Select>
				</FormControl>
				<Button
					variant="contained"
					color="primary"
					onClick={() => this.props.onSubmit(this.state)}
				>
					SIGN UP
				</Button>
			</>
		)
	}
}
