const RefundPolicy = () => {
	return (
		<div className='static-page-container'>
			<h2>Acceptance</h2>
			<p>As a user of the SP3C Website, you understand that the Services
			like Donations, Events, Offerings, Sponsorships, or Memberships may
			result in charges to you for the services or goods you order
			("Charges"). SP3C currently accepts donations through its Online
			Payment Gateway Facility for various charitable / social purposes.
			All donations and/or payment made through the Site, the acceptance
			of the same is at the sole discretion of the temple management and
			its trustees.</p>
			<p>
				The charges for the services are paid by you in Advance at the
				time of Booking the Service. You receive a Booking Confirmation
				from the SP3C Website at the time of booking the Service and
				successfully completing the payment to the SP3C Website for
				the services booked.
			</p>
			<h2>Refund</h2>
			<p>
				Any payment made multiple times due to error, or any other kind
				of erroneous payment may be brought to the notice of the Site
				officials and the payment would be refunded if the Site official
				is satisfied with the cause and/or reason of error by the payee.
			</p>
			<p>
				While processing the refund, a full refund will be processed
				within 10 working days of the refund request.
			</p>
			<h2>Cancellation</h2>
			<p>
				The management reserves its sole rights to accept or cancel any
				donation and/or payment without assigning any reason thereof.
			</p>
			<p>Cancellation Requests can be made by email to sp-3c.</p>
			<p>
				If the Service Request has been activated, the cancellation
				request will not be entertained and the amount paid is
				Non-Refundable.
			</p>
			<p>
				For valid donation cancelation requests, the refund will be
				processed within 10 working days of the cancelation request. On
				successful cancelation of a Service Request, a Cancelation
				Confirmation email will be sent to the user's email id.
			</p>
			<p>
				Please note that donations once made cannot be charged back,
				except in cases of fraudulent use, once investigation is carried
				out by necessary governmental / Bank authorities and proofs
				substantiated. In case any donation receipt is issued against
				the same, that shall stand cancelled, and any income tax benefit
				so derived shall be null and void.
			</p>
		</div>
	)
}

export default RefundPolicy
