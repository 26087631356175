import {
	GET_AREA_STARTED,
	GET_AREA_SUCCESS,
	GET_AREA_FAILED,
	UPDATE_AREA_STARTED,
	UPDATE_AREA_SUCCESS,
	UPDATE_AREA_FAILED,
} from "../constants/actions";
import { ALERT } from "../constants";

let initialState = {
	areas: [],
	loading: false,
	error: null,
	alert: null,
};
const areaReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_AREA_STARTED:
			return {
				...state,
				loading: true,
			};
		case GET_AREA_SUCCESS:
			return {
				...state,
				loading: false,
				areas: action.payload,
			};
		case GET_AREA_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			};
		case UPDATE_AREA_STARTED:
			return {
				...state,
				loading: true,
			};
		case UPDATE_AREA_SUCCESS:
			const areas = state.areas.map((area) =>
				area._id === action.payload._id ? action.payload : area
			);
			return {
				...state,
				loading: false,
				areas,
			};
		case UPDATE_AREA_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			};
		default:
			return state;
	}
};

export default areaReducer;
