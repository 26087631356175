import React from 'react';
import { EVENTS_UI_CONFIG } from '../../../data/event';

const EventLandingPage = ({...props}) => {
    const eventId = props.match.params.eventId;
    const EventPage = EVENTS_UI_CONFIG.find(event => event.id === eventId)?.landingPage;
    if (EventPage) {
        return (
            <div className='event-register-container'>
                <EventPage {...props} />
            </div>
        )
    } 
    return <div>
        404: Page Not Found
    </div>
}

export default EventLandingPage;