import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ExpandMore } from "@material-ui/icons";
import { useSelector } from "react-redux";
import "../style.scss";

const useStyles = makeStyles({
    body: {
        flexDirection: "column"
    }
})

const OverviewScreen = ({ eventId }) => {
  const eventReducer = useSelector((state) => state.eventReducer);
  const events = eventReducer.events;
  const currEvent = events.find((e) => e.eventId === eventId);
  const classes = useStyles()

  return (
    <div className="screen overview-screen">
      <Typography variant="h4">{currEvent?.title}</Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Basic Information</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography>
            Date:{" "}
            <b>
              {currEvent?.fromDate} to {currEvent?.toDate}
            </b>
          </Typography>
          <Typography>
            Venue: <b>{currEvent?.venue}</b>
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Pricing</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography>
            Basic Registration Amount:{" "}
            <b>
              ₹{currEvent?.registrationAmount}
            </b>
          </Typography>
          <Typography>
            Transportation Amount: <b>₹{currEvent?.transportationAmount}</b>
          </Typography>
          <Typography>
            Transportation Description: <b>{currEvent?.transportationDescription}</b>
          </Typography>
          <Typography>
            Accomodation Options: <b>{currEvent?.accomodationCategory && Object.keys(currEvent?.accomodationCategory).map(a => <span>{`${currEvent?.accomodationCategory[a].name} (₹${currEvent?.accomodationCategory[a].amount}) / `}</span>)}</b>
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Other Information</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography>
            {currEvent?.eligibility}
          </Typography>
          <Typography>
            {currEvent?.recommendation}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default OverviewScreen;
