import React, { useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { getBookingStatus } from '../../../services/event'
import '../style.scss'
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@material-ui/core'
import { exportFromJSON, getAreas, isMobileScreen } from '../../../utils'
import { ACCOMODATION_CATEGORY, CLASS_LEVEL, EVENT_BOOKING_STATUS } from '../../../constants'
import { CloudDownload } from '@material-ui/icons'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import { HIDE_MAIN_LOADER, SHOW_MAIN_LOADER } from '../../../constants/actions'

const ReportScreen = ({ eventId }) => {
	const [filters, setFilters] = useState({
		status: ['ALL'],
		areaId: ['ALL'],
		accomodation: ['ALL'],
		transportation: ['ALL'],
		per_page: 10,
		page: 1,
	})

	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [rowCount, setRowCount] = useState(0)
	const dispatch = useDispatch()

	useEffect(() => {
		updateBookingData()
	}, [])

	useEffect(() => {
		updateBookingData()
	}, [filters])

	const updateBookingData = () => {
		const status =
			filters.status[0] === 'ALL' ? undefined : filters.status.join(',')
		const areaId =
			filters.areaId[0] === 'ALL' ? undefined : filters.areaId.join(',')
		const accomodation =
			filters.accomodation[0] === 'ALL'
				? undefined
				: filters.accomodation.join(',')
		const transportation =
			filters.transportation[0] === 'ALL'
				? undefined
				: filters.transportation.join(',')
		setLoading(true)
		getBookingStatus({
			...filters,
			status,
			areaId,
			accomodation,
			transportation,
			eventId,
		})
			.then((res) => {
				setData(res.data.data)
				setRowCount(res.data.total_results)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
			})
	}

	const handleUserData = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page: page,
			per_page: pageSize,
		}))
	}
	const onPageSizeChange = ({ pageSize }) => {
		setFilters((f) => ({
			...f,
			page: 1,
			per_page: pageSize,
		}))
	}

	const rowWidthConfig = {
		name: { default: { flex: 0.15 }, mobile: { width: 150 } },
		phone: { default: { flex: 0.1 }, mobile: { width: 150 } },
		email: { default: { flex: 0.2 }, mobile: { width: 200 } },
		facilitator: { default: { flex: 0.15 }, mobile: { width: 150 } },
		area: { default: { flex: 0.2 }, mobile: { width: 150 } },
		status: { default: { flex: 0.1 } },
		accomodation: { default: { flex: 0.15 }, mobile: { width: 150 } },
		transportation: { default: { flex: 0.15 }, mobile: { width: 100 } },
		// user: { default: { width: 160 } },
	}

	const viewRequest = () => {}
	const approveRequest = () => {}

	const headCells = [
		{
			field: 'name',
			numeric: false,
			headerName: 'Name',
			sortable: false,
		},
		{
			field: 'phone',
			numeric: false,
			headerName: 'Phone',
			sortable: false,
		},
		{
			field: 'email',
			numeric: false,
			headerName: 'Email',
			sortable: false,
		},
		{
			field: 'area',
			numeric: false,
			headerName: 'Area',
			sortable: false,
		},
		{
			field: 'facilitator',
			numeric: false,
			headerName: 'Facilitator',
			sortable: false,
		},
		{
			field: 'status',
			numeric: false,
			headerName: 'Status',
			sortable: false,
		},
		{
			field: 'accomodation',
			numeric: false,
			headerName: 'Accomodation',
			sortable: true,
		},
		{
			field: 'transportation',
			numeric: false,
			headerName: 'Transportation',
			sortable: false,
		},
		// {
		// 	field: 'user',
		// 	headerName: 'Actions',
		// 	sortable: false,
		// 	filterable: false,
		// 	renderCell: (params) => (
		// 		<strong>
		// 			<IconButton
		// 				onClick={() => viewRequest(params.value)}
		// 				aria-label="View Details"
		// 				color="primary"
		// 				title="View Details"
		// 			>
		// 				<Visibility />
		// 			</IconButton>
		// 			<IconButton
		// 				onClick={() => approveRequest(params.value)}
		// 				aria-label="Activate"
		// 				color="primary"
		// 				title="Activate"
		// 			>
		// 				<CheckCircleRounded />
		// 			</IconButton>
		// 		</strong>
		// 	),
		// },
	]

	const onFilterChange = (name, value) => {
		let whatAdded, whatRemoved
		if (value.length > filters[name].length) {
			whatAdded = value.find((f) => !filters[name].includes(f))
		} else {
			whatRemoved = filters[name].find((f) => !value.includes(f))
		}
		if (typeof whatAdded !== undefined) {
			if (whatAdded === 'ALL') value = ['ALL']
			else value = value.filter((e) => e !== 'ALL')
		} else if (typeof whatRemoved !== undefined) {
			if (whatRemoved === 'ALL') value = value.length ? value : ['ALL']
		}
		setFilters((f) => ({
			...f,
			[name]: value,
			page: 1,
		}))
	}

	const download = () => {
		const todayDate = format(new Date(), 'dd-MM-yyyy')
		const fileName = 'Viplava Bookings ' + todayDate
		const status =
			filters.status[0] === 'ALL' ? undefined : filters.status.join(',')
		const areaId =
			filters.areaId[0] === 'ALL' ? undefined : filters.areaId.join(',')
		const accomodation =
			filters.accomodation[0] === 'ALL'
				? undefined
				: filters.accomodation.join(',')
		const transportation =
			filters.transportation[0] === 'ALL'
				? undefined
				: filters.transportation.join(',')
		dispatch({ type: SHOW_MAIN_LOADER })
		getBookingStatus({
			...filters,
			status,
			areaId,
			accomodation,
			transportation,
			page: 1,
			per_page: 5000,
			eventId,
		})
			.then((res) => {
				exportFromJSON({
					data: res.data?.data?.map((s) => ({
						name: s.userId.initiatedName || s.userId.name,
						email: s.userId.email,
						phone: s.userId.phone,
						area: s.connectedAreaId.name,
						sadhnaGroup: CLASS_LEVEL[s.userId.sadhnaGroup],
						status: EVENT_BOOKING_STATUS[s.status],
						facilitator:
							s.userId.facilitator?.initiatedName ||
							s.userId.facilitator?.name,
						accomodation:
							ACCOMODATION_CATEGORY[s.accomodationCategory].name,
						transportation: s.transportationAmount ? 'Yes' : 'No',
						totalAmount: s.finalAmount,
						amountPaid: s.tokenAmount,
					})),
					fileName,
				})
				dispatch({ type: HIDE_MAIN_LOADER })
			})
			.catch((e) => {
				dispatch({ type: HIDE_MAIN_LOADER })
			})
	}

	// const filteredData = data.filter((b) => {
	// 	return (
	// 		((filters.status.length <= 1 && filters.status[0] === 'ALL') ||
	// 			filters.status.includes(b.status)) &&
	// 		((filters.areaId.length <= 1 && filters.areaId[0] === 'ALL') ||
	// 			filters.areaId.includes(b.connectedAreaId._id)) &&
	// 		((filters.transportation.length <= 1 &&
	// 			filters.transportation[0] === 'ALL') ||
	// 			filters.transportation.includes(!!b.transportationAmount)) &&
	// 		((filters.accomodation.length <= 1 &&
	// 			filters.accomodation[0] === 'ALL') ||
	// 			filters.accomodation.includes(b.accomodationCategory))
	// 	)
	// })

	return (
		<div className="screen report-screen">
			<div className="filter-header">
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Area
					</InputLabel>
					<Select
						multiple
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={filters.areaId}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Area"
						name="areaId"
					>
						<MenuItem value={'ALL'}>All Areas</MenuItem>
						{getAreas().map((area) => (
							<MenuItem value={area._id}>{area.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Accomodation
					</InputLabel>
					<Select
						multiple
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={filters.accomodation}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Accomodation"
						name="accomodation"
					>
						<MenuItem value={'ALL'}>
							All Accomodation Types
						</MenuItem>
						{Object.values(ACCOMODATION_CATEGORY).map((acc) => (
							<MenuItem value={acc.id}>{acc.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Transportation
					</InputLabel>
					<Select
						multiple
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={filters.transportation}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Transportation"
						name="transportation"
					>
						<MenuItem value={'ALL'}>Both Yes and No</MenuItem>
						<MenuItem value={true}>Yes</MenuItem>
						<MenuItem value={false}>No</MenuItem>
					</Select>
				</FormControl>
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Status
					</InputLabel>
					<Select
						multiple
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={filters.status}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Status"
						name="status"
					>
						<MenuItem value={'ALL'}>All Status</MenuItem>
						{Object.keys(EVENT_BOOKING_STATUS).map((statusKey) => (
							<MenuItem value={statusKey}>
								{EVENT_BOOKING_STATUS[statusKey]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Button
					startIcon={<CloudDownload />}
					color="primary"
					variant="contained"
					onClick={download}
					style={{ marginBottom: '20px' }}
				>
					Download
				</Button>
			</div>
			<DataGrid
				rows={data.map((s) => ({
					...s,
					id: s._id,
					name: s.userId.initiatedName || s.userId.name,
					email: s.userId.email,
					phone: s.userId.phone,
					area: s.connectedAreaId.name,
					status: EVENT_BOOKING_STATUS[s.status],
					facilitator:
						s.userId.facilitator?.initiatedName ||
						s.userId.facilitator?.name,
					accomodation:
						ACCOMODATION_CATEGORY[s.accomodationCategory].name,
					transportation: s.transportationAmount ? 'Yes' : 'No',
				}))}
				autoHeight
				columns={headCells.map((col) => ({
					...col,
					sortable: true,
					disableClickEventBubbling: true,
					resizable: true,
					...(isMobileScreen()
						? rowWidthConfig[col.field].mobile ||
						  rowWidthConfig[col.field].default
						: rowWidthConfig[col.field].default),
				}))}
				pageSize={filters.per_page}
				rowCount={rowCount}
				rowsPerPageOptions={[5, 10, 20]}
				pagination
				paginationMode="server"
				onPageChange={handleUserData}
				disableColumnFilter
				loading={loading}
				onPageSizeChange={onPageSizeChange}
			/>
		</div>
	)
}

export default ReportScreen
