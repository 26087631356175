import {utils, writeFile} from 'xlsx';
import { uploadFile } from '../services/misc'


export const getToken = () => localStorage.getItem(process.env.AUTH_TOKEN)

export const setToken = (token) => localStorage.setItem(process.env.AUTH_TOKEN, token)

export const isUserloggedIn = () => {
	let userInfo = JSON.parse(localStorage.getItem('UserProfile')) || {}
	return !!(userInfo.token && userInfo.loggedIn)
}

export const serialize = (param) => {
	const str = []
	for (let p in param)
		if (param.hasOwnProperty(p) && !!param[p]) {
			str.push(encodeURIComponent(p) + '=' + encodeURIComponent(param[p]))
		}
	const serialized = str.join('&')
	return serialized ? `?${serialized}` : serialized
}

export const uploadBlobFile = (blob, filename = new Date().getTime()) => {
	var fd = new FormData()
	fd.append('file', blob, filename + '.wav')
	return uploadFile(fd)
}

export const getCurrentUser = () => {
	const userProfile = localStorage.getItem('UserProfile')
	if (userProfile) {
		try {
			return JSON.parse(userProfile)
		} catch {
			return {}
		}
	}
	return {}
}

export const extractYoutubeID = (url) => {
	url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
}

export const isMobileScreen = () => {
	return window.innerWidth <= 768
}

export const isAreaLeader = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(4)) {
		return true
	}
	return false
}

export const isAsstDirector = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(5)) {
		return true
	}
	return false
}

export const isGenSecretary = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(6)) {
		return true
	}
	return false
}

export const isDirector = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(7)) {
		return true
	}
	return false
}

export const isFacilitator = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(3)) {
		return true
	}
	return false
}

export const isFrontliner = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(2)) {
		return true
	}
	return false
}

export const isApprovedMember = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(1)) {
		return true
	}
	return false
}

export const removeItemOnce = (arr, value) => {
	var index = arr.indexOf(value)
	if (index > -1) {
		arr.splice(index, 1)
	}
	return arr
}

export const getQueryObj = (queryString) => {
	const queries = queryString.slice(1).split('&')
	const data = {}
	queries.forEach((query) => {
		const [key, value] = query.split('=')
		data[key] = value
	})
	return data
}

export const getAreas = () => {
	if (localStorage.getItem('AREAS')) {
		return JSON.parse(localStorage.getItem('AREAS'))
	}
	return []
}

export const exportFromJSON = ({data, fileName}) => {
	// Make Workbook of excel
	const wb = utils.book_new()

	// Export json to Worksheet of Excel ( only array possible )
	const jsonToSheet = utils.json_to_sheet(data)
	// Add Worksheet to Workbook ( workbook contains one or more worksheets )
	utils.book_append_sheet(wb, jsonToSheet, "Sheet 1")

	// Export excel file
	writeFile(wb, `${fileName}.xlsx`)
}
