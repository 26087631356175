import { API_URL, METHOD } from "../constants/network";
import { serialize } from "../utils";
import { fetchAPI } from "../utils/network";

export const searchUser = (params) => {
	const url = `${API_URL.SEARCH}${serialize(params)}`;
	return fetchAPI({
		url,
	});
};

export const getUsers = (params) => {
	const url = `${API_URL.USER}${serialize(params)}`;
	return fetchAPI({
		url,
	});
};

export const updateUser = (userId, updationObj) => {
	const url = `${API_URL.USER}/${userId}`;
	return fetchAPI({
		url,
		method: METHOD.PUT,
		body: updationObj,
	});
};

export const changeUserRole = (userId, userRole) => {
	const url = `${API_URL.USER}/${userId}`;
	return fetchAPI({
		url,
		method: METHOD.PUT,
		body: { userRole },
	});
};

export const assignMembersToFrontliner = (areaId, frontlinerId, assignedUser) => {
	const url = `${API_URL.AREA}/${areaId}/frontliners/assign`;
	return fetchAPI({
		url,
		method: METHOD.POST,
		body: {
			frontliner: frontlinerId,
			assignedUser
		}
	})
}

export const createUser = (body) => {
	const url = `${API_URL.USER_WEB}`;
	return fetchAPI({
		url,
		method: METHOD.POST,
		body
	});
};
