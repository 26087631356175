import PropTypes from "prop-types";
import classNames from "classnames";
import {
	daysOfWeek,
	createDaysForCurrentMonth,
	createDaysForNextMonth,
	createDaysForPreviousMonth,
	isWeekendDay,
	getMonthDropdownOptions,
	getYearDropdownOptions,
} from "./helpers";
import "./style.scss";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

Calendar.propTypes = {
	className: PropTypes.string,
	yearAndMonth: PropTypes.arrayOf(PropTypes.number).isRequired, // e.g. [2021, 6] for June 2021
	onYearAndMonthChange: PropTypes.func.isRequired,
	dayLabel: PropTypes.any,
	renderDay: PropTypes.func,
	onClickDay: PropTypes.func,
	schedule: PropTypes.object
};
export default function Calendar({
	className = "",
	yearAndMonth = [2021, 6],
	onYearAndMonthChange,
	renderDay = () => null,
	dayLabel: DayLabel,
	onClickDay = () => null,
	schedule = {}
}) {
	const [year, month] = yearAndMonth;

	let currentMonthDays = createDaysForCurrentMonth(year, month);
	let previousMonthDays = createDaysForPreviousMonth(
		year,
		month,
		currentMonthDays
	);
	let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);
	let calendarGridDayObjects = [
		...previousMonthDays,
		...currentMonthDays,
		...nextMonthDays,
	];

	const handleMonthNavBackButtonClick = () => {
		let nextYear = year;
		let nextMonth = month - 1;
		if (nextMonth === 0) {
			nextMonth = 12;
			nextYear = year - 1;
		}
		onYearAndMonthChange([nextYear, nextMonth]);
	};

	const handleMonthNavForwardButtonClick = () => {
		let nextYear = year;
		let nextMonth = month + 1;
		if (nextMonth === 13) {
			nextMonth = 1;
			nextYear = year + 1;
		}
		onYearAndMonthChange([nextYear, nextMonth]);
	};

	const handleMonthSelect = (evt) => {
		let nextYear = year;
		let nextMonth = parseInt(evt.target.value, 10);
		onYearAndMonthChange([nextYear, nextMonth]);
	};

	const handleYearSelect = (evt) => {
		let nextMonth = month;
		let nextYear = parseInt(evt.target.value, 10);
		onYearAndMonthChange([nextYear, nextMonth]);
	};

	return (
		<div className="calendar-root">
			<div className="navigation-header">
				<Button onClick={handleMonthNavBackButtonClick} variant="contained" color="primary">
					Previous
				</Button>
				&nbsp;&nbsp;&nbsp;
				<FormControl variant="outlined">
					<InputLabel id="month-label">Month</InputLabel>
					<Select
						labelId="month-label"
						id="month"
						value={month}
						onChange={handleMonthSelect}
						label="Month"
						className="month-select"
					>
						{getMonthDropdownOptions().map(({ label, value }) => (
							<MenuItem value={value} key={value}>{label}</MenuItem>
						))}
					</Select>
				</FormControl>
				&nbsp;&nbsp;&nbsp;
				<FormControl variant="outlined">
					<InputLabel id="year-label">Year</InputLabel>
					<Select
						labelId="year-label"
						id="year"
						value={year}
						onChange={handleYearSelect}
						label="Year"
						className="year-select"
					>
						{getYearDropdownOptions(year).map(({ label, value }) => (
							<MenuItem value={value} key={value}>{label}</MenuItem>
						))}
					</Select>
				</FormControl>
				&nbsp;&nbsp;&nbsp;
				{/* <select
					className="month-select"
					value={month}
					onChange={handleMonthSelect}
				>
					{getMonthDropdownOptions().map(({ label, value }) => (
						<option value={value} key={value}>
							{label}
						</option>
					))}
				</select> */}
				{/* <select
					className="year-select"
					value={year}
					onChange={handleYearSelect}
				>
					{getYearDropdownOptions(year).map(({ label, value }) => (
						<option value={value} key={value}>
							{label}
						</option>
					))}
				</select> */}
				<Button onClick={handleMonthNavForwardButtonClick} variant="contained" color="primary">
					Next
				</Button>
			</div>
			<div className="days-of-week">
				{daysOfWeek.map((day, index) => (
					<div
						key={day}
						className={classNames("day-of-week-header-cell", {
							"weekend-day": [6, 0].includes(index),
						})}
					>
						{day}
					</div>
				))}
			</div>
			<div className="days-grid">
				{calendarGridDayObjects.map((day) => (
					<div
						key={day.dateString}
						className={classNames("day-grid-item-container", {
							"weekend-day": isWeekendDay(day.dateString),
							"current-month": day.isCurrentMonth,
						})}
					>
						<div
							className="day-content-wrapper"
							onClick={(event) => {onClickDay(day);}}
						>
							{renderDay(day)}
						</div>
						<DayLabel />
					</div>
				))}
			</div>
		</div>
	);
}

CalendarDayHeader.propTypes = {
	calendarDayObject: PropTypes.object.isRequired,
};
export function CalendarDayHeader({ calendarDayObject }) {
	return (
		<div className="day-grid-item-header">{calendarDayObject.dayOfMonth}</div>
	);
}
