export const USER_ROLE = [
	'Non Coastok',
	'Coastok Member',
	'Frontliner',
	'Facilitator',
	'Area Leader',
	'Assistant Director',
	'General Secretary',
	'Director',
]

export const MARITAL_STATUS = {
	1: 'SINGLE',
	2: 'MARRIED',
	3: 'DIVORCED',
	4: 'WIDOWED',
}

export const GENDER = {
	1: 'MALE',
	2: 'FEMALE',
	3: 'OTHER',
}

export const FEST_TYPE = {
	1: 'UDGAAR',
	2: 'VIPLAVA',
	3: 'TEMPLE_CONNECT',
	4: 'COLLEGE_COACHING',
	5: 'VALUE_EDUCATION',
	6: 'CAMP',
	7: 'COASTOK_VIDEOS',
}

export const FILE_TYPE = {
	1: 'AUDIO',
	2: 'VIDEO',
	3: 'IMAGE',
}

export const ADMIN_ROLE = ['No_Access', 'Read', 'Write']

export const QUESTION_CATEGORY = {
	1: 'PERSONAL',
	2: 'PREACHING',
	3: 'OTHER',
}

export const STATUS = ['Default', 'Active', 'Inactive', 'Deleted']

export const ALERT = {
	SUCCESS: 'success',
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info',
}

export const PROFESSIONS = [
	'Accountant',
	'Actor',
	'Agriculture and Farm',
	'Aircraft Engineer',
	'Animation',
	'Architect',
	'Artist',
	'Audio and Music',
	'Businessperson',
	'Carpenter and Woodwork',
	'Chartered Accountant and CS',
	'Chef and Cook services',
	'Civil Engineer',
	'Civil Servant',
	'Content and Script',
	'Dietitian',
	'Doctor or Physician',
	'Drama and Filmmaker',
	'Electrical and Electronics',
	'Event Manager',
	'Finance',
	'Flight attendant',
	'Government Services',
	'Graphic Designer',
	'Gym and Fitness',
	'Hotel Management',
	'Humanitarian Worker',
	'Judge',
	'Lawyer',
	'Makeup Artist',
	'Mechanical and Automobile Engineer',
	'Media and Journalism',
	'Medical Services',
	'Merchant Navy',
	'Model',
	'Pharmacist',
	'Photographer',
	'Pilot',
	'Politician',
	'Psychiatrist',
	'Psychologist',
	'Radio Services',
	'Restaurant Services',
	'Sales and Marketing',
	'Scientist',
	'Software Engineer',
	'Sportsperson',
	'Student',
	'Tailor',
	'Teacher',
	'Technician',
	'Travel and Transport Services',
	'Veterinarian',
	'Yoga and Spirituality',
	'Unemployed',
	'Other',
]

export const EXPERTISE_AREAS = [
	'Animation',
	'Art and Painting',
	'Article Writing',
	'Audio and Music',
	'Automobile Driving',
	'Camp or Yatra Management',
	'Computer and MS Office Skills',
	'Cooking',
	'Debate',
	'Design and Decoration',
	'Digital Marketing',
	'Drama',
	'Education and Teaching',
	'Event Management',
	'Facilitation',
	'Fighting and Boxing',
	'Follow Up',
	'Fund Raising',
	'Good Speaking Skills',
	'Graphic Designing',
	'Leadership',
	'Medical Services',
	'Photo and Video Shooting',
	'PPT Making',
	'Public Relationships (PR)',
	'Scientific Research and Preaching',
	'Social Media Platform',
	'Software Programming',
	'Video Editing',
	'Yoga and Exercise',
	'Other',
]

export const CLASS_LEVEL = {
	1: 'VL1',
	2: 'VL2',
	3: 'VL3',
	4: 'NS',
	5: 'SPS',
	6: 'DPS',
	7: 'BVS',
	8: 'SNS',
	9: 'BSS',
	0: 'Other',
}

export const EVENT_BOOKING_STATUS = {
	0: 'INACTIVE',
	1: 'PENDING',
	2: 'SUBSIDY_REQUESTED',
	3: 'SUBSIDY_APPROVED',
	4: 'REQUEST_DENIED',
	5: 'ORDERED',
	6: 'PAYMENT_INPROGRESS',
	7: 'CONFIRMED',
	8: 'FAILED',
	9: 'CASH_PENDING',
	10: 'CASH_CONFIRMED',
	11: 'PARTIALLY_PAID',
	12: 'CASH_PARTIALLY_PAID',
}

export const ACCOMODATION_CATEGORY = {
	0: {
		id: 0,
		name: 'Dormitory',
		amount: 200,
	},
	1: {
		id: 1,
		name: 'Room',
		amount: 600,
	},
	2: {
		id: 2,
		name: 'Premium Room',
		amount: 1300,
	},
}

export const FUND_TRANSACTION_STATUS = {
	0: 'INACTIVE',
	1: 'PENDING',
	2: 'ON_HOLD',
	3: 'APPROVED',
	4: 'DELETED',
}
