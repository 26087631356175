import React, { useState } from "react";
import clsx from "clsx";
import {
	List,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	makeStyles,
} from "@material-ui/core";
import { logoutAction } from "../../actions/auth";
import { changeUserRole } from "../../services/user";
import { isGenSecretary, isUserloggedIn } from "../../utils";
import { hideSidebar, showAlert, showSidebar } from "../../actions";
import {
	Home,
	LocationCity,
	QuestionAnswer,
	Person,
	Storage,
	ExpandLess,
	ExpandMore,
	Notifications,
	Alarm,
	ExitToApp,
	AccountBox,
	Lock,
	Assessment,
	EventSeat,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { ROUTE } from "../../constants/route";
import { getCurrentUser } from "../../utils";
import { COLOR } from "../../constants/theme";
import { connect, useDispatch, useSelector } from "react-redux";
import { ALERT, USER_ROLE } from "../../constants";
import "./style.scss";

const useStyles = makeStyles((theme) => ({
	icon: {
		minWidth: "unset",
		paddingRight: "20px",
		color: COLOR.PRIMARY,
		"&:hover": {
			color: COLOR.SECONDARY,
		},
	},
	menuItem: {
		"&:hover": {
			background: COLOR.LIGHT_BLACK,
			color: COLOR.SECONDARY,
			"& $icon": {
				color: COLOR.SECONDARY,
			},
		},
	},
	activeItem: {
		background: COLOR.LIGHT_BLACK,
		color: COLOR.SECONDARY,
		"& $icon": {
			color: COLOR.SECONDARY,
		},
	},
}));

const Sidebar = (props) => {
	const { drawerOpen } = useSelector((store) => store.commonContent);
	const dispatch = useDispatch();
	const [userMenuOpen, setUserMenuOpen] = useState("");

	// const toggleDrawerOpen = () => {
	//   setDrawerOpen(!drawerOpen);
	//   setUserMenuOpen("");
	// };

	const { userProfile, events } = props;
	const isLogin = !!userProfile || isUserloggedIn();
	const user = userProfile || getCurrentUser();
	const classes = useStyles();

	const handleLogout = () => {
		const { logoutAction } = props;
		logoutAction();
		localStorage.removeItem(process.env.AUTH_TOKEN);
		localStorage.removeItem("UserProfile");
		localStorage.removeItem("AREAS");
		props.history.push(ROUTE.HOME.path);
	};

	const switchUserRole = (userId, role) => {
		const { showAlert } = props;
		changeUserRole(userId, role).then(() => {
			handleLogout();
			showAlert({
				message:
					"You are switching your role. Please login with the same credentials again.",
				severity: ALERT.ERROR,
				duration: 5000,
			});
		});
	};

	return (
		<div
			className={`drawer ${drawerOpen ? "drawer-open" : "drawer-close"}`}
			onMouseOver={() => {
				if (!drawerOpen) dispatch(showSidebar());
			}}
			onMouseLeave={() => {
				if (drawerOpen) {
					dispatch(hideSidebar());
					setUserMenuOpen("");
				}
			}}
		>
			<Divider light={true} />
			<List>
				<Link onClick={() => dispatch(hideSidebar())} to={ROUTE.DASHBOARD.path}>
					<ListItem
						button
						className={clsx(classes.menuItem, {
							[classes.activeItem]: props.match.url.includes(
								ROUTE.DASHBOARD.path
							),
						})}
					>
						<ListItemIcon className={classes.icon}>
							<Home />
						</ListItemIcon>
						<ListItemText primary={"Dashboard"} />
					</ListItem>
				</Link>
				{user.roles.some((r) => ROUTE.MANAGE_AREA.roles.indexOf(r) >= 0) && (
					<Link
						onClick={() => dispatch(hideSidebar())}
						to={ROUTE.MANAGE_AREA.path}
					>
						<ListItem
							button
							className={clsx(classes.menuItem, {
								[classes.activeItem]: props.match.url.includes(
									ROUTE.MANAGE_AREA.path
								),
							})}
						>
							<ListItemIcon className={classes.icon}>
								<LocationCity />
							</ListItemIcon>
							<ListItemText primary={"Manage Area"} />
						</ListItem>
					</Link>
				)}
				{user.roles.some(
					(r) => ROUTE.ANSWER_QUESTION.roles.indexOf(r) >= 0
				) && (
					<Link
						onClick={() => dispatch(hideSidebar())}
						to={ROUTE.ANSWER_QUESTION.path}
					>
						<ListItem
							button
							className={clsx(classes.menuItem, {
								[classes.activeItem]: props.match.url.includes(
									ROUTE.ANSWER_QUESTION.path
								),
							})}
						>
							<ListItemIcon className={classes.icon}>
								<QuestionAnswer />
							</ListItemIcon>
							<ListItemText primary={"Answer Questions"} />
						</ListItem>
					</Link>
				)}
				{user.roles.some((r) => ROUTE.USER.roles.indexOf(r) >= 0) && (
					<>
						<ListItem
							button
							className={clsx(classes.menuItem, {
								[classes.activeItem]: props.match.url.includes(ROUTE.USER.path),
							})}
							onClick={() => setUserMenuOpen(ROUTE.USER.path)}
						>
							<ListItemIcon className={classes.icon}>
								<Person />
							</ListItemIcon>
							<ListItemText primary={"Manage User"} />
							{userMenuOpen === ROUTE.USER.path ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={userMenuOpen === ROUTE.USER.path} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<Link
									onClick={() => {
										dispatch(hideSidebar());
										setUserMenuOpen("");
									}}
									to={ROUTE.INACTIVE_USER.path}
								>
									<ListItem
										button
										className={clsx(classes.menuItem, {
											[classes.activeItem]: props.match.url.includes(
												ROUTE.INACTIVE_USER.path
											),
										})}
									>
										<ListItemIcon className={classes.icon} />
										<ListItemText primary="Inactive Members" />
									</ListItem>
								</Link>
								<Link
									onClick={() => {
										dispatch(hideSidebar());
										setUserMenuOpen("");
									}}
									to={ROUTE.NON_COASTOK_USER.path}
								>
									<ListItem
										button
										className={clsx(classes.menuItem, {
											[classes.activeItem]: props.match.url.includes(
												ROUTE.NON_COASTOK_USER.path
											),
										})}
									>
										<ListItemIcon className={classes.icon} />
										<ListItemText primary="Non Coastok Members" />
									</ListItem>
								</Link>
								<Link
									onClick={() => {
										dispatch(hideSidebar());
										setUserMenuOpen("");
									}}
									to={ROUTE.PENDING_USER.path}
								>
									<ListItem
										button
										className={clsx(classes.menuItem, {
											[classes.activeItem]: props.match.url.includes(
												ROUTE.PENDING_USER.path
											),
										})}
									>
										<ListItemIcon className={classes.icon} />
										<ListItemText primary="Pending Members" />
									</ListItem>
								</Link>
								<Link
									onClick={() => {
										dispatch(hideSidebar());
										setUserMenuOpen("");
									}}
									to={ROUTE.APPROVED_USER.path}
								>
									<ListItem
										button
										className={clsx(classes.menuItem, {
											[classes.activeItem]: props.match.url.includes(
												ROUTE.APPROVED_USER.path
											),
										})}
									>
										<ListItemIcon className={classes.icon} />
										<ListItemText primary="Approved Members" />
									</ListItem>
								</Link>
								<Link
									onClick={() => {
										dispatch(hideSidebar());
										setUserMenuOpen("");
									}}
									to={ROUTE.ALL_USER.path}
								>
									<ListItem
										button
										className={clsx(classes.menuItem, {
											[classes.activeItem]: props.match.url.includes(
												ROUTE.ALL_USER.path
											),
										})}
									>
										<ListItemIcon className={classes.icon} />
										<ListItemText primary="All Members" />
									</ListItem>
								</Link>
							</List>
						</Collapse>
					</>
				)}
				{user.roles.some(
					(r) => ROUTE.MANAGE_APP_CONTENT.roles.indexOf(r) >= 0
				) && (
					<>
						<ListItem
							button
							className={clsx(classes.menuItem, {
								[classes.activeItem]: props.match.url.includes(
									ROUTE.MANAGE_APP_CONTENT.path
								),
							})}
							onClick={() => setUserMenuOpen(ROUTE.MANAGE_APP_CONTENT.path)}
						>
							<ListItemIcon className={classes.icon}>
								<Storage />
							</ListItemIcon>
							<ListItemText primary={"Manage App Content"} />
							{userMenuOpen === ROUTE.MANAGE_APP_CONTENT.path ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={userMenuOpen === ROUTE.MANAGE_APP_CONTENT.path} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<Link
									onClick={() => {
										dispatch(hideSidebar());
										setUserMenuOpen("");
									}}
									to={ROUTE.MANAGE_VIDEOS.path}
								>
									<ListItem
										button
										className={clsx(classes.menuItem, {
											[classes.activeItem]: props.match.url.includes(
												ROUTE.MANAGE_VIDEOS.path
											),
										})}
									>
										<ListItemIcon className={classes.icon} />
										<ListItemText primary="Videos" />
									</ListItem>
								</Link>
								<Link
									onClick={() => {
										dispatch(hideSidebar());
										setUserMenuOpen("");
									}}
									to={ROUTE.MANAGE_IMAGES.path}
								>
									<ListItem
										button
										className={clsx(classes.menuItem, {
											[classes.activeItem]: props.match.url.includes(
												ROUTE.MANAGE_IMAGES.path
											),
										})}
									>
										<ListItemIcon className={classes.icon} />
										<ListItemText primary="Images" />
									</ListItem>
								</Link>
							</List>
						</Collapse>
					</>
				)}
				{user.roles.some(
					(r) => ROUTE.EVENTS.roles.indexOf(r) >= 0
				) && (
					<>
						<ListItem
							button
							className={clsx(classes.menuItem, {
								[classes.activeItem]: props.match.url.includes(
									ROUTE.EVENTS.path
								),
							})}
							onClick={() => setUserMenuOpen(ROUTE.EVENTS.path)}
						>
							<ListItemIcon className={classes.icon}>
								<EventSeat />
							</ListItemIcon>
							<ListItemText primary={"Manage Events"} />
							{userMenuOpen === ROUTE.EVENTS.path ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={userMenuOpen === ROUTE.EVENTS.path} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{events?.filter((event) => {
									if(isGenSecretary()) {
										return true
									}
									return event.status
								})?.map((event) => {
									return (<a
										onClick={() => {
											dispatch(hideSidebar());
											setUserMenuOpen("");
										}}
										href={`${ROUTE.EVENT_MANAGE_PAGE.path}/${event.eventId}`}
									>
										<ListItem
											button
											className={clsx(classes.menuItem, {
												[classes.activeItem]: props.match.url.includes(
													`${ROUTE.EVENT_MANAGE_PAGE.path}/${event.eventId}`
												),
											})}
										>
											<ListItemIcon className={classes.icon} />
											<ListItemText primary={event.title} />
										</ListItem>
									</a>)
								})}
							</List>
						</Collapse>
					</>
				)}
				{user.roles.some((r) => ROUTE.PLEDGING.roles.indexOf(r) >= 0) && (
					<Link
						onClick={() => dispatch(hideSidebar())}
						to={ROUTE.PLEDGING.path}
					>
						<ListItem
							button
							className={clsx(classes.menuItem, {
								[classes.activeItem]: props.match.url.includes(
									ROUTE.PLEDGING.path
								),
							})}
						>
							<ListItemIcon className={classes.icon}>
								<QuestionAnswer />
							</ListItemIcon>
							<ListItemText primary={"Pledging"} />
						</ListItem>
					</Link>
				)}
				{user.roles.some((r) => ROUTE.NOTIFICATION.roles.indexOf(r) >= 0) && (
					<Link
						onClick={() => dispatch(hideSidebar())}
						to={ROUTE.NOTIFICATION.path}
					>
						<ListItem
							button
							className={clsx(classes.menuItem, {
								[classes.activeItem]: props.match.url.includes(
									ROUTE.NOTIFICATION.path
								),
							})}
						>
							<ListItemIcon className={classes.icon}>
								<Notifications />
							</ListItemIcon>
							<ListItemText primary={"Custom Notification"} />
						</ListItem>
					</Link>
				)}
				{user.roles.some(
					(r) => ROUTE.SCHEDULE_NOTIFICATION.roles.indexOf(r) >= 0
				) && (
					<Link
						onClick={() => dispatch(hideSidebar())}
						to={ROUTE.SCHEDULE_NOTIFICATION.path}
					>
						<ListItem
							button
							className={clsx(classes.menuItem, {
								[classes.activeItem]: props.match.url.includes(
									ROUTE.SCHEDULE_NOTIFICATION.path
								),
							})}
						>
							<ListItemIcon className={classes.icon}>
								<Alarm />
							</ListItemIcon>
							<ListItemText primary={"Scheduled Notification"} />
						</ListItem>
					</Link>
				)}
				{user.roles.some(
					(r) => ROUTE.REPORTS.roles.indexOf(r) >= 0
				) && (
					<Link
						onClick={() => dispatch(hideSidebar())}
						to={ROUTE.REPORTS.path}
					>
						<ListItem
							button
							className={clsx(classes.menuItem, {
								[classes.activeItem]: props.match.url.includes(
									ROUTE.REPORTS.path
								),
							})}
						>
							<ListItemIcon className={classes.icon}>
								<Assessment />
							</ListItemIcon>
							<ListItemText primary={"Reports"} />
						</ListItem>
					</Link>
				)}
			</List>
			
			<Divider />
			<List>
				{user?.roles
					?.filter((role) => {
						if (role > 3 && role !== user.userRole) {
							return true;
						}
						return false;
					})
					.map((role) => (
						<ListItem
							button
							onClick={() => switchUserRole(user._id, role)}
							className={classes.menuItem}
						>
							<ListItemIcon className={classes.icon}>
								<AccountBox />
							</ListItemIcon>
							<ListItemText primary={`Login as ${USER_ROLE[role]}`} />
						</ListItem>
					))}
				{user.roles.some(
					(r) => ROUTE.RESET_PASSWORD.roles.indexOf(r) >= 0
				) && (
					<Link
						onClick={() => dispatch(hideSidebar())}
						to={ROUTE.RESET_PASSWORD.path}
					>
						<ListItem button className={classes.menuItem}>
							<ListItemIcon className={classes.icon}>
								<Lock />
							</ListItemIcon>
							<ListItemText primary={"Change Password"} />
						</ListItem>
					</Link>
				)}
				<ListItem button onClick={handleLogout} className={classes.menuItem}>
					<ListItemIcon className={classes.icon}>
						<ExitToApp />
					</ListItemIcon>
					<ListItemText primary={"Log out"} />
				</ListItem>
			</List>
		</div>
	);
};

const mapStateToProps = (state) => ({
	userProfile: state.commonContent.user,
});

const mapDispatchToProps = {
	showAlert,
	logoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
