import React from "react";
import { io } from "socket.io-client";
import { IconButton } from "@material-ui/core";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import { getAreaDetails } from "../../services/area";
import { API_BASE_URL } from "../../utils/network";
import "./style.scss";
import { getPledgeSummaryAction } from "../../actions/pledge";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTE } from "../../constants/route";

class ViewPedging extends React.Component {
	constructor(props) {
		super(props);

		document.onfullscreenchange = (event) => {
			this.setState({
				fullScreen: !this.state.fullScreen,
			});
		};

		/*
		// provide api url in the arguments
		const socket = io(API_BASE_URL);

		socket.on("connect", () => {
			console.log(">>>>>>>", socket.id); // x8WIv7-mJelg7on_ALbx
		});

		// form.addEventListener('submit', function (e) {
		//     e.preventDefault();
		//     if (input.value) {
		//         socket.emit('chat message', input.value);
		//         input.value = '';
		//     }
		// });

		socket.on("pledge", (data) => {
			// {areaId: "ssasasasa", areaUserTotal: 12, areaUserPledged: 8, areaPledgeTotal: 45}

			const areas = this.state.areas;
			const rowIndex = areas.findIndex((area) => area.id === data.areaId);

			if (!!(rowIndex + 1)) {
				areas[rowIndex] = {
					...areas[rowIndex],
					totalMembers: data.areaUserTotal,
					pledgedMembers: data.areaUserPledged,
					pledgeCount: data.areaPledgeTotal,
				};
				this.placeRow(areas, rowIndex, +data.areaPledgeTotal);
			} else {
				// mark it as "Others" area
				const fallbackRowIndex = areas.findIndex((area) => area.id === "");
				areas[fallbackRowIndex] = {
					...areas[fallbackRowIndex],
					totalMembers: data.areaUserTotal,
					pledgedMembers: data.areaUserPledged,
					pledgeCount: data.areaPledgeTotal,
				};
				this.placeRow(areas, fallbackRowIndex, +data.areaPledgeTotal);
			}
		});
        */
	}
	state = {
		areaPledgeTotal: 0,
		areas: [
			{
				areaName: "Others",
				id: "",
				totalMembers: 0,
				pledgedMembers: 0,
				pledgeCount: 0,
			},
		],
		fullScreen: false,
	};

	openFullscreen = () => {
		const elem = document.querySelector(".pledging-container");
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Safari */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE11 */
			elem.msRequestFullscreen();
		}
	};

	closeFullScreen = () => {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitExitFullscreen) {
			/* Safari */
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			/* IE11 */
			document.msExitFullscreen();
		}
	};

	placeRow = (areas, rowIndex, priority) => {
		let flag = 0;
		let tempIndex = rowIndex;
		while (tempIndex - 1 >= 0) {
			// check all previous rows
			if (areas[tempIndex - 1].pledgeCount > priority) {
				// place the area on current row Index;
				const arr = areas;
				const element = arr[rowIndex];
				arr.splice(rowIndex, 1);
				arr.splice(tempIndex, 0, element);
				this.setState({
					areas: arr,
				});
				flag = 1;
				break;
			}
			tempIndex--;
		}
		if (!flag) {
			// place the row on top
			const arr = areas;
			const element = arr[rowIndex];
			arr.splice(rowIndex, 1);
			arr.splice(0, 0, element);
			this.setState({
				areas: arr,
			});
		}
	};

	componentDidMount() {
		const { match, getPledgeSummaryAction } = this.props;
		const { pledgeId } = match.params;

		getPledgeSummaryAction({ pledgeId }).then((areas) => {
			areas.data.sort(function compare(a, b) {
				if (a.pledgeData.areaPledgeTotal > b.pledgeData.areaPledgeTotal) {
					return -1;
				}
				return 0;
			});
			this.setState((prevState) => ({
				areas: [
					...areas.data.map((a) => {
						return {
							areaName: a.areaName,
							id: a.pledgeData.areaId,
							totalMembers: a.pledgeData.areaUserTotal,
							pledgedMembers: a.pledgeData.areaUserPledged,
							pledgeCount: a.pledgeData.areaPledgeTotal,
						};
					}),
					...prevState.areas,
				],
			}));
		});
	}

	render() {
		const { match } = this.props;
		return (
			<div
				className={`pledging-container ${
					this.state.fullScreen ? "component-wrapper" : ""
				}`}
			>
				<div className="row">
					<div className="col-md-12">
						<div className="d-flex justify-content-center flex-wrap align-items-center">
							<div className="d-flex align-items-center">
								<p className="text">
									{" "}
									<b>Total Pledges</b>{" "}
								</p>
								<h2 className="scoreboard yellow" id="total">
									{this.state.areas
										.map((a) => a.pledgeCount)
										.reduce(
											(previousValue, currentValue) =>
												previousValue + currentValue
										)}
								</h2>
								<p className="text">
									{" "}
									taken by{" "}
									<b>
										{this.state.areas
											.map((a) => a.pledgedMembers)
											.reduce(
												(previousValue, currentValue) =>
													previousValue + currentValue
											)}
									</b>{" "}
									devotees.
								</p>
							</div>
							<div>
								<IconButton
									onClick={
										this.state.fullScreen
											? this.closeFullScreen
											: this.openFullscreen
									}
								>
									{this.state.fullScreen ? <FullscreenExit /> : <Fullscreen />}
								</IconButton>
							</div>
						</div>
					</div>
				</div>
				<div
					className={
						this.state.areas.filter((area) => !!area.pledgeCount).length > 10 &&
						"table-wrapper"
					}
				>
					<table id="areaTable" className="table">
						<tbody>
							{this.state.areas.map((area) => (
								<tr
									id={area.id}
									style={{
										display: !!area.pledgeCount ? "" : "none",
									}}
								>
									<td>
										<Link
											to={`${ROUTE.PLEDGING.path}/${match.params.pledgeId}/${area.id}`}
										>
											{area.areaName} ({area.pledgedMembers}/{area.totalMembers}
											)
										</Link>
									</td>

									<td className="area-pledge-count">{area.pledgeCount}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	pledge: state.pledgeReducer,
});

const mapDispatchToProps = {
	getPledgeSummaryAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPedging);
