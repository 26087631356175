import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Modal,
	TextareaAutosize,
	TextField,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useEffect, useState } from 'react'
import CareIcon from '../../../assets/icons/CareIcon'
import { RAZORPAY_KEY_ID } from '../../../constants/config'
import { COLOR } from '../../../constants/theme'
import { EVENTS_UI_CONFIG } from '../../../data/event'
import { raiseSubsidyRequest, updateBooking } from '../../../services/viplava'

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 140,
	},

	modalRoot: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	modal: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 400,
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	reasonInput: {
		height: '88px',
		overflow: 'hidden',
		width: '100%',
		marginTop: '16px',
		padding: '12px',
	},
})

const PaymentSection = ({
	userDetails,
	booking,
	onClickingPay,
	onPaymentSuccess,
	onPaymentFailure,
	onRaiseSubsidyRequest,
	orderIdUpdated,
	unsetOrderIdUpdated,
	eventId,
	isTransportSelected,
}) => {
	const eventUiConfig = EVENTS_UI_CONFIG.find(
		(event) => event.uuid === eventId
	)
	const [verified, setVerified] = useState(false)
	const [subsidyModal, setSubsidyModal] = useState(false)
	const [subsidy, setSubsidy] = useState({
		amountAfterSubsidy: 0,
		subsidyReason:
			'Hare Krishna prabhuji. I am unable to pay the  complete amount due to <please provide the reason>',
	})
	const classes = useStyles()

	var options = {
		key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
		name: 'SP3C',
		order_id: booking.orderId, // For one time payment
		prefill: {
			name: userDetails.name,
			email: userDetails.email,
			contact: userDetails.phone,
		},
		theme: {
			color: COLOR.PRIMARY,
		},
		// This handler function will handle the success payment
		handler: function (response) {
			// alert(response.razorpay_payment_id);
			// alert("Payment Successfull");
			onPaymentSuccess()
		},
	}

	// eslint-disable-next-line no-undef
	var rzp1 = new Razorpay(options)

	useEffect(() => {
		if (booking?.orderId && verified && orderIdUpdated) {
			rzp1.open()
			unsetOrderIdUpdated()
		}
	}, [booking, verified, orderIdUpdated])

	rzp1.on('payment.failed', function (response) {
		// const code = response.error.code;
		// const description = response.error.description;
		// const source = response.error.source;
		// const step = response.error.step;
		// const reason = response.error.reason;
		// const order_id = response.error.metadata.order_id;
		// const payment_id = response.error.metadata.payment_id;
		onPaymentFailure(response.error)
	})

	const handleSubsidyInput = (e) => {
		setSubsidy((subsidy) => ({
			...subsidy,
			[e.target.name]: e.target.value,
		}))
	}

	const sendSubsidyRequest = async () => {
		if (booking.accomodationCategory === 0) {
			raiseSubsidyRequest({
				userId: userDetails._id,
				bookingId: booking.bookingId,
				amountAfterSubsidy: +subsidy.amountAfterSubsidy,
				subsidyReason: subsidy.subsidyReason,
			}).then((res) => {
				console.log('Hello', res)
				onRaiseSubsidyRequest(res.result)
			})
		} else {
			// change booking accomodation type and set transportation and donation as zero
			await updateBooking({
				eventId,
				userId: userDetails._id,
				bookingId: booking.bookingId,
				accomodationCategory: 0,
				donationAmount: 0,
				transportationAmount: booking.transportationAmount,
			})
			raiseSubsidyRequest({
				userId: userDetails._id,
				bookingId: booking.bookingId,
				...subsidy,
			}).then((res) => {
				onRaiseSubsidyRequest(res.result)
			})
		}
	}

	const payThroughCash = async () => {
		await updateBooking({
			eventId,
			userId: userDetails._id,
			bookingId: booking.bookingId,
			accomodationCategory: booking.accomodationCategory,
			transportationAmount:
				booking.transportationAmount || isTransportSelected,
			paymentMode: 'CASH',
			tokenAmount: booking.tokenAmount,
		}).then((res) => {
			onRaiseSubsidyRequest(res.result)
		})
	}

	return (
		<div className="payment-section">
			<div className={classes.modalRoot}>
				<Modal
					open={subsidyModal}
					onClose={() => setSubsidyModal(false)}
					className={classes.modal}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className={classes.paper}>
						<CareIcon />
						<Typography
							variant="h4"
							style={{
								marginBottom: '20px',
								textAlign: 'center',
							}}
						>
							If you feel you cannot pay complete amount, kindly
							fill this form. The details of this form would be
							shared with your Area leader and Facilitator.
						</Typography>
						<form>
							<TextField
								label="Amount you can pay"
								variant="outlined"
								defaultValue={+subsidy.amountAfterSubsidy}
								TabPa
								value={+subsidy.amountAfterSubsidy}
								name="amountAfterSubsidy"
								onChange={handleSubsidyInput}
								fullWidth
								type="number"
							/>
							<TextareaAutosize
								maxRows={4}
								aria-label="maximum height"
								placeholder="Reason"
								value={subsidy.subsidyReason}
								name="subsidyReason"
								onChange={handleSubsidyInput}
								minRows={4}
								className={classes.reasonInput}
								fullWidth
							/>
							<Button
								variant="contained"
								color="primary"
								onClick={() => sendSubsidyRequest()}
								style={{ marginTop: '20px' }}
							>
								Send Request
							</Button>
						</form>
					</div>
				</Modal>
			</div>
			<Card className={classes.root}>
				<CardActionArea>
					<CardMedia
						className={classes.media}
						image={eventUiConfig.paymentHeaderImage}
						title={eventUiConfig.title}
					/>
					<CardContent>
						<center>
							<Typography
								gutterBottom
								variant="h5"
								component="h2"
							>
								Please verify your details before doing final
								payment.
							</Typography>
							<hr />
							<Typography
								gutterBottom
								variant="h6"
								component="h2"
							>
								{eventUiConfig.title}
							</Typography>
						</center>
						<hr />
						<div></div>
						<Typography
							gutterBottom
							variant="body1"
							color="textPrimary"
							component="p"
						>
							Name:{' '}
							<b>
								{userDetails.initiatedName || userDetails.name}
							</b>
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
							color="textPrimary"
							component="p"
						>
							Contact: <b>{userDetails.phone}</b>
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
							color="textPrimary"
							component="p"
						>
							Email: <b>{userDetails.email}</b>
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
							color="textPrimary"
							component="p"
						>
							Chanting rounds: <b>{userDetails.chantingRounds}</b>
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
							color="textPrimary"
							component="p"
						>
							Connected Area: <b>{booking.connectedArea}</b>
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
							color="textPrimary"
							component="p"
						>
							Accomodation Type:{' '}
							<b>{booking.accomodationCategoryName}</b>
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
							color="textPrimary"
							component="p"
						>
							Transportation:{' '}
							<b>
								{booking.transportationAmount ||
								isTransportSelected
									? 'Yes'
									: 'No'}
							</b>
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
							color="textPrimary"
							component="p"
						>
							Total Amount:{' '}
							<b>
								₹{booking.finalAmount}
								{(eventUiConfig.allowOnlinePayment && !booking.tokenAmount) ? '*' : ''}
							</b>
						</Typography>
						{booking.tokenAmount && (
							<div>
								<Typography
									gutterBottom
									variant="body1"
									color="textPrimary"
									component="p"
								>
									Your booking confirmation amount:{' '}
									<b>
										₹{booking.tokenAmount}
										{eventUiConfig.allowOnlinePayment
											? '*'
											: ''}
									</b>
								</Typography>
								<Typography
									gutterBottom
									variant="body1"
									color="textPrimary"
									component="p"
								>
									Remaining amount:{' '}
									<b>
										₹{booking.finalAmount - booking.tokenAmount}
									</b>
								</Typography>
							</div>
						)}
						{eventUiConfig.showAmountBreakup && (
							<Typography
								gutterBottom
								variant="body"
								component="p"
							>
								{booking.registrationAmount &&
									`Registration (₹${booking.registrationAmount})`}
								{!!booking.donationAmount &&
									` + Donation (₹${booking.donationAmount})`}
								{!!booking.transportationAmount &&
									` + Transportation (₹${booking.transportationAmount})`}
								{!!booking.accomodationAmount &&
									` + Accomodation (₹${booking.accomodationAmount})`}
								{!!booking.outfitAmount &&
									` + Dhoti Kurta X ${booking.outfitCount} (₹${booking.outfitAmount})`}
								{!!booking.subsidy &&
									` - Discount (₹${booking.subsidy})`}
							</Typography>
						)}
						{!!booking.subsidy && (
							<div>
								<Typography
									gutterBottom
									variant="body1"
									color="textPrimary"
									component="p"
								>
									<b>{booking.subsidyReason}</b>
								</Typography>
							</div>
						)}
						{eventUiConfig.allowOnlinePayment && (
							<Typography
								gutterBottom
								variant="body"
								component="p"
							>
								*Transaction Charges excluded, if done through online mode
							</Typography>
						)}
					</CardContent>
				</CardActionArea>
				<CardActions>
					<div className="action-btns">
						{eventUiConfig.allowOnlinePayment &&
							(verified ? (
								<button
									className="primary-btn"
									id="rzp-button1"
									onClick={onClickingPay}
								>
									Pay online (transaction charges applied)
								</button>
							) : (
								<button
									className="primary-btn"
									onClick={() => setVerified(true)}
								>
									Verify details
								</button>
							))}
						{eventUiConfig.allowSubsidy &&
							!(
								booking.status === 'SUBSIDY_APPROVED' ||
								booking.status === 'SUBSIDY_REQUESTED' ||
								booking.status === 'SUBSIDY_FAILED'
							) && (
								<button
									className="secondary-btn"
									onClick={() => {
										setSubsidyModal(true)
									}}
								>
									Unable to Pay
								</button>
							)}
						{
							// localStorage.getItem('isViplavaFacilitator') === 'yes'
							eventUiConfig.allowCash && (
								<button
									className={
										eventUiConfig.allowOnlinePayment
											? 'secondary-btn'
											: 'primary-btn'
									}
									onClick={payThroughCash}
								>
									Pay through Cash mode
								</button>
							)
						}
					</div>
				</CardActions>
			</Card>
		</div>
	)
}

export default PaymentSection
