import React from "react";
import { TextField, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Send } from "@material-ui/icons";
import "./style.scss";
import { connect } from "react-redux";
import { sendBulkNotificationAction } from "../../actions/notification";
import { actionPages } from "./constants";
import { getAreaDetails } from "../../services/area";
import { ALERT } from "../../constants";
import { showAlert } from "../../actions";

class Notification extends React.Component {
	constructor(props){
		super(props);
		this.isAreaLeader = false;
		const audienceOptions = [
			{ key: "allsp3c", value: "ALL MEMBERS" },
			{ key: "sp3c", value: "SP3C MEMBERS" },
			{ key: "nonsp3c", value: "NON-SP3C MEMBERS" },
			{ key: "facilitator", value: "FACILITATORS" },
			{ key: "frontliner", value: "FRONTLINERS" },
			{ key: "areaLeader", value: "AREA LEADERS" },
			{ key: "asstDirector", value: "ASSISTANT DIRECTORS" },
		];
		if(JSON.parse(localStorage.getItem("UserProfile")).userRole === 4){
			this.isAreaLeader = true;
			audienceOptions.pop();
			audienceOptions.pop();
		}
		this.state = {
			audience: null,
			title: "",
			notificationAction: null,
			body: "",
			url: "",
			actionOptions: [],
			audienceOptions,
		};
	}

	componentDidMount() {
		if(JSON.parse(localStorage.getItem("UserProfile")).userRole === 4){
			getAreaDetails().then(area => {
				const areaIds = area.data.data.map(a => a._id);
				this.setState({
					areaId: areaIds[0]
				});
			});
		}
	}

	onInputChange = () => {};

	onChange = () => {};

	sendNotification = () => {
		const { sendBulkNotificationAction, showAlert } = this.props;
		const { audience, notificationAction, title, body, url, areaId } = this.state;
		if (this.validateForm()) {
			let requestBody = {
				title: title,
				body: body,
				url: url,
				action: url ? undefined : (notificationAction?.page ? notificationAction?.page : "dialog"),
				topic: audience.key,
				areaId: areaId
			};
			sendBulkNotificationAction({...requestBody, isAreaLeader: this.isAreaLeader}).then((response) => {
				showAlert({
					message:
						"Your notification has been sent successfully.",
					severity: ALERT.SUCCESS,
					duration: 3000,
				});
				this.setState({
						audience: null,
						title: "",
						notificationAction: null,
						body: "",
						url: "",
						actionOptions: []					
				})
			}).catch(() => {
				showAlert({
					message:
						"Your notification has been failed to send. Something went wrong.",
					severity: ALERT.ERROR,
					duration: 3000,
				});
			});
		}
	};

	validateForm = () => {
		const { audience, title, body } = this.state;
		if (audience && title && body) {
			return true;
		} else {
			return false;
		}
	};

	render() {
		const {
			audience,
			title,
			notificationAction,
			body,
			url,
			actionOptions,
			audienceOptions,
		} = this.state;
		return (
			<div className={`notification-container`}>
				<div className="grid-container">
					<div className="row">
						<div className="col-md-12">
							<h1>Send Notifications</h1>
							<h3 className="form-label">
								Notification Audience <span className="asterik">*</span>
							</h3>
							<h4 className="form-text">
								The selected audience would be able to see your notification.
							</h4>
							<Autocomplete
								className="m-top-12 m-bottom-16"
								options={audienceOptions}
								getOptionLabel={(option) => option.value || ""}
								value={audience}
								getOptionSelected={(option, value) => option.key === value.key}
								filterSelectedOptions
								onInputChange={this.onInputChange}
								onChange={(e, newValue) => {
									this.setState({ audience: newValue });
									if (newValue) {
										this.setState({
											actionOptions: actionPages[newValue.key],
										});
									} else {
										this.setState({ actionOptions: [] });
									}
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label="Audience"
										placeholder="Select Audience"
									/>
								)}
							/>
							<h3 className="form-label">
								Notification Title <span className="asterik">*</span>
							</h3>
							<h4 className="form-text">
								This would be the heading of the notification.
							</h4>
							<TextField
								className="m-top-12 m-bottom-16"
								label="Title"
								variant="outlined"
								value={title}
								name="title"
								onChange={(e) => this.setState({ title: e.target.value })}
								fullWidth
							/>
							<h3 className="form-label">
								Notification Body <span className="asterik">*</span>
							</h3>
							<h4 className="form-text">
								This message would be displayed in the notification.
							</h4>
							<TextField
								className="m-top-12 m-bottom-16"
								label="Body"
								variant="outlined"
								value={body}
								name="body"
								multiline
								rows={2}
								rowsMax={4}
								onChange={(e) => this.setState({ body: e.target.value })}
								fullWidth
							/>
							<h3 className="form-label">Notification URL</h3>
							<h4 className="form-text">
								This url would open in the Browser when the user clicks the
								notification.
							</h4>
							<TextField
								className="m-top-12 m-bottom-16"
								label="URL"
								variant="outlined"
								value={url}
								name="url"
								onChange={(e) => this.setState({ url: e.target.value })}
								fullWidth
							/>
							<h3 className="form-label">Notification Action</h3>
							<h4 className="form-text">
								Select an action when the user clicks the notification.
							</h4>
							<Autocomplete
								className="m-top-12 m-bottom-16"
								options={actionOptions}
								getOptionLabel={(option) =>
									option.initiatedName || option.placeholder || ""
								}
								filterSelectedOptions
								onInputChange={this.onInputChange}
								getOptionSelected={(option, value) =>
									option.page === value.page
								}
								onChange={(e, newValue) => {
									this.setState({ notificationAction: newValue });
								}}
								value={notificationAction}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label="Notification Action"
										placeholder="To open my duties"
									/>
								)}
							/>
							<Button
								className="m-top-16"
								startIcon={<Send />}
								color="primary"
								variant="contained"
								onClick={() => {
									this.sendNotification();
								}}
							>
								Send Notification
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.notificationReducer.loading,
	alert: state.notificationReducer.alert,
});

const mapDispatchToProps = {
	sendBulkNotificationAction,
	showAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
