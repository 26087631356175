import React, { Component } from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import './style.scss'
import Logo from '../../assets/images/logo.jpeg'
import Registration from '../../assets/images/online-registration.png'
import { validateToken } from '../../services/auth'
import { ROUTE } from '../../constants/route'
import { getToken } from '../../utils'
import { loginAction } from '../../actions/auth'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class LoginContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: '',
			password: '',
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (getToken()) {
			validateToken(getToken())
				.then((response) => {
					const userProfile = response?.data[0]
					userProfile.accessToken = getToken()
					userProfile.loggedIn = true
					localStorage.setItem(
						'UserProfile',
						JSON.stringify(userProfile)
					)
					props.history.replace(ROUTE.DASHBOARD.path)
				})
				.catch((err) => {
					console.log(err)
				})
		}
		return null
	}

	onChange = (name, value) => {
		this.setState({
			[name]: value,
		})
	}

	handleLogin = () => {
		const { email, password } = this.state
		const { history, loginAction } = this.props
		if (email && password) {
			loginAction({ email, password }).then((res) => {
				if (res && res.data) {
					const userProfile = res.data[0]
					userProfile.loggedIn = true
					const token = res.data[0]?.token
					localStorage.setItem(process.env.AUTH_TOKEN, token)
					localStorage.setItem(
						'UserProfile',
						JSON.stringify(userProfile)
					)
					history.replace(ROUTE.DASHBOARD.path)
				}
			})
		} else {
			alert('Please fill details')
		}
	}

	render() {
		const { email, password } = this.state
		return (
			<div className="login-container">
				<div className="greetings">
					<img src={Registration} />
				</div>
				<div className="login-box">
					<div className="img-wrapper">
						<img width="200px" src={Logo} />
					</div>
					<Typography variant="h5">
						Hare Krsna! Let's get started
					</Typography>
					<Typography variant="subtitle2">
						Sign in to continue (Only for admins){' '}
					</Typography>
					<TextField
						className="input-elem"
						required
						label="Email"
						variant="outlined"
						autoComplete="off"
						name="email"
						value={email}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						fullWidth
					/>
					<TextField
						className="input-elem"
						required
						label="Password"
						variant="outlined"
						type="password"
						autoComplete="off"
						name="password"
						value={password}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						fullWidth
					/>
					<Button
						variant="outlined"
						color="primary"
						onClick={this.handleLogin}
					>
						{' '}
						Login{' '}
					</Button><br/>
					<Typography variant="h4">
						<Link to={`${ROUTE.EVENT_PAGE.path}/jagannath-puri-mayapur-camp`}>
							Click <u>here</u> for <b>Annual Mayapur & Jagannath Puri Yatra Registration</b>
						</Link>
					</Typography>{' '}
					<marquee>Annual Mayapur & Jagannath Puri Yatra 2022 registrations have been started. Book your seat now!</marquee>
				</div>
			</div>
		)
	}
}

const mapStateToProps = null

const mapDispatchToProps = {
	loginAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
