export const ROUTE = {
	HOME: { path: '/', roles: [4, 5, 6, 7] },
	LOGIN: { path: '/login', roles: [4, 5, 6, 7] },
	SCHEDULE_NOTIFICATION: {
		path: '/schedule-notification',
		roles: [6, 7],
	},
	DASHBOARD: { path: '/dashboard', roles: [4, 5, 6, 7] },
	ANSWER_QUESTION: { path: '/answer-question', roles: [4, 5, 6, 7] },
	MANAGE_AREA: { path: '/area', roles: [4, 5, 6, 7] },
	ALL_USER: { path: '/user/all', roles: [4, 5, 6, 7] },
	PENDING_USER: { path: '/user/pending', roles: [4, 5, 6, 7] },
	APPROVED_USER: { path: '/user/approved', roles: [4, 5, 6, 7] },
	NON_COASTOK_USER: { path: '/user/non-sp3c', roles: [4, 5, 6, 7] },
	INACTIVE_USER: { path: '/user/inactive', roles: [6, 7] },
	USER: { path: '/user/:pageType', roles: [4, 5, 6, 7] },
	PROFILE: { path: '/profile', roles: [4, 5, 6, 7] },
	PRIVACY: { path: '/privacy', roles: [4, 5, 6, 7] },
	PLEDGING: { path: '/pledging', roles: [4, 5, 6, 7] },
	VIEW_PLEDGE_SUMMARY: { path: '/pledging/:pledgeId', roles: [5, 6, 7] },
	VIEW_PLEDGE_AREAWISE: {
		path: '/pledging/:pledgeId/:areaId',
		roles: [4, 5, 6, 7],
	},
	NOTIFICATION: { path: '/notification', roles: [4, 6, 7] },
	MANAGE_APP_CONTENT: { path: '/publish/:type', roles: [6, 7] },
	MANAGE_VIDEOS: { path: '/publish/videos', roles: [6, 7] },
	MANAGE_IMAGES: { path: '/publish/images', roles: [6, 7] },
	REGISTER: { path: '/register' },
	RESET_PASSWORD: { path: '/resetPassword', roles: [4, 5, 6, 7] },
	UPDATE_FRONTLINERS: { path: '/assignFrontliners', roles: [4, 5, 6, 7] },
	REPORTS: { path: '/reports', roles: [6, 7] },
	VIPLAVA: { path: '/viplava' }, // 1
	EVENT: {path: '/event/:eventId'},
	EVENT_PAGE: {path: '/event'},
	EVENT_REGISTER: {path: '/event/register/:eventId'},
	EVENT_REGISTER_FOR_APP: {path: '/event/:eventId/register'},
	EVENT_REGISTER_PAGE: {path: '/event/register'},
	EVENT_BRIDGE: {path: '/event/bridge/:eventId'},
	EVENT_BRIDGE_PAGE: {path: '/event/bridge'},
	VIPLAVA_REPORT: { path: '/viplava-report', roles: [4, 5, 6, 7] },
	VIPLAVA_REGISTER: { path: '/viplava/register' }, // 3
	VIPLAVA_BRIDGE: {path: '/viplava/bridge'}, // 2
	EVENTS: { path: '/event/manage/:eventId', roles: [4, 5, 6, 7] },
	EVENT_MANAGE_PAGE: { path: '/event/manage', roles: [4, 5, 6, 7] },
	REFUND_POLICY: { path: '/refund' },
	TNC: { path: '/terms' },
	ABOUT: { path: '/about-us' },
	CONTACT: { path: '/contact-us' },
}

export const WITHOUT_HEADER_PAGES = [
	ROUTE.VIPLAVA_REGISTER,
	ROUTE.VIPLAVA,
	ROUTE.VIPLAVA_BRIDGE,
	ROUTE.EVENT,
	ROUTE.EVENT_REGISTER,
	ROUTE.EVENT_BRIDGE,
	ROUTE.EVENT_REGISTER_FOR_APP,
]