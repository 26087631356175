import { Button, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTE } from "../../../constants/route";
import { COLOR } from "../../../constants/theme";

const SuccessScreen = ({name, bookingReferenceNo, bookingDateTime, eventName}) => {
  return (
    <div className="success-screen">
      <div className="mb-16">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width={"50"}
          height={"50"}
          viewBox="0 0 375.147 375.147"
          fill={COLOR.PRIMARY}
        >
          <polygon points="344.96,44.48 119.147,270.293 30.187,181.333 0,211.52 119.147,330.667 375.147,74.667 		" />
        </svg>
      </div>
      <Typography variant="h2" className="mb-16">
        {`Thank you ${name ? name + " " : ""}for registering to ${eventName}.`}
      </Typography>
      <Typography variant="h4" className="mb-16">
        <p>Booking Reference No. <b>{bookingReferenceNo}</b></p>
        <p>Date of registration: <b>{bookingDateTime}</b></p>
      </Typography>
      <Typography variant="body1" className="mb-16">
        The details would be forwarded to the concerned area leader. You can
        also install our SP3C application also for seamless experience of our
        services. Thank you so much for being here.
      </Typography>
      <div className="action-btns">
        <Link to={ROUTE.HOME.path}>
          <Button variant="contained" color="primary" className="mb-16">
            Return To Home Page
          </Button>
        </Link>
        <Typography variant="body1">
          Read our{" "}
          <Link to={ROUTE.PRIVACY.path} className="font-bold">
            Privacy policy
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default SuccessScreen