/* eslint-disable jsx-a11y/anchor-is-valid */
import './css/stylo.min.css'
import './css/32-layout.css'
import './css/frontend.css'
import './css/print.css'
import './css/style.css'
import './css/rs6.css'
import './css/styles.min.css'
import './css/template.min.css'
import './css/wpcdt-public.css'
import './css/zn_dynamic.css'
import './css/znb_frontend.css'
import { useGoogleLogin } from 'react-google-login'
import { ROUTE } from '../../constants/route'
import { useEffect, useRef, useState } from 'react'
import { getQueryObj, serialize } from '../../utils'
import { GOOGLE_CLIENT_ID } from '../../constants/config'
import { loginByToken } from '../../services/auth'
import { getUserByUid } from '../../services/viplava'
import { showAlert } from '../../actions'
import { ALERT } from '../../constants'
import { useDispatch } from 'react-redux'

const Viplava = ({ history, location }) => {
	const counterRef = useRef(null)
	const [counter, setCounter] = useState({
		days: 0,
		hours: 0,
		min: 0,
		sec: 0,
	})
	const dispatch = useDispatch();
	const onLoginSuccess = (res) => {
		localStorage.setItem(
			'USER_DETAILS',
			JSON.stringify({
				email: res.profileObj.email,
				name: res.profileObj.name,
				imageUrl: res.profileObj.imageUrl,
			})
		)
		dispatch(showAlert({
			message:
				"Please wait. Redirecting you to appropriate page.",
			severity: ALERT.INFO,
			duration: 2000,
		}));
		getUserByUid({email: res.profileObj.email}).then(({ result }) => {
			if(result.roles.some(role => (role >= 3))) {
				localStorage.setItem("isViplavaFacilitator", "yes")
				history.push(`${ROUTE.VIPLAVA_BRIDGE.path}`)
			} else {
				localStorage.setItem("isViplavaFacilitator", "no")
				history.push(`${ROUTE.VIPLAVA_REGISTER.path}?email=${res.profileObj.email}`)
				// setTimeout(function(){
				// 	document.location.href = `${ROUTE.VIPLAVA_REGISTER.path}?email=${res.profileObj.email}`;
				// },250);
				window.location.href = `${ROUTE.VIPLAVA_REGISTER.path}?email=${res.profileObj.email}`
			}
		}).catch(() => {
			localStorage.setItem("isViplavaFacilitator", "no")
			// history.push(`${ROUTE.VIPLAVA_REGISTER.path}?email=${res.profileObj.email}`)
			window.location.href = `${ROUTE.VIPLAVA_REGISTER.path}?email=${res.profileObj.email}`
		})
	}

	const { signIn, loaded } = useGoogleLogin({
		clientId: GOOGLE_CLIENT_ID,
		onSuccess: onLoginSuccess,
		onFailure: (e) => {
			alert("It seems cookies are not enabled in your browser. Please enable third party cookies and allow popups in browser settings. (One possible reason could be that you're trying to access website in incognito or please check your browser settings)")
		},
	})

	const handleRegisterBtnClick = (...args) => {
		const data = getQueryObj(location?.search);
		const userProfile = localStorage.getItem("UserProfile");
		if (userProfile && JSON.parse(userProfile).roles.some(role => (role >= 3))) {
			localStorage.setItem("isViplavaFacilitator", "yes")
			history.push(`${ROUTE.VIPLAVA_BRIDGE.path}${serialize({token: data.token})}`)
		} else if (data.token) {
			// window.location.href = `${ROUTE.VIPLAVA_REGISTER.path}?token=${data.token}`
			localStorage.setItem("isViplavaFacilitator", "no")
			history.push(`${ROUTE.VIPLAVA_REGISTER.path}?token=${data.token}`)
		} else {
			signIn(...args)
		}
	}

	useEffect(() => {
		const date = new Date('10/14/2022')
		counterRef.current = setInterval(() => {
			const now = new Date()
			const diff = date - now
			if (typeof setCounter === 'function')
				setCounter((c) => ({
					days: Math.floor(diff / 1000 / 60 / 60 / 24),
					hours: Math.floor((diff / 1000 / 60 / 60) % 24),
					min: Math.floor((diff / 1000 / 60) % 60),
					sec: Math.floor((diff / 1000) % 60),
				}))
		}, 1000);
		const data = getQueryObj(location?.search);
    if(data.token) {
      loginByToken(data.token).then((res) => {
        const userProfile = res.data[0]
        userProfile.loggedIn = true
        localStorage.setItem(
          'UserProfile',
          JSON.stringify(userProfile)
        )
      })
    }
		return () => {
			clearInterval(counterRef.current)
		}
	}, [])

	return (
		<div className="home page-template-default page page-id-32 res1170 kl-follow-menu kl-skin--light viplava-container">
			<div className="login_register_stuff" />
			{/* end login register stuff */} <div id="fb-root" />
			<div
				id="zn-nav-overlay"
				className="znNavOvr znNavOvr--layoutS1 znNavOvr--animation1 znNavOvr--theme-light"
			>
				<div className="znNavOvr-inner is-empty">
					<div className="znNavOvr-menuWrapper" />
				</div>
				<a
					href="#"
					className="znNavOvr-close znNavOvr-close--trSmall"
					id="znNavOvr-close"
				>
					<span />
					<svg
						x="0px"
						y="0px"
						width="54px"
						height="54px"
						viewBox="0 0 54 54"
					>
						<circle
							fill="transparent"
							stroke="#656e79"
							strokeWidth={1}
							cx={27}
							cy={27}
							r={25}
							strokeDasharray="157 157"
							strokeDashoffset={157}
						/>
					</svg>
				</a>
			</div>
			<div id="page_wrapper">
				<header
					id="header"
					className="site-header  style5  header--follow    sticky-resize headerstyle--image_color site-header--absolute nav-th--light siteheader-classic siteheader-classic-normal sheader-sh--light"
				>
					<div className="site-header-wrapper sticky-top-area">
						<div className="kl-top-header site-header-main-wrapper clearfix  header-no-top  sh--light">
							<div className="container siteheader-container header--oldstyles">
								<div className="fxb-row fxb-row-col-sm">
									<div className="fxb-col fxb-basis-auto">
										<div className="fxb-row site-header-row site-header-main ">
											<div className="fxb-col fxb fxb-start-x fxb-center-y fxb-basis-auto site-header-col-left site-header-main-left"></div>
											<div className="fxb-col fxb fxb-center-x fxb-center-y fxb-basis-auto site-header-col-center site-header-main-center"></div>
											<div className="fxb-col fxb fxb-end-x fxb-center-y fxb-basis-auto site-header-col-right site-header-main-right">
												<div className="fxb-col fxb fxb-end-x fxb-center-y fxb-basis-auto site-header-main-right-top">
													<div
														className="sh-component main-menu-wrapper"
														role="navigation"
														itemScope="itemscope"
														itemType="https://schema.org/SiteNavigationElement"
													>
														<div className="zn-res-menuwrapper">
															<a
																href="#"
																className="zn-res-trigger zn-menuBurger zn-menuBurger--3--s zn-menuBurger--anim1 "
																id="zn-res-trigger"
															>
																<span />
																<span />
																<span />
															</a>
														</div>
														{/* end responsive menu */}
														<div
															id="main-menu"
															className="main-nav mainnav--overlay mainnav--active-text mainnav--pointer-dash nav-mm--light zn_mega_wrapper "
														>
															<ul
																id="menu-event-conference-menu"
																className="main-menu main-menu-nav zn_mega_menu "
															>
																<li
																	id="menu-item-24"
																	className="main-menu-item menu-item menu-item-type-custom menu-item-object-custom menu-item-24  main-menu-item-top  menu-item-even menu-item-depth-0"
																>
																	<a
																		href="#aboutviplava"
																		className=" main-menu-link main-menu-link-top"
																	>
																		<span>
																			ABOUT
																			EVENT
																		</span>
																	</a>
																</li>
																<li
																	id="menu-item-25"
																	className="main-menu-item menu-item menu-item-type-custom menu-item-object-custom menu-item-25  main-menu-item-top  menu-item-even menu-item-depth-0"
																>
																	<a
																		href="#eventsviplava"
																		className=" main-menu-link main-menu-link-top"
																	>
																		<span>
																			EVENTS
																		</span>
																	</a>
																</li>
																<li
																	id="menu-item-27"
																	className="main-menu-item menu-item menu-item-type-custom menu-item-object-custom menu-item-27  main-menu-item-top  menu-item-even menu-item-depth-0"
																>
																	<a
																		href="#RegisterViplava"
																		className=" main-menu-link main-menu-link-top"
																	>
																		<span>
																			DETAILS
																		</span>
																	</a>
																</li>
																<li
																	id="menu-item-95"
																	className="main-menu-item menu-item menu-item-type-custom menu-item-object-custom menu-item-95  main-menu-item-top  menu-item-even menu-item-depth-0"
																>
																	<a
																		href="#"
																		className=" main-menu-link main-menu-link-top"
																		onClick={
																			handleRegisterBtnClick
																		}
																		disabled={
																			!loaded
																		}
																	>
																		<span>
																			REGISTER
																			NOW
																		</span>
																	</a>
																</li>
															</ul>
														</div>{' '}
													</div>
													{/* end main_menu */}
												</div>
											</div>
										</div>
										{/* /.site-header-main */}
									</div>
								</div>
								<div className="separator site-header-separator " />{' '}
							</div>
							{/* /.siteheader-container */}
						</div>
						{/* /.site-header-main-wrapper */}
					</div>
					{/* /.site-header-wrapper */}
				</header>
				<div
					className="zn_pb_wrapper clearfix zn_sortable_content"
					data-droplevel={0}
				>
					{' '}
					<section
						className="zn_section eluidf73c7c54   zn_parallax  section-sidemargins    zn_section--relative section--no "
						id="eluidf73c7c54"
					>
						<div className="zn-bgSource zn-bgSource-imageParallax js-znParallax">
							<div
								className="zn-bgSource-image"
								style={{
									backgroundImage:
										'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/Slide-01.jpg)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
								}}
							/>
							<div
								className="zn-bgSource-overlay"
								style={{ backgroundColor: 'rgba(5,0,62,0.7)' }}
							/>
						</div>
						<div className="zn_section_size container zn-section-height--custom_height zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid3151d108            col-md-12 col-sm-12   znColumnElement"
									id="eluid3151d108"
								>
									<div className="znColumnElement-innerWrapper-eluid3151d108 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h1
												className="eluid142ad9e4  dn-heading"
												id="eluid142ad9e4"
												itemProp="headline"
											>
												VIPLAVA 2022
											</h1>
											<p
												className="eluid08c6f97a  dn-heading"
												id="eluid08c6f97a"
												itemProp="headline"
											>
												The Revolution In Consciousness
											</p>
											<h1
												className="eluid51c10575  dn-heading"
												id="eluid51c10575"
												itemProp="headline"
											>
												Glories of vedic{' '}
												<font color="ef761f">in</font>d
												<font color="88bc71">ia</font>
											</h1>
											<div
												id="eluid483ea049"
												className="zn_buttons_element eluid483ea049 text-center "
											>
												<a
													href="#"
													id="eluid483ea0490"
													className="eluid483ea0490 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
													onClick={handleRegisterBtnClick}
													disabled={!loaded}
												>
													<span>REGISTER NOW</span>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluide5ee4e5d     section-sidemargins    section--no "
						id="eluide5ee4e5d"
					>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid93c3ea30            col-md-12 col-sm-12   znColumnElement"
									id="eluid93c3ea30"
								>
									<div className="znColumnElement-innerWrapper-eluid93c3ea30 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluid6c367734  dn-heading"
												id="eluid6c367734"
												itemProp="headline"
											>
												EVENT STARTS IN:
											</h3>
											<div
												id="eluid8e6e3cba"
												className="ud_counter kl-counter kl-font-alt eluid8e6e3cba text-center "
											>
												{' '}
												<ul className="th-counter kl-counterlist">
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.days}
														</span>
														<span className="kl-counter-unit ">
															DAYS
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.hours}
														</span>
														<span className="kl-counter-unit ">
															HOURS
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.min}
														</span>
														<span className="kl-counter-unit ">
															MIN
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.sec}
														</span>
														<span className="kl-counter-unit ">
															SEC
														</span>
													</li>{' '}
												</ul>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluide9a224b7     section-sidemargins    section--no "
						id="aboutviplava"
					>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid18fdb7ce            col-md-12 col-sm-12   znColumnElement"
                  style={{display: 'flex', justifyContent: "center"}}
									id="eluid18fdb7ce"
								>
                  <img src='https://res.cloudinary.com/dnuq1lgqs/image/upload/v1659420735/WhatsApp_Image_2022-07-28_at_1.05.12_PM_tnuyth.jpg' alt='Viplava poster' />
                </div>
							</div>
							<div className="row gutter-0">
								<div
									className="eluid18fdb7ce            col-md-6 col-sm-6   znColumnElement"
									id="eluid18fdb7ce"
								>
									<div className="znColumnElement-innerWrapper-eluid18fdb7ce znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluidd418f44a  dn-heading"
												id="eluidd418f44a"
												itemProp="headline"
											>
												ABOUT
											</h3>
											<h3
												className="eluid83c749b6  dn-heading"
												id="eluid83c749b6"
												itemProp="headline"
											>
												ABOUT EVENT
											</h3>
											<h3
												className="eluidf78ba658  dn-heading"
												id="eluidf78ba658"
												itemProp="headline"
											>
												The word ‘Viplava’ literally
												means revolution. So, the ISKCON
												is celebrating ‘VIPLAVA’ so as
												to bring Revolution or
												transformation in the
												Consciousness of our youth,
												therby leading to their overall
												well-being. It will take place
												from October 14th to 16th
												October, 2022 at Srī Govardhan
												Dhāma, for 5000+ youth from
												across the nation to inspire and
												empower them.
											</h3>
											<div
												id="eluidabc0d9b0"
												className="zn_buttons_element eluidabc0d9b0 text-left "
											>
												<a
													href="#"
													id="eluidabc0d9b00"
													className="eluidabc0d9b00 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
													onClick={handleRegisterBtnClick}
													disabled={!loaded}
												>
													<span>Register Now</span>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
								<div
									className="eluid45521b11            col-md-6 col-sm-6   znColumnElement"
									id="eluid45521b11"
								>
									<div className="znColumnElement-innerWrapper-eluid45521b11 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="media-container eluidd816dbd6  media-container--type-btn kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/Viplava-Highlights-2021.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(5,0,62,0.7)',
														}}
													/>
												</div>{' '}
												<a
													className="media-container__link media-container__link--btn media-container__link--style-circle "
													href="https://www.youtube.com/watch?v=exbAPtnFqHU"
													data-lightbox="iframe"
												>
													<div className="circleanim-svg">
														<svg
															height={108}
															width={108}
															xmlns="http://www.w3.org/2000/svg"
														>
															<circle
																strokeOpacity="0.1"
																fill="#FFFFFF"
																strokeWidth={5}
																cx={54}
																cy={54}
																r={48}
																className="circleanim-svg__circle-back"
															/>
															<circle
																strokeWidth={5}
																fill="#FFFFFF"
																cx={54}
																cy={54}
																r={48}
																className="circleanim-svg__circle-front"
																transform="rotate(50 54 54) "
															/>
															<path
																d="M62.1556183,56.1947505 L52,62.859375 C50.6192881,63.7654672 49.5,63.1544098 49.5,61.491212 L49.5,46.508788 C49.5,44.8470803 50.6250889,44.2383396 52,45.140625 L62.1556183,51.8052495 C64.0026693,53.0173767 63.9947588,54.9878145 62.1556183,56.1947505 Z"
																fill="#FFFFFF"
															/>
														</svg>
													</div>{' '}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluide9b9c256     section-sidemargins    section--no "
						id="eventsviplava"
					>
						<div className="zn_section_size full_width zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluidb10bd2a5            col-md-3 col-sm-3   znColumnElement"
									id="eluidb10bd2a5"
								>
									<div className="znColumnElement-innerWrapper-eluidb10bd2a5 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-center znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluidae1898ac  dn-heading"
												id="eluidae1898ac"
												itemProp="headline"
											>
												EVENTS
											</h3>
											<h3
												className="eluide74a2c16  dn-heading"
												id="eluide74a2c16"
												itemProp="headline"
											>
												EVENTS
											</h3>
											<h3
												className="eluid072fa61e  dn-heading"
												id="eluid072fa61e"
												itemProp="headline"
											>
												Talk Show, Panel Discussion,
												Shadow Act, Debate, Drama, Rock
												Show, Quiz, Group Dance &amp;
												many more
											</h3>
											<div className="media-container eluid1a07545b  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/Viplava-2021-4374.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'left center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(247,152,56,0.5)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluidbb62d562            col-md-12 col-sm-12   znColumnElement"
															id="eluidbb62d562"
														>
															<div className="znColumnElement-innerWrapper-eluidbb62d562 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluid5aefb679  dn-heading"
																		id="eluid5aefb679"
																		itemProp="headline"
																	>
																		Theatrical
																		Show
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="eluid0298d50d            col-md-3 col-sm-3   znColumnElement"
									id="eluid0298d50d"
								>
									<div className="znColumnElement-innerWrapper-eluid0298d50d znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="media-container eluid395cb513  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/HHGKGM.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(247,152,56,0.5)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluid7df5957e            col-md-12 col-sm-12   znColumnElement"
															id="eluid7df5957e"
														>
															<div className="znColumnElement-innerWrapper-eluid7df5957e znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluid0f822b88  dn-heading"
																		id="eluid0f822b88"
																		itemProp="headline"
																	>
																		Talk
																		Show
																	</h3>
																	<h3
																		className="eluidd36876a5  dn-heading"
																		id="eluidd36876a5"
																		itemProp="headline"
																	>
																		by
																		Eminent
																		Personalities
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="media-container eluiddab10e3d  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/Viplava-2021-1923-1.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(179,229,52,0.45)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluid26947b3d            col-md-12 col-sm-12   znColumnElement"
															id="eluid26947b3d"
														>
															<div className="znColumnElement-innerWrapper-eluid26947b3d znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluid1f006f84  dn-heading"
																		id="eluid1f006f84"
																		itemProp="headline"
																	>
																		Mantra
																		Rock
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="media-container eluid6040f1cc  media-container--type-pb kl-overlay-none  ">
									<div className="zn-bgSource ">
										<div
											classname="zn-bgSource-image"
											style={{
												backgroundImage:
													'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/govardhan-parikrama.jpg)',
												backgroundRepeat: 'no-repeat',
												backgroundPosition:
													'center center',
												backgroundSize: 'cover',
												backgroundAttachment: 'scroll',
											}}
										/>
										<div
											classname="zn-bgSource-overlay"
											style={{
												backgroundColor:
													'rgba(247,152,56,0.5)',
											}}
										/>
									</div>
									<div className="media-container-pb media-container-pb--alg-bottom">
										<div className="row zn_col_container-media_container ">
											<div
												className="eluid5be967ce            col-md-12 col-sm-12   znColumnElement"
												id="eluid5be967ce"
											>
												<div className="znColumnElement-innerWrapper-eluid5be967ce znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
													<div className="znColumnElement-innerContent">
														{' '}
														<h3
															className="eluid2aeeb20a  dn-heading"
															id="eluid2aeeb20a"
															itemprop="headline"
														>
															Goverdhan Parikrama
														</h3>{' '}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div> */}
								<div
									className="eluidf30da713            col-md-3 col-sm-3   znColumnElement"
									id="eluidf30da713"
								>
									<div className="znColumnElement-innerWrapper-eluidf30da713 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="media-container eluidef0e0627  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/Shadow-act.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(179,229,52,0.45)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluid0884976f            col-md-12 col-sm-12   znColumnElement"
															id="eluid0884976f"
														>
															<div className="znColumnElement-innerWrapper-eluid0884976f znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluidfcc1562c  dn-heading"
																		id="eluidfcc1562c"
																		itemProp="headline"
																	>
																		Shadow
																		Act
																	</h3>
																	<h3
																		className="eluid9c07119e  dn-heading"
																		id="eluid9c07119e"
																		itemProp="headline"
																	>
																		by IIT
																		Students
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="eluide4e3a836            col-md-3 col-sm-3   znColumnElement"
									id="eluide4e3a836"
								>
									<div className="znColumnElement-innerWrapper-eluide4e3a836 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="media-container eluid724aac0e  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/Debate.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(247,152,56,0.5)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluidd7c57827            col-md-12 col-sm-12   znColumnElement"
															id="eluidd7c57827"
														>
															<div className="znColumnElement-innerWrapper-eluidd7c57827 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluidf7bbfac1  dn-heading"
																		id="eluidf7bbfac1"
																		itemProp="headline"
																	>
																		Debate
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="media-container eluidc8ad12bc  media-container--type-pb kl-overlay-none  ">
												<div className="zn-bgSource ">
													<div
														className="zn-bgSource-image"
														style={{
															backgroundImage:
																'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/Viplava-2021-358.jpg)',
															backgroundRepeat:
																'no-repeat',
															backgroundPosition:
																'center center',
															backgroundSize:
																'cover',
															backgroundAttachment:
																'scroll',
														}}
													/>
													<div
														className="zn-bgSource-overlay"
														style={{
															backgroundColor:
																'rgba(179,229,52,0.45)',
														}}
													/>
												</div>
												<div className="media-container-pb media-container-pb--alg-bottom">
													<div className="row zn_col_container-media_container ">
														<div
															className="eluid3fc6667d            col-md-12 col-sm-12   znColumnElement"
															id="eluid3fc6667d"
														>
															<div className="znColumnElement-innerWrapper-eluid3fc6667d znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																<div className="znColumnElement-innerContent">
																	{' '}
																	<h3
																		className="eluid5d493883  dn-heading"
																		id="eluid5d493883"
																		itemProp="headline"
																	>
																		And{' '}
																		<br />
																		Much
																		More
																	</h3>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluid07b91c2d     section-sidemargins    section--no "
						id="RegisterViplava"
					>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid749337d2            col-md-6 col-sm-6   znColumnElement"
									id="eluid749337d2"
								>
									<div className="znColumnElement-innerWrapper-eluid749337d2 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluidf986196d  dn-heading"
												id="eluidf986196d"
												itemProp="headline"
											>
												DETAILS
											</h3>
											<h3
												className="eluid2805e62a  dn-heading"
												id="eluid2805e62a"
												itemProp="headline"
											>
												DETAILS
											</h3>
											<h3
												className="eluid53e51192  dn-heading"
												id="eluid53e51192"
												itemProp="headline"
											>
												<strong>Theme:</strong> Glories
												of Vedic India
												<br />
												<strong>Date:</strong> 14th Oct
												- 16th Oct 2022
												<br />
												<strong>Events:</strong> Talk
												Show, Panel Discussion, Shadow
												Act, Debate, Drama, Rock Show,
												Quiz, Group Dance &amp; many
												more
												<br />
												<strong>
													Beneficiaries:
												</strong>{' '}
												5000+ Youths from 9 States of
												Bharat
												<br />
												<strong>Place:</strong> Sri
												Govardhan Dham, Mathura (U.P.)
												<br />
											</h3>
											<h3
												className="eluid3a07571f  dn-heading"
												id="eluid3a07571f"
												itemProp="headline"
											>
												Event starts in :
											</h3>
											<div
												id="eluid6b9d6afb"
												className="ud_counter kl-counter kl-font-alt eluid6b9d6afb text-left "
											>
												{' '}
												<ul className="th-counter kl-counter-list">
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.days}
														</span>
														<span className="kl-counter-unit ">
															DAYS
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.hours}
														</span>
														<span className="kl-counter-unit ">
															HOURS
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.min}
														</span>
														<span className="kl-counter-unit ">
															MIN
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.sec}
														</span>
														<span className="kl-counter-unit ">
															SEC
														</span>
													</li>{' '}
												</ul>
											</div>{' '}
										</div>
									</div>
								</div>
								<div
									className="eluid9d42d219 price-column           col-md-2 col-sm-2   znColumnElement"
									id="eluid9d42d219"
								>
									<div className="znColumnElement-innerWrapper-eluid9d42d219 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
										</div>
									</div>
								</div>
								<div
									className="eluid100ef4ce price-column           col-md-4 col-sm-4   znColumnElement"
									id="eluid100ef4ce"
								>
									<div className="znColumnElement-innerWrapper-eluid100ef4ce znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="image-boxes imgbox-simple eluid6d6f37e9   image-boxes-cover-fit-img">
												<div className="image-boxes-holder imgboxes-wrapper u-mb-0  ">
													<div className="image-boxes-img-wrapper img-align-center">
														<img
															className="image-boxes-img img-responsive cover-fit-img"
															src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/Register-Icon.png"
															alt=""
															title="Register-Icon"
														/>
													</div>
												</div>
											</div>
											<h3
												className="eluidcff2560b  dn-heading"
												id="eluidcff2560b"
												itemProp="headline"
											>
												Register Now
											</h3>
											<h3
												className="eluidf6dfed6f  dn-heading"
												id="eluidf6dfed6f"
												itemProp="headline"
											>
												FOR ONE PERSON
											</h3>
											<h3
												className="eluid10560141  dn-heading"
												id="eluid10560141"
												itemProp="headline"
											>
												₹1400/-
											</h3>
											<div className="znList eluidd919cac8  text-center znList-icon--center elm-znlist--light element-scheme--light">
												<ul className="znListItems clearfix">
													<li className="znListItems-item clearfix">
														<span className="znListItems-icon znListItems-icon-0" />
														<span className="znListItems-text">
															EVENT ACCESS
														</span>
													</li>
													<li className="znListItems-item clearfix">
														<span className="znListItems-icon znListItems-icon-1" />
														<span className="znListItems-text">
															ACCOMODATION
														</span>
													</li>
													<li className="znListItems-item clearfix">
														<span className="znListItems-icon znListItems-icon-1" />
														<span className="znListItems-text">
															PRASHADAM
														</span>
													</li>
													{/* <li className="znListItems-item clearfix">
														<span className="znListItems-icon znListItems-icon-2" />
														<span className="znListItems-text">
															TRANSPORTATION
														</span>
													</li> */}
												</ul>
											</div>
											<div className="zn-buttonWrapper zn-buttonWrapper-eluidacd16e1f text-center">
												<a
													href="#"
													id="eluidacd16e1f"
													className="eluidacd16e1f  zn-button btn btn-fullcolor btn-custom-color btn--square btn-icon--before"
													title="Click me"
													target="_self"
													itemProp="url"
													onClick={handleRegisterBtnClick}
													disabled={!loaded}
												>
													<span className="zn-buttonText">
														REGISTER NOW
													</span>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className="eluidca3d2708 ">
						{' '}
						<section
							className="zn_section eluid53eea35a   zn_parallax  section-sidemargins    zn_section--relative section--no "
							id="eluid53eea35a"
						>
							<div className="zn-bgSource zn-bgSource-imageParallax js-znParallax">
								<div
									className="zn-bgSource-image"
									style={{
										backgroundImage:
											'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/07/Goverdhan.jpg)',
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center center',
										backgroundSize: 'cover',
									}}
								/>
								<div
									className="zn-bgSource-overlay"
									style={{
										backgroundColor: 'rgba(5,0,62,0.7)',
									}}
								/>
							</div>
							<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
								<div className="row gutter-0">
									<div
										className="eluid26b54b20            col-md-12 col-sm-12   znColumnElement"
										id="eluid26b54b20"
									>
										<div className="znColumnElement-innerWrapper-eluid26b54b20 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
											<div className="znColumnElement-innerContent">
												{' '}
												<h3
													className="eluidabb01b75  dn-heading"
													id="eluidabb01b75"
													itemProp="headline"
												>
													14TH - 16TH OCTOBER, 2022
												</h3>
												<h3
													className="eluidd48312f8  dn-heading"
													id="eluidd48312f8"
													itemProp="headline"
												>
													5000 Youths
												</h3>
												<h3
													className="eluid17692dce  dn-heading"
													id="eluid17692dce"
													itemProp="headline"
												>
													Sri Govardhan Dham, Mathura
													(U.P.)
												</h3>
												<div
													id="eluidacce348c"
													className="zn_buttons_element eluidacce348c text-center "
												>
													<a
														href="#"
														id="eluidacce348c0"
														className="eluidacce348c0 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
														target="_self"
														itemProp="url"
														onClick={handleRegisterBtnClick}
														disabled={!loaded}
													>
														<span>
															REGISTER NOW
														</span>
													</a>
													<a
														href="http://wa.me/919650041101"
														id="eluidacce348c1"
														className="eluidacce348c1 btn-element btn-element-1 btn  btn-lined btn-custom-color   zn_dummy_value btn-icon--before btn--square"
														target="_blank"
														rel="noopener"
														itemProp="url"
													>
														<span>KNOW MORE</span>
													</a>
												</div>{' '}
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>{' '}
				<footer
					id="footer"
					className="site-footer"
					role="contentinfo"
					itemScope="itemscope"
					itemType="https://schema.org/WPFooter"
				>
					<div className="container">
						<div className="row">
							<div className="col-sm-12">
								<div className="bottom site-footer-bottom clearfix">
									<div className="zn_footer_nav-wrapper">
										<ul
											id="menu-event-conference-menu-1"
											className="footer_nav"
										>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-24">
												<a href="#aboutviplava">
													ABOUT EVENT
												</a>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-25">
												<a href="#eventsviplava">
													EVENTS
												</a>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-27">
												<a href="#RegisterViplava">
													DETAILS
												</a>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-95">
												<a
													href="#"
													onClick={handleRegisterBtnClick}
													disabled={!loaded}
												>
													REGISTER NOW
												</a>
											</li>
										</ul>
									</div>
									<div className="copyright footer-copyright">
										<p className="footer-copyright-text">
											© 2022. All rights reserved by{' '}
											<a
												href="https://sp-3c"
												target="_blank"
												rel="noreferrer"
											>
												SP3C
											</a>
											.
										</p>{' '}
									</div>
									{/* end copyright */}
								</div>
								{/* end bottom */}
							</div>
						</div>
						{/* end row */}
					</div>
				</footer>
			</div>
			{/* end page_wrapper */}
			{/* <a
				href="#"
				id="totop"
				className="u-trans-all-2s js-scroll-event"
				data-forch={300}
				dataVisibleclass="on--totop"
			>
				TOP
			</a> */}
		</div>
	)
}

export default Viplava
