import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { assignMembersToFrontliner, getUsers, searchUser } from '../../services/user';

class FrontlinersComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			options: [],
			members: props.members || []
		};
	}

	componentDidUpdate(prevProps) {
		const {members} = this.props;
		if(prevProps.members.length !== this.props.members.length){
			this.setState({
				members: members
			})
		}
	}

	onInputChange = (event, value) => {
		//response from api
		if (event) {
			searchUser({
				q: value,
				userRole: '1',
				areaId: this.props.areaId,
			}).then((res) => {
				this.setState({
					options: res.data.data.filter(u => !u.frontliner)
				});
			});
		}
	};

	onChange = (name, value) => {
		this.setState({
			[name]: value
		});
	};

	assignFrontliner = () => {
		const {areaId, frontlinerId} = this.props;
		const {members} = this.state;
		assignMembersToFrontliner(areaId, frontlinerId, members.map(m => m._id)).then(() => {
			this.setState({
				disableBtn: false
			});
		});
	}

	render() {
		const { options, members } = this.state;
		return (
			<div style={{ width: '100%' }}>
				<Autocomplete
					multiple
					className="m-top-16 m-bottom-16"
					options={options}
					getOptionLabel={(option) => option.initiatedName || option.name || ''}
					filterSelectedOptions
					onInputChange={this.onInputChange}
					onChange={(e, newValue) => {
						this.onChange('members', newValue);
					}}
					style={{ width: '100%' }}
					value={members}
					disableClearable
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Members"
							placeholder="Start typing Name/Email under this Frontliner"
						/>
					)}
					fullWidth
				/>
				<Button
					onClick={() => {
						this.setState({
							disableBtn: true
						});
						this.assignFrontliner();
					}}
					color="primary"
					variant='contained'
					disabled={this.state.disableBtn}
				>
					{this.state.disableBtn ? 'Please wait' : 'Save Data'}
				</Button>
			</div>
		);
	}
}

export default FrontlinersComponent;
