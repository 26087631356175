import { Button, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTE } from '../../../constants/route'
import { COLOR } from '../../../constants/theme'

const SubsidyInProgress = ({ name, bookingReferenceNo, bookingDateTime }) => {
	return (
		<div className="success-screen">
			<div className="mb-16">
				<svg
					version="1.1"
					id="Capa_1"
					fill={COLOR.PRIMARY}
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					width="50"
					height="50"
					viewBox="0 0 45.311 45.311"
					style={{ enableBackground: 'new 0 0 45.311 45.311;' }}
				>
					<g>
						<path
							d="M22.675,0.02c-0.006,0-0.014,0.001-0.02,0.001c-0.007,0-0.013-0.001-0.02-0.001C10.135,0.02,0,10.154,0,22.656
		c0,12.5,10.135,22.635,22.635,22.635c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c12.5,0,22.635-10.135,22.635-22.635
		C45.311,10.154,35.176,0.02,22.675,0.02z M22.675,38.811c-0.006,0-0.014-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001
		c-2.046,0-3.705-1.658-3.705-3.705c0-2.045,1.659-3.703,3.705-3.703c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0
		c2.045,0,3.706,1.658,3.706,3.703C26.381,37.152,24.723,38.811,22.675,38.811z M27.988,10.578
		c-0.242,3.697-1.932,14.692-1.932,14.692c0,1.854-1.519,3.356-3.373,3.356c-0.01,0-0.02,0-0.029,0c-0.009,0-0.02,0-0.029,0
		c-1.853,0-3.372-1.504-3.372-3.356c0,0-1.689-10.995-1.931-14.692C17.202,8.727,18.62,5.29,22.626,5.29
		c0.01,0,0.02,0.001,0.029,0.001c0.009,0,0.019-0.001,0.029-0.001C26.689,5.29,28.109,8.727,27.988,10.578z"
						/>
					</g>
				</svg>
			</div>
			<Typography variant="h2" className="mb-16">
				{`Hare Krishna, ${
					name ? name + ' ' : ''
				}! Your partial payment request has been submitted to your Area Leader and Facilitator.`}
			</Typography>
			{/* <Typography variant="h4" className="mb-16">
        <p>Booking Reference No. <b>{bookingReferenceNo}</b></p>
        <p>Date of registration: <b>{bookingDateTime}</b></p>
      </Typography> */}
			<Typography variant="body1" className="mb-16">
				The details would be forwarded to the concerned area leader and
				facilitator. You would be soon informed by the status. Stay
				tuned.
			</Typography>
			<div className="action-btns">
				<Link to={ROUTE.HOME.path}>
					<Button
						variant="contained"
						color="primary"
						className="mb-16"
					>
						Return To Home Page
					</Button>
				</Link>
				<Typography variant="body1">
					Read our{' '}
					<Link to={ROUTE.PRIVACY.path} className="font-bold">
						Privacy policy
					</Link>
				</Typography>
			</div>
		</div>
	)
}

export default SubsidyInProgress
