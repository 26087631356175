import {
	GET_EVENT_DATA_FAILED,
	GET_EVENT_DATA_STARTED,
	GET_EVENT_DATA_SUCCESS,
	GET_SUBSIDY_LIST_FAILED,
	GET_SUBSIDY_LIST_STARTED,
	GET_SUBSIDY_LIST_SUCCESS,
	GET_CASH_LIST_FAILED,
	GET_CASH_LIST_STARTED,
	GET_CASH_LIST_SUCCESS,
	GET_FUND_BALANCE_FAILED,
	GET_FUND_BALANCE_STARTED,
	GET_FUND_BALANCE_SUCCESS,
	APPROVE_SUBSIDY_FAILED,
	APPROVE_SUBSIDY_STARTED,
	APPROVE_SUBSIDY_SUCCESS,
	APPROVE_CASH_FAILED,
	APPROVE_CASH_STARTED,
	APPROVE_CASH_SUCCESS,
	REJECT_SUBSIDY_FAILED,
	REJECT_SUBSIDY_STARTED,
	REJECT_SUBSIDY_SUCCESS,
} from '../constants/actions'
import {
	approveCashRequest,
	approveSubsidyRequest,
	getCashList,
	getFundBalance,
	getSubsidyList,
	rejectSubsidyRequest,
} from '../services/event'
import { getEventDetails } from '../services/viplava'
import { getAreas } from '../utils'
import { extractErrorStr } from '../utils/network'
import { actionCreator } from '../utils/redux'

export const getEventDetailsAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_EVENT_DATA_STARTED))
	return getEventDetails(params)
		.then((json) => {
			dispatch(
				actionCreator(
					GET_EVENT_DATA_SUCCESS,
					json?.result?.length ? json?.result : [json?.result]
				)
			)
			return json
		})
		.catch((error) => {
			dispatch(
				actionCreator(GET_EVENT_DATA_FAILED, extractErrorStr(error))
			)
			return error
		})
}

export const getSubsidyListAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_SUBSIDY_LIST_STARTED))
	return getSubsidyList(params)
		.then((json) => {
			dispatch(actionCreator(GET_SUBSIDY_LIST_SUCCESS, json.data))
			return json
		})
		.catch((error) => {
			dispatch(
				actionCreator(GET_SUBSIDY_LIST_FAILED, extractErrorStr(error))
			)
			return error
		})
}

export const getCashListAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_CASH_LIST_STARTED))
	return getCashList(params)
		.then((json) => {
			dispatch(actionCreator(GET_CASH_LIST_SUCCESS, json.data))
			return json
		})
		.catch((error) => {
			dispatch(
				actionCreator(GET_CASH_LIST_FAILED, extractErrorStr(error))
			)
			return error
		})
}

export const getFundBalanceAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_FUND_BALANCE_STARTED))
	const area = getAreas()[0]
	const areaId = params || area._id
	return getFundBalance(areaId)
		.then((json) => {
			dispatch(actionCreator(GET_FUND_BALANCE_SUCCESS, json.data))
			return json
		})
		.catch((error) => {
			dispatch(
				actionCreator(GET_FUND_BALANCE_FAILED, extractErrorStr(error))
			)
			return error
		})
}

export const approveSubsidyRequestAction =
	({ id, amountAfterSubsidy, comments, subsidy }) =>
	(dispatch) => {
		dispatch(actionCreator(APPROVE_SUBSIDY_STARTED))
		return approveSubsidyRequest({ id, amountAfterSubsidy, comments })
			.then((json) => {
				dispatch(
					actionCreator(APPROVE_SUBSIDY_SUCCESS, { id, subsidy })
				)
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(
						APPROVE_SUBSIDY_FAILED,
						extractErrorStr(error)
					)
				)
				return error
			})
	}

	export const approveCashRequestAction =
	({ id, cashRecieved }) =>
	(dispatch) => {
		dispatch(actionCreator(APPROVE_CASH_STARTED))
		return approveCashRequest({ id, cashRecieved })
			.then((json) => {
				dispatch(
					actionCreator(APPROVE_CASH_SUCCESS, { id})
				)
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(
						APPROVE_CASH_FAILED,
						extractErrorStr(error)
					)
				)
				return error
			})
	}

export const rejectSubsidyRequestAction =
	({ id, comments }) =>
	(dispatch) => {
		dispatch(actionCreator(REJECT_SUBSIDY_STARTED))
		return rejectSubsidyRequest({id, comments})
			.then((json) => {
				dispatch(
					actionCreator(REJECT_SUBSIDY_SUCCESS, { id })
				)
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(
						REJECT_SUBSIDY_FAILED,
						extractErrorStr(error)
					)
				)
				return error
			})
	}
