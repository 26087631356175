import JagannathPuriMayapurCamp from '../containers/EventRegister/particular-pages/PuriMayapurCamp2022'
import { isProd } from '../utils/network'

export const EVENTS_UI_CONFIG = [
	{
		id: 'jagannath-puri-mayapur-camp',
		title: 'Both Jagannath Puri & Mayapur Camp',
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		additionalHeaderImage: 'https://res.cloudinary.com/dnuq1lgqs/image/upload/v1672485863/WhatsApp_Image_2022-12-28_at_2.26.23_PM_nd86cp.jpg',
		paymentHeaderImage: 'https://i.pinimg.com/originals/ae/f2/5b/aef25b06ef92b646c2fa70a9ae81e5b2.jpg',
        uuid: isProd() ? '63ab231285f0d55dee92f158' : '63ab231285f0d55dee92f158',
		priceTallyImg: "https://res.cloudinary.com/dnuq1lgqs/image/upload/v1672485863/WhatsApp_Image_2022-12-28_at_2.26.23_PM_nd86cp.jpg",
		startingPrice: 4200,
	},
    {
		id: 'jagannath-puri-camp',
		title: 'Only Jagannath Puri Camp',
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		additionalHeaderImage: 'https://res.cloudinary.com/dnuq1lgqs/image/upload/v1672485863/WhatsApp_Image_2022-12-28_at_2.26.23_PM_nd86cp.jpg',
		paymentHeaderImage: 'https://i.ytimg.com/vi/9GjGUyHXm0g/maxresdefault.jpg',
        uuid: isProd() ? '63ab231a85f0d55dee92f159' : '63ab231a85f0d55dee92f159',
		priceTallyImg: "https://res.cloudinary.com/dnuq1lgqs/image/upload/v1672485863/WhatsApp_Image_2022-12-28_at_2.26.23_PM_nd86cp.jpg",
		startingPrice: 1600,
	},
    {
		id: 'mayapur-camp',
		title: 'Only Mayapur Camp',
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		additionalHeaderImage: 'https://res.cloudinary.com/dnuq1lgqs/image/upload/v1672485863/WhatsApp_Image_2022-12-28_at_2.26.23_PM_nd86cp.jpg',
		paymentHeaderImage: 'https://www.mayapur.com/wp-content/uploads/2021/03/Reflections-on-Bhakti-II-Devotion-as-the-Ultimate-Goal-of-Life.jpg',
        uuid: isProd() ? '63aaf4eb85f0d55dee92f151' : '63aaf4eb85f0d55dee92f151',
		priceTallyImg: "https://res.cloudinary.com/dnuq1lgqs/image/upload/v1672485863/WhatsApp_Image_2022-12-28_at_2.26.23_PM_nd86cp.jpg",
		startingPrice: 2300,
	},
]
