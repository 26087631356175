import { Button, TextField, Typography } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { Formik } from 'formik'
import React from 'react'
import { ROUTE } from '../../../constants/route'
import { EVENTS_UI_CONFIG } from '../../../data/event'

const ViplavaBridge = ({ match }) => {
	const eventId = match.params.eventId
	const event = EVENTS_UI_CONFIG.find((event) => event.uuid === eventId)
	return (
		<div className="viplava-page">
			<Formik
				initialValues={{
					email: '',
				}}
				// validationSchema={basicValidationSchema}
				validate={(values) => {
					const errors = {}
					if (!values.email) {
						errors.email = 'Required'
					} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
							values.email
						)
					) {
						errors.email = 'Invalid email address'
					}
					return errors
				}}
				onSubmit={(values) => {
					localStorage.setItem(
						'USER_DETAILS',
						JSON.stringify({
							email: values.email,
							name: '',
							imageUrl: `https://source.unsplash.com/96x96/?nature`,
						})
					)
					window.location.href = `${ROUTE.EVENT_REGISTER_PAGE.path}/${eventId}?email=${values.email}`
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					/* and other goodies */
				}) => (
					<form
						className="register-container"
						onSubmit={handleSubmit}
					>
						<Typography variant="h3">
							Register for {event.title}
						</Typography>
						<Typography variant="h6">
							Please input the email id. We will check it against
							our database and then proceed.
						</Typography>
						<TextField
							className="m-top-12"
							label="Enter Email"
							variant="outlined"
							value={values.email}
							name="email"
							onChange={(e) => {
								handleChange({
									target: {
										name: e.target.name,
										value: e.target.value.toLowerCase(),
									},
								})
							}}
							onBlur={handleBlur}
							fullWidth
							error={touched.email && errors.email}
							helperText={
								touched.email &&
								errors.email && <span>{errors.email}</span>
							}
						/>
						<Button
							color="primary"
							type="submit"
							startIcon={<Search />}
							variant="contained"
						>
							Check email
						</Button>
					</form>
				)}
			</Formik>
		</div>
	)
}

export default ViplavaBridge
