import {
	IconButton,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Typography,
	Button,
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import {
	CancelRounded,
	CheckCircleRounded,
	CloudDownload,
	EventBusyRounded,
	MeetingRoomRounded,
} from '@material-ui/icons'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeDialog, showDialog } from '../../../actions'
import {
	approveCashRequestAction,
	getFundBalanceAction,
	getCashListAction,
} from '../../../actions/event'
import { ACCOMODATION_CATEGORY, CLASS_LEVEL, EVENT_BOOKING_STATUS } from '../../../constants'
import { HIDE_MAIN_LOADER, SHOW_MAIN_LOADER } from '../../../constants/actions'
import { getCashList } from '../../../services/event'
import { updateBooking } from '../../../services/viplava'
import { exportFromJSON, isAreaLeader, isMobileScreen } from '../../../utils'
import '../style.scss'

const CashScreen = ({ eventId }) => {
	const [filters, setFilters] = useState({
		page: 1,
		per_page: 10,
	})
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const eventReducer = useSelector((state) => state.eventReducer)
	const { cashRequests } = eventReducer
	const AREAS =
		localStorage.getItem('AREAS') &&
		JSON.parse(localStorage.getItem('AREAS'))
	const [areaId, setAreaId] = useState(AREAS[0]._id)

	const approveRequest = (data) => {
		if (data._id) {
			dispatch(
				showDialog({
					title: 'Approve this cash request?',
					description:
						"Are you sure to approve this cash request? This action won't be reverted",
					form: [
						{
							name: 'amount',
							label: 'Amount Received',
							placeholder: 'Input the Amount you recieved',
							defaultValue: data.tokenAmount,
						},
					],
					actions: [
						{
							action: dispatch(closeDialog()),
							text: 'Cancel',
							color: 'secondary',
						},
						{
							action: (dialogValue) => {
								dispatch(
									approveCashRequestAction({
										id: data._id,
										cashRecieved: dialogValue.amount,
									})
								)
								dispatch(closeDialog())
							},
							text: 'Yes, Approve request',
						},
					],
				})
			)
		}
	}

	const disproveRequest = (data) => {
		console.log("data", data);
		if (data._id) {
			dispatch(
				showDialog({
					title: 'Cancel this cash request?',
					description:
						"Are you sure to Cancel this cash request? This action won't be reverted",
					actions: [
						{
							action: dispatch(closeDialog()),
							text: 'Cancel',
							color: 'secondary',
						},
						{
							action: () => {
								cancelBooking(data)
								dispatch(closeDialog())
							},
							text: 'Yes, Cancel request',
						},
					],
				})
			)
		}
	}

	const rowWidthConfig = {
		name: { default: { flex: 0.15 }, mobile: { width: 150 } },
		phone: { default: { flex: 0.1 }, mobile: { width: 150 } },
		facilitator: { default: { flex: 0.15 }, mobile: { width: 150 } },
		transportationAmount: {
			default: { flex: 0.1 },
			mobile: { width: 150 },
		},
		accomodationCategory: { default: { flex: 0.15 } },
		connectedArea: { default: { flex: 0.2 }, mobile: { width: 150 } },
		tokenAmount: { default: { flex: 0.1 }, mobile: { width: 100 } },
		finalAmount: { default: { flex: 0.1 }, mobile: { width: 100 } },
		status: { default: { flex: 0.1 }, mobile: { width: 100 } },
		user: { default: { width: 160 } },
	}

	const headCells = [
		{
			field: 'name',
			numeric: false,
			headerName: 'Name',
			sortable: false,
		},
		{
			field: 'phone',
			numeric: false,
			headerName: 'Phone',
			sortable: false,
		},
		{
			field: 'facilitator',
			numeric: false,
			headerName: 'Facilitator',
			sortable: false,
		},
		{
			field: 'transportationAmount',
			numeric: false,
			headerName: 'Transportation',
			sortable: false,
		},
		{
			field: 'accomodationCategory',
			numeric: false,
			headerName: 'Accomodation',
			sortable: false,
		},
		{
			field: 'finalAmount',
			numeric: false,
			headerName: 'Total Amount',
			sortable: true,
		},
		{
			field: 'tokenAmount',
			numeric: false,
			headerName: 'Amount paid',
			sortable: true,
		},
		{
			field: 'status',
			numeric: false,
			headerName: 'Pay mode',
			sortable: true,
		},
		{
			field: 'connectedArea',
			numeric: false,
			headerName: 'Area',
			sortable: false,
		},
		{
			field: 'user',
			headerName: 'Actions',
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<strong>
					{!isAreaLeader() && (
						<IconButton
							onClick={() => approveRequest(params.row)}
							aria-label="View Reason"
							color="primary"
							title="View Reason"
						>
							<CheckCircleRounded />
						</IconButton>
					)}
					{params.row.accomodationCategory === 'Dormitory' &&
						params.row.status === 'Cash' && (
							<IconButton
								onClick={() => upgradeToRoom(params.row)}
								aria-label="Upgrade to Room"
								color="primary"
								title="Upgrade to Room"
							>
								<MeetingRoomRounded />
							</IconButton>
						)}
					{params.row.status === 'Cash' && (
						<IconButton
							onClick={() => disproveRequest(params.row)}
							aria-label="Delete Booking"
							color="primary"
							title="Delete Booking"
						>
							<CancelRounded />
						</IconButton>
					)}
				</strong>
			),
		},
	]

	useEffect(() => {
		getCashData()
	}, [areaId])

	useEffect(() => {
		getCashData()
	}, [filters])

	const download = () => {
		const todayDate = format(new Date(), 'dd-MM-yyyy')
		const fileName = 'Cash Requests ' + todayDate
		dispatch({ type: SHOW_MAIN_LOADER })
		getCashList({
			page: 1,
			per_page: 5000,
			eventId,
		})
			.then((res) => {
				exportFromJSON({
					data: res.data?.data?.map((s) => ({
						name: s.userId.initiatedName || s.userId.name,
						email: s.userId.email,
						phone: s.userId.phone,
						sadhnaGroup: CLASS_LEVEL[s.userId.sadhnaGroup],
						facilitator:
							s.userId.facilitator?.initiatedName ||
							s.userId.facilitator?.name,
						connectedArea: s.connectedAreaId.name,
						transportationAmount: s.transportationAmount
							? 'Yes'
							: 'No',
						accomodationCategory:
							ACCOMODATION_CATEGORY[s.accomodationCategory].name,
						status: EVENT_BOOKING_STATUS[s.status].includes('CASH')
							? 'Cash'
							: 'Online',
						totalAmount: s.finalAmount,
						amountPaid: s.tokenAmount,
					})),
					fileName,
				})
				dispatch({ type: HIDE_MAIN_LOADER })
			})
			.catch((e) => {
				dispatch({ type: HIDE_MAIN_LOADER })
			})
	}

	const upgradeToRoom = async (rowData) => {
		const booking = cashRequests.data.find(req => req.bookingReferenceNo === rowData.bookingReferenceNo);
		await updateBooking({
			eventId,
			userId: booking.userId._id,
			accomodationCategory: 1,
			transportationAmount: booking.transportationAmount,
			paymentMode: 'CASH',
			tokenAmount: booking.tokenAmount || 500,
		})
		getCashData()
	}

	const cancelBooking = async (rowData) => {
		const booking = cashRequests.data.find(req => req.bookingReferenceNo === rowData.bookingReferenceNo);
		await updateBooking({
			eventId,
			userId: booking.userId._id,
			accomodationCategory: booking.accomodationCategory,
			transportationAmount: booking.transportationAmount,
			tokenAmount: booking.tokenAmount || 500,
		})
		getCashData()
	}

	const getCashData = () => {
		setLoading(true)
		if (isAreaLeader()) {
			dispatch(getCashListAction({ ...filters, areaId, eventId }))
			dispatch(getFundBalanceAction(areaId))
		} else {
			dispatch(getCashListAction({ ...filters, eventId }))
		}
		setLoading(false)
	}

	const handleUserData = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page: page,
			per_page: pageSize,
		}))
	}

	const onPageSizeChange = ({ pageSize }) => {
		setFilters((f) => ({
			...f,
			page: 1,
			per_page: pageSize,
		}))
	}

	return (
		<div className="screen subsidy-screen">
			{isAreaLeader() && (
				<div className="fund-details">
					{AREAS?.length > 1 && (
						<FormControl variant="outlined">
							<InputLabel id="demo-simple-select-label">
								Select Area
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={areaId}
								onChange={(e) => {
									setAreaId(e.target.value)
								}}
								displayEmpty
								className="mb-16 w-full"
							>
								{AREAS.map((area) => (
									<MenuItem key={area._id} value={area._id}>
										{area.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</div>
			)}
			<div className="filter-header">
				<Typography variant="h4">Cash Requests </Typography>
				<Button
					startIcon={<CloudDownload />}
					color="primary"
					variant="contained"
					onClick={download}
					style={{ marginBottom: '20px' }}
				>
					Download complete list
				</Button>
			</div>
			<DataGrid
				rows={
					cashRequests?.data?.map((s) => ({
						...s,
						id: s._id,
						name: s.userId.initiatedName || s.userId.name,
						email: s.userId.email,
						phone: s.userId.phone,
						facilitator:
							s.userId.facilitator?.initiatedName ||
							s.userId.facilitator?.name,
						connectedArea: s.connectedAreaId.name,
						transportationAmount: s.transportationAmount
							? 'Yes'
							: 'No',
						accomodationCategory:
							ACCOMODATION_CATEGORY[s.accomodationCategory].name,
						status: EVENT_BOOKING_STATUS[s.status].includes('CASH')
							? 'Cash'
							: 'Online',
					})) || []
				}
				autoHeight
				columns={headCells.map((col) => ({
					...col,
					sortable: true,
					disableClickEventBubbling: true,
					resizable: true,
					...(isMobileScreen()
						? rowWidthConfig[col.field].mobile ||
						  rowWidthConfig[col.field].default
						: rowWidthConfig[col.field].default),
				}))}
				pageSize={filters.per_page}
				rowCount={cashRequests?.total_results}
				// rowCount={10}
				rowsPerPageOptions={[5, 10, 20]}
				pagination
				paginationMode="server"
				onPageChange={handleUserData}
				disableColumnFilter
				loading={loading}
				onPageSizeChange={onPageSizeChange}
			/>
		</div>
	)
}

export default CashScreen
