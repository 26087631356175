export const SHOW_MAIN_LOADER = "SHOW_MAIN_LOADER";
export const HIDE_MAIN_LOADER = "HIDE_MAIN_LOADER";

export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";

export const SAVE_USER_DETAILS_STARTED = "SAVE_USER_DETAILS_STARTED";
export const SAVE_USER_DETAILS_SUCCESS = "SAVE_USER_DETAILS_SUCCESS";
export const SAVE_USER_DETAILS_FAILED = "SAVE_USER_DETAILS_FAILED";

export const UPDATE_AREA_STARTED = "UPDATE_AREA_STARTED";
export const UPDATE_AREA_SUCCESS = "UPDATE_AREA_SUCCESS";
export const UPDATE_AREA_FAILED = "UPDATE_AREA_FAILED";

export const CREATE_AREA_STARTED = "CREATE_AREA_STARTED";
export const CREATE_AREA_SUCCESS = "CREATE_AREA_SUCCESS";
export const CREATE_AREA_FAILED = "CREATE_AREA_FAILED";

export const GET_AREA_STARTED = "GET_AREA_STARTED";
export const GET_AREA_SUCCESS = "GET_AREA_SUCCESS";
export const GET_AREA_FAILED = "GET_AREA_FAILED";

export const GET_USERS_STARTED = "GET_USERS_STARTED";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const UPDATE_USER_STARTED = "UPDATE_USER_STARTED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const DELETE_USER_STARTED = "DELETE_USER_STARTED";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const LOGOUT_USER_STARTED = "LOGOUT_USER_STARTED";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED";

export const GET_PLEDGE_DETAIL_STARTED = "GET_PLEDGE_DETAIL_STARTED";
export const GET_PLEDGE_DETAIL_SUCCESS = "GET_PLEDGE_DETAIL_SUCCESS";
export const GET_PLEDGE_DETAIL_FAILED = "GET_PLEDGE_DETAIL_FAILED";

export const GET_PLEDGE_SUMMARY_STARTED = "GET_PLEDGE_SUMMARY_STARTED";
export const GET_PLEDGE_SUMMARY_SUCCESS = "GET_PLEDGE_SUMMARY_SUCCESS";
export const GET_PLEDGE_SUMMARY_FAILED = "GET_PLEDGE_SUMMARY_FAILED";

export const GET_PLEDGE_USERS_STARTED = "GET_PLEDGE_USERS_STARTED";
export const GET_PLEDGE_USERS_SUCCESS = "GET_PLEDGE_USERS_SUCCESS";
export const GET_PLEDGE_USERS_FAILED = "GET_PLEDGE_USERS_FAILED";

export const SEND_NOTIFICATION_STARTED = "SEND_NOTIFICATION_STARTED";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";

export const GET_SCHEDULE_FAILED = "GET_SCHEDULE_FAILED";
export const GET_SCHEDULE_STARTED = "GET_SCHEDULE_STARTED";
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";

export const CREATE_SCHEDULE_FAILED = "CREATE_SCHEDULE_FAILED";
export const CREATE_SCHEDULE_STARTED = "CREATE_SCHEDULE_STARTED";
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";

export const UPDATE_SCHEDULE_FAILED = "UPDATE_SCHEDULE_FAILED";
export const UPDATE_SCHEDULE_STARTED = "UPDATE_SCHEDULE_STARTED";
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS";

export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";

export const GET_EVENT_DATA_STARTED = "GET_EVENT_DATA_STARTED";
export const GET_EVENT_DATA_SUCCESS = "GET_EVENT_DATA_SUCCESS";
export const GET_EVENT_DATA_FAILED = "GET_EVENT_DATA_FAILED";

export const GET_SUBSIDY_LIST_STARTED = "GET_SUBSIDY_LIST_STARTED";
export const GET_SUBSIDY_LIST_SUCCESS = "GET_SUBSIDY_LIST_SUCCESS";
export const GET_SUBSIDY_LIST_FAILED = "GET_SUBSIDY_LIST_FAILED";

export const GET_CASH_LIST_STARTED = "GET_CASH_LIST_STARTED";
export const GET_CASH_LIST_SUCCESS = "GET_CASH_LIST_SUCCESS";
export const GET_CASH_LIST_FAILED = "GET_CASH_LIST_FAILED";

export const GET_FUND_BALANCE_STARTED = "GET_FUND_BALANCE_STARTED";
export const GET_FUND_BALANCE_SUCCESS = "GET_FUND_BALANCE_SUCCESS";
export const GET_FUND_BALANCE_FAILED = "GET_FUND_BALANCE_FAILED";

export const APPROVE_SUBSIDY_STARTED = "APPROVE_SUBSIDY_STARTED";
export const APPROVE_SUBSIDY_SUCCESS = "APPROVE_SUBSIDY_SUCCESS";
export const APPROVE_SUBSIDY_FAILED = "APPROVE_SUBSIDY_FAILED";

export const APPROVE_CASH_STARTED = "APPROVE_CASH_STARTED";
export const APPROVE_CASH_SUCCESS = "APPROVE_CASH_SUCCESS";
export const APPROVE_CASH_FAILED = "APPROVE_CASH_FAILED";

export const REJECT_SUBSIDY_STARTED = "REJECT_SUBSIDY_STARTED";
export const REJECT_SUBSIDY_SUCCESS = "REJECT_SUBSIDY_SUCCESS";
export const REJECT_SUBSIDY_FAILED = "REJECT_SUBSIDY_FAILED";
