import { Step, StepLabel, Stepper } from '@material-ui/core'
import React from 'react'
import GoogleLogin from 'react-google-login'
import { ROUTE } from '../../../constants/route'
import { getQueryObj } from '../../../utils'
import '../style.scss'
import {
	confirmViplavaRegistration,
	createOrder,
	getBookingDetails,
	getEventDetails,
	getUserByUid,
	updateBooking,
	updateWebUser,
} from '../../../services/viplava'
import { connect } from 'react-redux'
import { HIDE_MAIN_LOADER, SHOW_MAIN_LOADER } from '../../../constants/actions'
import { basicValidationSchema, calculateTotal, getAmounts } from '../utils'
import { Formik } from 'formik'
import BasicInfo from './BasicInfoScreen'
import AdditionalInfo from './AdditionalInfoScreen'
import PaymentSection from './PaymentScreen'
import AcknowledgmentScreen from './AcknowledgementScreen'
import { loginByToken } from '../../../services/auth'
import { GOOGLE_CLIENT_ID } from '../../../constants/config'
import { EVENTS_UI_CONFIG } from '../../../data/event'
import { CLASS_LEVEL } from '../../../constants'

class ViplavaRegister extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			stage: 0,
			userDetails: {},
			emailConfirmModal: false,
			userCreated: false,
			totalAmount: calculateTotal(getAmounts({})),
			event: null,
			orderIdUpdated: false,
			isTransportSelected: 1,
			eventId: this.props.match?.params?.eventId,
		}
		this.steps = ['Fill details', 'Select Amentities', 'Payment']
	}

	componentDidMount() {
		const data = getQueryObj(this.props?.location?.search)
		if (data.token) {
			// alert(JSON.stringify(data));
			loginByToken(data.token).then((res) => {
				const userProfile = res.data[0]
				const { _id, roles } = userProfile
				userProfile.loggedIn = true
				// const token = res.data[0]?.token
				// localStorage.setItem(process.env.AUTH_TOKEN, token)
				localStorage.setItem('UserProfile', JSON.stringify(userProfile))
				// check if the user is an facilitator or above role?
				// provide multiple entry options if this is the case.
				// but for now the person can register himself rather than registering others.
				this.firstEncounter({ _id })
			})
		} else {
			this.firstEncounter(data)
		}
		this.setEventId(this.props.match.params.eventId)
	}

	setEventId = (eventId) => {
		this.setState({
			eventId,
		})
		getEventDetails().then(({ result }) => {
			const event = result.find((event) => event.eventId === eventId)
			this.setState((state) => ({
				...state,
				event,
				totalAmount: calculateTotal(getAmounts({ event })),
			}))
		})
	}

	firstEncounter = (data) => {
		getUserByUid({ email: data.email, user_id: data._id }).then(
			({ result }) => {
				if (result?.email) {
					const tempState = {
						userDetails: {
							_id: result._id,
							email: data.email || result.email,
							name: result.name,
							initiatedName: result.initiatedName,
							imageUrl:
								result.imageUrl ||
								'https://source.unsplash.com/96x96/?nature',
							phone: result.phone,
							connectedAreaId: result.connectedAreaId,
							facilitator: result.facilitatorId,
							chantingRounds: result.chantingRounds,
							userRole: result.userRole,
							userCategory: result.userCategory || 'YOUTH',
							sadhnaGroup:
								result.userCategory === 'YOUTH'
									? result.sadhnaGroup || 1
									: 0,
						},
						userCreated: true,
					}
					// userId: 62d6fbc5e7cd03b240c0f5df
					getBookingDetails(
						result._id,
						this.props.match.params.eventId
					)
						.then((resp) => {
							if (resp.result?.bookingId) {
								tempState.booking = resp.result
							}
							if (
								resp.result?.status === 'CONFIRMED' ||
								resp.result?.status === 'FAILED' ||
								resp.result?.status === 'SUBSIDY_REQUESTED' ||
								resp.result?.status === 'CASH_PENDING' ||
								resp.result?.status === 'CASH_CONFIRMED' ||
								resp.result?.status === 'PARTIALLY_PAID' ||
								resp.result?.status === 'CASH_PARTIALLY_PAID'
							) {
								this.setState({
									stage: 3,
								})
							} else if (
								resp.result?.status === 'ORDERED' ||
								resp.result?.status === 'APPROVED' ||
								resp.result?.status === 'SUBSIDY_APPROVED'
							) {
								this.setState({
									stage: 2,
								})
							} else if (resp.result?.status === 'PENDING') {
								this.setState({
									stage: 1,
								})
							}
							this.setState(tempState)
						})
						.catch((e) => {
							this.setState(tempState)
						})
				} else {
					// check the localStorage
					try {
						const userDetails = JSON.parse(
							localStorage.getItem('USER_DETAILS')
						)
						if (userDetails) {
							this.setState({ userDetails })
						} else {
							this.setState({
								emailConfirmModal: true,
							})
						}
					} catch (e) {
						this.setState({
							emailConfirmModal: true,
						})
					}
				}
			}
		)
	}

	updateAmount = (updatedAmount) => {
		this.setState({
			totalAmount: updatedAmount,
		})
	}

	unsetOrderIdUpdated = () => {
		this.setState({
			orderIdUpdated: false,
		})
	}

	setOrderIdUpdated = () => {
		this.setState({
			orderIdUpdated: true,
		})
	}

	saveBasicInfo = (newData) => {
		const { userDetails } = this.state
		const { showLoader, hideLoader } = this.props
		const combinedDetails = { ...userDetails, ...newData }
		const {
			_id,
			chantingRounds,
			connectedAreaId,
			email,
			facilitator,
			imageUrl,
			initiatedName,
			name,
			phone,
			userRole,
			userCategory,
			sadhnaGroup,
			eventId,
		} = combinedDetails
		this.setState(({ userDetails }) => ({
			userDetails: { ...userDetails, ...newData },
		}))
		if (
			combinedDetails.email &&
			combinedDetails.name &&
			combinedDetails.phone &&
			(combinedDetails?.connectedAreaId ||
				combinedDetails?.connectedAreaId?._id) &&
			combinedDetails.facilitator &&
			combinedDetails.chantingRounds >= 2 &&
			combinedDetails.userCategory
		) {
			showLoader()
			updateWebUser({
				_id,
				chantingRounds,
				connectedAreaId: connectedAreaId?._id || connectedAreaId,
				email,
				facilitator: facilitator?._id || facilitator,
				imageUrl,
				initiatedName,
				name,
				phone,
				userRole,
				gender: 1,
				userCategory,
				sadhnaGroup,
			})
				.then(({ result }) => {
					if (result._id) {
						// const eventId = this.props.match.params.eventId
						updateBooking({
							userId: _id || result?._id,
							accomodationCategory: 0,
							transportationAmount: 0,
							eventId: eventId || this.props.match.params.eventId,
							tokenAmount: 500,
						})
							.then((resp) => {
								if (resp.result.bookingId) {
									if (
										this.props.match.params.eventId !==
										eventId
									) {
										// we have to redirect to othere event with event id: eventId
										window.location.href = window.location.href.replace(this.props.match.params.eventId, eventId)
									} else {
										this.handleNext()
										this.setState({
											booking: resp.result,
										})
									}
								}
							})
							.catch((err) => {
								console.log('errrrrrrrrrrrrrrrrrrr', err)
							})
					}
					this.setState({
						userDetails: {
							_id: result._id,
							email: result.email,
							name: result.name,
							initiatedName: result.initiatedName,
							imageUrl: result.imageUrl,
							phone: result.phone,
							connectedAreaId: result.connectedAreaId,
							facilitator: result.facilitatorId,
							chantingRounds: result.chantingRounds,
							userRole: result.userRole,
							gender: result.gender || 1,
							userCategory: result.userCategory || 'YOUTH',
							sadhnaGroup:
								result.userCategory === 'YOUTH'
									? result.sadhnaGroup || 1
									: 0,
						},
						userCreated: true,
					})
				})
				.finally(() => {
					hideLoader()
				})
		} else {
			alert('Please fill all details')
		}
	}

	saveAddnInfo = (newData) => {
		const { userDetails, booking } = this.state
		const combinedDetails = { ...userDetails, ...newData, booking }
		const {
			_id,
			accomodationCategory,
			registrationAmount,
			donationAmount,
			accomodationAmount,
			transportationAmount,
			outfitAmount,
			outfitCount,
		} = combinedDetails
		if (_id) {
			this.setState({
				isTransportSelected: transportationAmount,
			})
			const eventId = this.props.match.params.eventId
			updateBooking({
				userId: _id,
				bookingId: booking?.bookingId,
				accomodationCategory,
				registrationAmount,
				donationAmount,
				accomodationAmount,
				transportationAmount,
				outfitCount,
				eventId,
				tokenAmount: newData.tokenAmount,
			})
				.then((resp) => {
					this.handleNext()
					this.setState({
						booking: resp.result,
					})
				})
				.catch((err) => {
					console.log('errrrrrrrrrrrrrrrrrrr', err)
				})
		}
	}

	onRaiseSubsidyRequest = (booking) => {
		this.setState({
			booking,
			stage: 3,
		})
	}

	generateOrderId = () => {
		const { userDetails, booking } = this.state
		if (userDetails._id && booking.bookingId) {
			return createOrder({
				userId: userDetails._id,
				bookingId: booking.bookingId,
			})
				.then((resp) => {
					if (resp.result.orderId) {
						this.setState({
							booking: {
								...booking,
								orderId: resp.result.orderId,
							},
							orderIdUpdated: true,
						})
					}
				})
				.catch((err) => {
					console.log('errrrrrrrrrrrrrrrrrrr', err)
				})
		}
	}

	getStepContent = (stepIndex) => {
		const {
			userDetails,
			totalAmount,
			booking,
			paymentDone,
			failureReason,
			event,
			orderIdUpdated,
			isTransportSelected,
		} = this.state
		const eventId = this.state.eventId
		const eventUiConfig = EVENTS_UI_CONFIG.find(
			(event) => event.uuid === eventId
		)

		const FormHeader = (
			<div className="viplava-form-header">
				<img
					className="profile-img"
					src={userDetails.imageUrl}
					alt="Profile"
					referrerpolicy="no-referrer"
				/>
				{stepIndex ? (
					eventUiConfig.showAmount && (
						<div className="payment-details">
							<div className="small">Total Amount</div>
							<div className="cost">₹{totalAmount}</div>
						</div>
					)
				) : (
					<div className="payment-details">
						<div className="small" style={{ margin: '20px' }}>
							Please update your profile information here
						</div>
					</div>
				)}
			</div>
		)
		switch (stepIndex) {
			case 0:
				return (
					<Formik
						initialValues={{
							email: userDetails.email || '',
							name: userDetails.name || '',
							initiatedName: userDetails.initiatedName || '',
							connectedAreaId: userDetails.connectedAreaId || '',
							phone: userDetails.phone || '',
							facilitator: userDetails.facilitator || '',
							chantingRounds: userDetails.chantingRounds || 0,
							userRole: userDetails.userRole || 0,
							userCategory: userDetails.userCategory || 'YOUTH',
							sadhnaGroup:
								userDetails.userCategory === 'YOUTH'
									? userDetails.sadhnaGroup || 1
									: 0,
						}}
						validationSchema={basicValidationSchema}
						// validate={(values) => {
						//   const errors = {};
						//   if (!values.email) {
						//     errors.email = "Required";
						//   } else if (
						//     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
						//   ) {
						//     errors.email = "Invalid email address";
						//   }
						//   return errors;
						// }}
						onSubmit={(values, { setSubmitting }) => {
							this.saveBasicInfo(values)
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							/* and other goodies */
						}) => (
							<form noValidate className="register-container">
								{FormHeader}
								<BasicInfo
									errors={errors}
									touched={touched}
									handleChange={handleChange}
									handleBlur={handleBlur}
									userDetails={values}
									onSubmit={handleSubmit}
									eventId={eventId}
								/>
							</form>
						)}
					</Formik>
				)
			case 1:
				return (
					<form noValidate className="register-container">
						{FormHeader}
						<AdditionalInfo
							userDetails={userDetails}
							onSubmit={this.saveAddnInfo}
							totalAmount={totalAmount}
							updateAmount={this.updateAmount}
							event={event}
							eventId={eventId}
						/>
					</form>
				)
			case 2:
				return (
					<PaymentSection
						eventId={eventId}
						userDetails={userDetails}
						booking={booking}
						onClickingPay={this.generateOrderId}
						onRaiseSubsidyRequest={this.onRaiseSubsidyRequest}
						onPaymentSuccess={() => {
							confirmViplavaRegistration(booking.bookingId)
								.then(({ result }) => {
									this.setState({
										paymentDone: true,
										failureReason: '',
										stage: 3,
										booking: result,
									})
								})
								.catch((err) => {
									this.setState((state) => ({
										paymentDone: true,
										failureReason: '',
										stage: 3,
										booking: {
											...state.booking,
											status: 'FAILED',
										},
									}))
								})
						}}
						onPaymentFailure={(failureObj) => {
							this.setState({
								paymentDone: false,
								failureObj: failureObj,
								stage: 3,
							})
						}}
						isTransportSelected={isTransportSelected}
						orderIdUpdated={orderIdUpdated}
						unsetOrderIdUpdated={this.unsetOrderIdUpdated}
					/>
				)
			default:
				if (booking?.bookingReferenceNo) {
					return (
						<AcknowledgmentScreen
							name={userDetails.initiatedName || userDetails.name}
							paymentDone={paymentDone}
							failureReason={failureReason}
							bookingStatus={booking.status}
							bookingReferenceNo={booking.bookingReferenceNo}
							bookingDateTime={booking.bookingDateTime}
							eventName={booking.eventName}
						/>
					)
				}
		}
	}

	handleNext = () => {
		this.setState((state) => ({ stage: state.stage + 1 }))
	}

	handleBack = () => {
		this.setState((state) => ({ stage: state.stage - 1 }))
	}

	handleReset = () => {
		this.setState({
			stage: 0,
		})
	}

	render() {
		const { stage, userDetails, emailConfirmModal } = this.state
		return (
			<div className="viplava-page">
				{emailConfirmModal && (
					<div className="email-confirm-modal">
						<div className="message">
							Sorry but we found your email invalid. Kindly verify
							your email again
						</div>
						<GoogleLogin
							clientId={GOOGLE_CLIENT_ID}
							buttonText="Verify with Google"
							onSuccess={(res) => {
								localStorage.setItem(
									'USER_DETAILS',
									JSON.stringify({
										email: res.profileObj.email,
										name: res.profileObj.name,
										imageUrl: res.profileObj.imageUrl,
									})
								)
								this.setState({
									emailConfirmModal: false,
								})
								window.location.href = `${ROUTE.VIPLAVA_REGISTER.path}?email=${res.profileObj.email}`
							}}
							onFailure={() => {}}
							cookiePolicy={'single_host_origin'}
							className="white-border-button"
						/>
					</div>
				)}
				<Stepper activeStep={stage} alternativeLabel>
					{this.steps.map((label, index) => (
						<Step
							key={label}
							onClick={() => {
								if (stage < 3 && stage > index)
									this.setState({
										stage: index,
									})
							}}
						>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{userDetails?.email && this.getStepContent(stage)}
			</div>
		)
	}
}

const mapStateToProps = null

const mapDispatchToProps = (dispatch) => ({
	showLoader: () => dispatch({ type: SHOW_MAIN_LOADER }),
	hideLoader: () => dispatch({ type: HIDE_MAIN_LOADER }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViplavaRegister)
