import {
	CREATE_SCHEDULE_STARTED,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAILED,
    UPDATE_SCHEDULE_STARTED,
    UPDATE_SCHEDULE_SUCCESS,
    UPDATE_SCHEDULE_FAILED,
    GET_SCHEDULE_STARTED,
    GET_SCHEDULE_SUCCESS,
    GET_SCHEDULE_FAILED
} from "../constants/actions";
import { ALERT } from "../constants";
import { format } from "date-fns";

let initialState = {
	schedules: {},
	loading: false,
	error: null,
	alert: null,
};
const scheduleReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SCHEDULE_STARTED:
			return {
				...state,
				loading: true,
			};
		case GET_SCHEDULE_SUCCESS:
            let tmpShchedule = state.schedules;
            tmpShchedule[action.payload.date] = action.payload.response.data;
			return {
				...state,
				loading: false,
                schedules: tmpShchedule
			};
		case GET_SCHEDULE_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			};
		case CREATE_SCHEDULE_STARTED:
			return {
				...state,
				loading: true,
			};
		case CREATE_SCHEDULE_SUCCESS: {
            let data = action.payload;
            let keyDate = format(new Date(data.date), "MM/yy")
            let tempSchedules = state.schedules;
            if(tempSchedules.hasOwnProperty(keyDate)) {
                tempSchedules[keyDate].push(data);
            } else {
                tempSchedules[keyDate] = [data];
            }
			return {
				...state,
				loading: false
			};
		}
		case CREATE_SCHEDULE_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			};
        case UPDATE_SCHEDULE_STARTED:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_SCHEDULE_SUCCESS: {
            let data = action.payload;
			let keyDate;
			try {
				keyDate = format(new Date(data.date), "MM/yy")
			} catch(e) {
				// if the date is in dd/mm/yy format just take mm/yy out of it
				keyDate = data.date.slice(3);
			}
            let tempSchedules = state.schedules;
            tempSchedules[keyDate] = tempSchedules[keyDate].map(el=> el._id == data._id ? data : el);
            return {
                ...state,
				loading: false,
                schedules: tempSchedules
            }
		}
        case UPDATE_SCHEDULE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                alert: {
                    message: action.error,
                    severity: ALERT.ERROR,
                    duration: 5000,
                },
            };
		default:
			return state;
	}
};

export default scheduleReducer;
