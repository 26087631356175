import React, { Component } from 'react'
import { withRouter, matchPath } from 'react-router'
import { NavLink } from 'react-router-dom'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import Logo from '../../assets/images/logo.jpeg'
import './style.scss'
import NavbarComponent from '../Navbar'
import { loginByToken } from '../../services/auth'
import { ROUTE, WITHOUT_HEADER_PAGES } from '../../constants/route'

class HeaderComponent extends Component {
	componentDidMount() {
		const { history, location } = this.props
		const search = location.search

		const match = WITHOUT_HEADER_PAGES.find((path) => {
			return matchPath(location.pathname, {
				path: path,
				exact: true,
			})
		})
		if (search && !match) {
			const [key, value] = location.search.split('=')
			if (key.includes('token') && value) {
				loginByToken(value).then((res) => {
					const userProfile = res.data[0]
					userProfile.loggedIn = true
					const token = res.data[0]?.token
					localStorage.setItem(process.env.AUTH_TOKEN, token)
					localStorage.setItem(
						'UserProfile',
						JSON.stringify(userProfile)
					)
					history.replace(ROUTE.DASHBOARD.path)
				})
			}
		}
	}

	render() {
		const navVal = this.action
		const { location } = this.props
		const match = WITHOUT_HEADER_PAGES.find((path) => {
			return matchPath(location.pathname, {
				path: path,
				exact: true,
			})
		})
		return (
			<header
				className="app-header"
				style={{
					display: match ? 'none' : 'block',
				}}
			>
				<AppBar position="static">
					<Toolbar className={'header-container'}>
						{localStorage.getItem('UserProfile') ? (
							<NavLink to="/dashboard" exact={true}>
								<LogoContainer />
							</NavLink>
						) : (
							<LogoContainer />
						)}

						<NavbarComponent showLinks={false} {...this.props} />
					</Toolbar>
				</AppBar>
			</header>
		)
	}
}

export const LogoContainer = () => {
	return (
		<div className="logo-container">
			<div className="logo-img-wrapper">
				<img alt="SP3C" src={Logo} />
			</div>
			<Typography variant="h2" className="logo-title">
				SP3C
			</Typography>
		</div>
	)
}

export default withRouter(HeaderComponent)
