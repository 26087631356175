import {
	Button,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from "@material-ui/core";
import React from "react";
import GoogleLogin from "react-google-login";
import { Link } from "react-router-dom";
import { ROUTE } from "../../constants/route";
import { AdditionalInfo, BasicInfo } from "./RegisterForm";
import { createUser, updateUser } from "../../services/user";
import { format } from "date-fns";
import { COLOR } from "../../constants/theme";
import { GOOGLE_CLIENT_ID } from "../../constants/config";

export default class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stage: 0,
			userDetails: {},
		};
		this.steps = [
			"Login with Google",
			"Fill basic details",
			"Submit the registration request",
		];
	}

	onGoogleLoginSuccess = (res) => {
		if (res.profileObj) {
			this.setState((state) => ({
				userDetails: {
					...state.userDetails,
					email: res.profileObj.email,
					name: res.profileObj.name,
					imageUrl: res.profileObj.imageUrl,
				},
			}));
			// getUsers({email: res.profileObj.email}).then((res) => {
			// 	console.log('>>>>>>>>>>',res.data)
			// })
		}
		this.handleNext();
	};

	onGoogleLoginFailure = (res) => {
		console.log("failure", res);
	};

	saveBasicInfo = (newData) => {
		const {userDetails} = this.state;
		const combinedDetails = { ...userDetails, ...newData };
		if(combinedDetails.dob){
			combinedDetails.dob = format(combinedDetails.dob, "dd/MM/yyyy")
		}
		this.setState(({ userDetails }) => ({
			userDetails: { ...userDetails, ...newData },
		}));
		if(combinedDetails.email && combinedDetails.name && combinedDetails.phone && combinedDetails.connectedAreaId){
			createUser(combinedDetails).then((res) => {
				this.setState(({ userDetails }) => ({
					userDetails: { ...userDetails, ...res.data },
				}));
				this.handleNext();
			}).catch((err) => {
				if(err.response.status === 409){
					alert("You are already registered with SP3C.");
				} else {
					alert("Something is not working correct. Please refresh and try again");
				}
			})
		} else {
			alert("You've not entered name / email / phone / connected area")
		}
	};

	saveAddnInfo = (newData) => {
		this.setState(({ userDetails }) => ({
			userDetails: { ...userDetails, ...newData },
		}));
		if(this.state.userDetails._id){
			updateUser(this.state.userDetails._id, newData).then(() => {
				this.handleNext();
			}).catch(() => {
				alert("Something is not working correct. Please refresh and try again");
			})
		}
	};

	getStepContent = (stepIndex) => {
		const { userDetails } = this.state;
		switch (stepIndex) {
			case 0:
				return (
					<div className="google-btn">
						<GoogleLogin
							clientId={GOOGLE_CLIENT_ID}
							buttonText="Signup with Google"
							onSuccess={this.onGoogleLoginSuccess}
							onFailure={this.onGoogleLoginFailure}
							cookiePolicy={"single_host_origin"}
						/>
					</div>
				);
			case 1:
				return (
					<form noValidate className="register-container">
						<BasicInfo
							userDetails={this.state.userDetails}
							onSubmit={this.saveBasicInfo}
						/>
					</form>
				);
			case 2:
				return (
					<form noValidate className="register-container">
						<AdditionalInfo
							userDetails={this.state.userDetails}
							onSubmit={this.saveAddnInfo}
						/>
					</form>
				);
			default:
				return <AcknowledgmentScreen name={userDetails.name} />;
		}
	};

	handleNext = () => {
		this.setState((state) => ({ stage: state.stage + 1 }));
	};

	handleBack = () => {
		this.setState((state) => ({ stage: state.stage - 1 }));
	};

	handleReset = () => {
		this.setState({
			stage: 0,
		});
	};

	render() {
		const { stage } = this.state;
		return (
			<div className="register-page">
				<Stepper activeStep={stage} alternativeLabel>
					{this.steps.map((label, index) => (
						<Step key={label} onClick={() => {
							if(index === 1){
								this.setState({
									stage: index
								})
							}
						}}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{this.getStepContent(stage)}
			</div>
		);
	}
}

const AcknowledgmentScreen = ({ name }) => {
	return (
		<div className="success-screen">
			<div className="mb-16">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					width={"50"}
					height={"50"}
					viewBox="0 0 375.147 375.147"
					fill={COLOR.PRIMARY}
				>
					<polygon points="344.96,44.48 119.147,270.293 30.187,181.333 0,211.52 119.147,330.667 375.147,74.667 		" />
				</svg>
			</div>
			<Typography variant="h2" className="mb-16">
				{`Thank you ${name ? name + " " : ""}for registering to SP3C.`}
			</Typography>
			<Typography variant="body1" className="mb-16">
				The details would be forwarded to the concerned area leader. You can
				also install our SP3C application also for seamless experience of our
				services. Thank you so much for being here.
			</Typography>
			<div className="action-btns">
				<Link to={ROUTE.HOME.path}>
					<Button variant="contained" color="primary" className="mb-16">
						Return To Home Page
					</Button>
				</Link>
				<Typography variant="body1">
					Read our{" "}
					<Link to={ROUTE.PRIVACY.path} className="font-bold">
						Privacy policy
					</Link>
				</Typography>
			</div>
		</div>
	);
};
