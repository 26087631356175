import React from 'react'
import FailureScreen from './FailureScreen'
import SubsidyInProgress from './SubsidyInProgress'
import CashInProgress from './CashInProgress'
import SuccessScreen from './SuccessScreen'

const AcknowledgmentScreen = ({
	name,
	bookingStatus,
	bookingReferenceNo,
	bookingDateTime,
	eventName,
}) => {
	if (bookingStatus === 'CONFIRMED' || bookingStatus === 'CASH_CONFIRMED') {
		return (
			<SuccessScreen
				name={name}
				bookingReferenceNo={bookingReferenceNo}
				bookingDateTime={bookingDateTime}
				eventName={eventName}
			/>
		)
	} else if (bookingStatus === 'SUBSIDY_REQUESTED') {
		return (
			<SubsidyInProgress
				name={name}
				bookingReferenceNo={bookingReferenceNo}
				bookingDateTime={bookingDateTime}
			/>
		)
	} else if (
		bookingStatus === 'CASH_PENDING' ||
		bookingStatus === 'PARTIALLY_PAID' ||
		bookingStatus === 'CASH_PARTIALLY_PAID'
	) {
		return (
			<CashInProgress
				name={name}
				bookingReferenceNo={bookingReferenceNo}
				bookingDateTime={bookingDateTime}
			/>
		)
	}
	return <FailureScreen name={name} bookingReferenceNo={bookingReferenceNo} />
}

export default AcknowledgmentScreen
