import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Sidebar from "../Sidebar";
import { ROUTE } from "../../constants/route";
import { connect, useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { getEventDetailsAction } from "../../actions/event";

const PrivateRouter = ({
	component: Component,
	path,
	route,
	userStatus,
	user,
	...rest
}) => {
	const dispatch = useDispatch();
	const events = useSelector(state => state.eventReducer)?.events
	useEffect(() => {
        if(!events?.length) {
            dispatch(getEventDetailsAction())
        }
    }, [])
	if (
		(user && user.roles.some((r) => route.roles.indexOf(r) >= 0)) ||
		localStorage.getItem(process.env.AUTH_TOKEN)
	) {
		return (
			<Route
				path={path}
				{...rest}
				render={(props) => (
					<div className="private-route">
						<Sidebar events={events} {...props} />
						<div className="component-wrapper">
							<Component {...props} />
						</div>
					</div>
				)}
			/>
		);
	} else {
		return (
			<Route
				{...rest}
				path={path}
				render={(props) => <Redirect {...props} to={ROUTE.HOME} />}
			/>
		);
	}
};

const mapStateToProps = (state) => ({
	user: state.commonContent.user,
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouter);
