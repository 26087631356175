import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { Component } from "react";
import { FEST_TYPE } from "../../constants";
import {
	createPledgeDetails,
	updatePledgeDetails,
} from "../../services/pledging";
import "./style.scss";

class UpdatePledgingPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pledgingId: props.pledgingId || "",
			tag: props.tag || "",
			msg: props.msg || "",
			startTime: props.startTime
				? format(new Date(props.startTime), "yyyy-MM-dd") +
				  "T" +
				  format(new Date(props.startTime), "HH:mm")
				: format(new Date(), "yyyy-MM-dd") + "T" + format(new Date(), "HH:mm"),
			endTime: props.endTime
				? format(new Date(props.endTime), "yyyy-MM-dd") +
				  "T" +
				  format(new Date(props.endTime), "HH:mm")
				: format(new Date(), "yyyy-MM-dd") + "T" + format(new Date(), "HH:mm"),
		};
	}

	onChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	submitFileData = () => {
		const { pledgingId, startTime, endTime } = this.state;
		const { closeHandler } = this.props;
		if (pledgingId) {
			//update pledging
			updatePledgeDetails(pledgingId, {
				...this.state,
				startTime: startTime ? new Date(startTime).getTime() : startTime,
				endTime: endTime ? new Date(endTime).getTime() : endTime,
			}).then((res) => {
				closeHandler();
			});
		} else {
			//create area
			createPledgeDetails(this.state).then((res) => {
				closeHandler();
			});
		}
	};

	render() {
		const { pledgingId, msg, startTime, endTime, tag } = this.state;
		return (
			<form noValidate autoComplete="off">
				<Typography variant="h5">
					{pledgingId ? "Update Pledge Drive" : "Create Pledge Drive"}
				</Typography>
				<TextField
					className="m-top-16"
					label="Tag"
					variant="outlined"
					defaultValue={tag}
					value={tag}
					name="tag"
					onChange={(e) => this.onChange(e.target.name, e.target.value)}
					fullWidth
				/>
				<TextField
					className="m-top-16"
					label="Message"
					variant="outlined"
					defaultValue={msg}
					value={msg}
					name="msg"
					onChange={(e) => this.onChange(e.target.name, e.target.value)}
					fullWidth
					multiline
					rows={4}
				/>
				<FormControl variant="outlined" className="m-top-16" fullWidth>
					<TextField
						name="startTime"
						label="Start Time"
						type="datetime-local"
						variant="outlined"
						value={startTime}
						onChange={(e) => this.onChange(e.target.name, e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</FormControl>
				<FormControl variant="outlined" className="m-top-16" fullWidth>
					<TextField
						name="endTime"
						label="End Time"
						type="datetime-local"
						variant="outlined"
						value={endTime}
						onChange={(e) => this.onChange(e.target.name, e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</FormControl>
				<Button
					className="m-top-16"
					variant="contained"
					color="primary"
					onClick={this.submitFileData}
				>
					{pledgingId ? "UPDATE PLEDGE" : "CREATE PLEDGE"}
				</Button>
			</form>
		);
	}
}

export default UpdatePledgingPopup;
