import React from 'react'
import CashScreen from './components/CashScreen'
import FundScreen from './components/FundsScreen'
import OverviewScreen from './components/OverviewScreen'
import ReportScreen from './components/ReportScreen'
import SubsidyScreen from './components/SubsidyScreen'
import TabHeader from './components/TabHeader'
import './style.scss'

const EventContainer = ({ match }) => {
    const [value, setValue] = React.useState(0)
    const eventId = match?.params?.eventId

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div className="event-container">
            <TabHeader value={value} handleChange={handleChange} />
            <div>
                {value === 0 && <OverviewScreen eventId={eventId} />}
                {value === 1 && <SubsidyScreen eventId={eventId} />}
                {value === 2 && <CashScreen eventId={eventId} />}
                {value === 3 && <ReportScreen eventId={eventId} />}
                {value === 4 && <FundScreen eventId={eventId} />}
            </div>
        </div>
    )
}

export default EventContainer
