import {
	CLOSE_DRAWER,
	HIDE_ALERT,
	HIDE_DIALOG,
	OPEN_DRAWER,
	SHOW_ALERT,
	SHOW_DIALOG,
} from "./constants/actions";
import { actionCreator } from "./utils/redux";

export const hideAlert = () => (dispatch) => {
	dispatch(actionCreator(HIDE_ALERT));
};

export const showAlert = (payload) => (dispatch) => {
	dispatch(actionCreator(SHOW_ALERT, payload));
};

export const closeDialog = () => (dispatch) => {
	dispatch(actionCreator(HIDE_DIALOG));
};

export const showDialog = (payload) => (dispatch) => {
	dispatch(actionCreator(SHOW_DIALOG, payload));
};

export const showSidebar = () => (dispatch) => {
	dispatch(actionCreator(OPEN_DRAWER));
};

export const hideSidebar = () => (dispatch) => {
	dispatch(actionCreator(CLOSE_DRAWER));
};
