import React, { Component } from "react";
import { Button, IconButton, Modal } from "@material-ui/core";
import isEqual from "lodash.isequal";
import {
	exportFromJSON,
	getCurrentUser,
	isAreaLeader,
	isAsstDirector,
	isDirector,
	isGenSecretary,
	isMobileScreen,
} from "../../utils";
import UpdateAreaPopup from "./UpdateArea";
import { withStyles } from "@material-ui/core/styles";
import {
	AddCircle,
	CloudDownload,
	Delete,
	Edit,
	People,
	Visibility,
} from "@material-ui/icons";
import { closeDialog, showDialog } from "../../actions";
import { getAreasAction } from "../../actions/area";
import { connect } from "react-redux";
import { COLOR } from "../../constants/theme";
import "./style.scss";
import Table from "../../components/Table";
import { deactivateArea } from "../../services/area";
import { ROUTE } from "../../constants/route";

const styles = {
	DataGrid: {
		background: COLOR.WHITE,
	},
	tableHeader: {
		marginBottom: "20px",
		padding: "20px",
	},
};

class ManageAreaContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: {
				assistantDirector: "",
			},
			modalData: null,
			areas: props.areas || [],
		};
		this.isMobile = isMobileScreen();
	}
	componentDidMount() {
		const { getAreasAction } = this.props;
		const user = getCurrentUser();
		const userId = user._id;
		const params = {
			per_page: 100,
		};
		if (isAsstDirector(user)) {
			params.assistantDirector = userId;
		}
		getAreasAction(params).then((data) => {
			this.setState({ areas: data?.data?.data });
		});
	}

	componentDidUpdate(prevProps) {
		const { areas } = this.props;
		if (!isEqual(prevProps.areas, areas))
			this.setState({
				areas,
			});
	}

	viewArea = (row) => {
		this.setState({
			modalData: row,
			modalType: "VIEW",
		});
	};

	editArea = (row) => {
		this.setState({
			modalData: row,
			modalType: "EDIT",
		});
	};

	deleteArea = (area) => {
		this.props.showDialog({
			title: "Delete this area?",
			description:
				"Are you sure to delete this area? This action won't be reverted",
			actions: [
				{ action: this.props.closeDialog, text: "Cancel", color: "secondary" },
				{
					action: () => {
						deactivateArea(area._id).then(() => {
							this.updateTableDataHandler();
						});
						this.props.closeDialog();
					},
					text: "Yes, Delete Area",
				},
			],
		});
	};

	updateTableDataHandler = () => {
		const { getAreasAction } = this.props;
		const params = {
			per_page: 100,
		};
		getAreasAction(params).then((data) => {
			this.setState({ areas: data?.data?.data });
		});
	};

	onModalClose = () => {
		this.setState({
			modalData: null,
		});
	};

	searchTerm = (term) => {
		const lowerTerm = term.toLowerCase();
		this.setState({
			areas: this.props.areas.filter((area) => {
				return (
					area.code.toLowerCase().includes(lowerTerm) ||
					area.name.toLowerCase().includes(lowerTerm) ||
					area.areaLeader?.name.toLowerCase().includes(lowerTerm) ||
					area.assistantDirector?.name.toLowerCase().includes(lowerTerm)
				);
			}),
		});
	};

	download = () => {
		const { areas } = this.state;
		const fileName = "areas";
		exportFromJSON({
			data: areas.map((area) => ({
				...area,
				areaLeader: area?.areaLeader?.initiatedName || area?.areaLeader?.name,
				assistantDirector:
					area?.assistantDirector?.initiatedName ||
					area?.assistantDirector?.name,
				facilitators: area?.facilitators?.map((f) => f.initiatedName || f.name),
			})),
			fileName,
		});
	};

	populateData = (areas, isEditAccess, isDeleteAccess) => {
		const user = getCurrentUser();
		const rowWidthConfig = {
			code: { default: { width: 100 } },
			name: { default: { flex: 0.25 }, mobile: { width: 200 } },
			areaLeader: { default: { flex: 0.35 }, mobile: { width: 200 } },
			assistantDirector: { default: { flex: 0.35 }, mobile: { width: 200 } },
			area: {
				default: {
					width: 160,
				},
			},
		};
		const headCells = [
			{ field: "code", numeric: false, headerName: "Code" },
			{ field: "name", numeric: false, headerName: "Name" },
			{
				field: "areaLeader",
				numeric: false,
				headerName: "Area Leader",
			},
			{
				field: "assistantDirector",
				numeric: false,
				headerName: "Assistant Director",
			},
			{
				field: "area",
				headerName: "Actions",
				renderCell: (params) => (
					<strong>
						<IconButton
							onClick={() => this.viewArea(params.value)}
							ariaLabel="View"
							title="View"
							style={{padding: "7px"}}
						>
							<Visibility />
						</IconButton>
						{isEditAccess && (
							<IconButton
								onClick={() => this.editArea(params.value)}
								ariaLabel="Edit"
								style={{padding: "7px"}}	
								title="Edit"
							>
								<Edit />
							</IconButton>
						)}
						{isEditAccess && (
							<IconButton
								onClick={() => this.props.history.push(`${ROUTE.UPDATE_FRONTLINERS.path}?areaId=${params.value._id}`)}
								ariaLabel="Assign frontliners"
								style={{padding: "7px"}}	
								title="Assign frontliners"
							>
								<People />
							</IconButton>
						)}
						{isDeleteAccess && (
							<IconButton
								onClick={() => this.deleteArea(params.value)}
								ariaLabel="Delete"
								style={{padding: "7px"}}	
								title="Delete"
							>
								<Delete />
							</IconButton>
						)}
					</strong>
				),
			},
		];
		const data = [
			{
				btnLabel: "View",
				btnClick: this.viewArea,
			},
		];
		if (isEditAccess) {
			data.push({
				btnLabel: "Edit",
				btnClick: this.editArea,
			});
		}
		const { classes } = this.props;
		return (
			<div className="area-container">
				<Table
					data={areas.map((area) => ({
						id: area._id,
						code: area.code,
						name: area.name,
						areaLeader:
							area.areaLeader &&
							(area.areaLeader.initiatedName || area.areaLeader.name),
						assistantDirector:
							area.assistantDirector &&
							(area.assistantDirector.initiatedName ||
								area.assistantDirector.name),
						area: area,
					}))}
					header={headCells.map((col) => ({
						...col,
						sortable: true,
						disableClickEventBubbling: true,
						resizable: true,
						...(this.isMobile
							? rowWidthConfig[col.field].mobile ||
							  rowWidthConfig[col.field].default
							: rowWidthConfig[col.field].default),
					}))}
					classes={classes}
					searchTerm={this.searchTerm}
					actionButtons={() => (
						<div className="btn-group">
							{(isGenSecretary(user) || isDirector(user)) && (
								<Button
									startIcon={<AddCircle />}
									color="primary"
									variant="contained"
									onClick={() =>
										this.editArea({
											areaLeader: null,
											assistantDirector: null,
											code: "",
											facilitators: [],
											frontliners: [],
											name: "",
										})
									}
								>
									Add Area
								</Button>
							)}
							<Button
								startIcon={<CloudDownload />}
								color="primary"
								variant="contained"
								onClick={this.download}
							>
								Download
							</Button>
						</div>
					)}
				/>
			</div>
		);
	};

	render() {
		const { modalData, modalType } = this.state;
		const { areas } = this.state;
		const user = getCurrentUser();
		const areaLeader =
			modalData &&
			modalData.areaLeader &&
			(modalData.areaLeader.initiatedName || modalData.areaLeader.name);
		const asstDirector =
			modalData &&
			modalData.assistantDirector &&
			(modalData.assistantDirector.initiatedName ||
				modalData.assistantDirector.name);
		let TableComponent;
		if (isDirector(user) || isAsstDirector(user)) {
			TableComponent = this.populateData(areas, false, false);
		} else if (isGenSecretary(user)) {
			TableComponent = this.populateData(areas, true, true);
		} else if (isAreaLeader(user)) {
			TableComponent = this.populateData(areas, true, false);
		} else {
			/*waise toh ye page khulna hi nahin chahiye kyuki area leader
             aur facilitator is page ko ni dekh skte. */
			TableComponent = this.populateData(areas);
		}
		const frontlinerStr = modalData?.frontliners?.reduce((acc, curr) => {
			return acc + ` [${curr.initiatedName || curr.name}]`
		}, "")
		return (
			<div className="manage-area-container">
				{TableComponent}
				{modalData && (
					<Modal
						open={!!modalData}
						onClose={this.onModalClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						{modalType === "VIEW" ? (
							<div className="c-modal">
								<div className="area-code">{modalData.code}</div>
								<div className="area-name">{modalData.name}</div>
								<div className="area-leader">
									{(areaLeader && (<span><b>Area Leader: </b>{areaLeader}</span>)) ||
										"No Area Leader assigned"}
								</div>
								<div className="area-director">
									{(asstDirector && (<span><b>Assistant Director: </b>{asstDirector}</span>)) ||
										"No Assistant Director assigned"}
								</div>
								{modalData?.facilitators?.length ? (
									<div className="facilitators">
										<b>Facilitators:</b>
										{modalData?.facilitators?.map(
											(f) => ` [${f.initiatedName || f.name}] `
										)}
									</div>
								) : (
									<div className="facilitators">No Facilitators assigned</div>
								)}
								{modalData?.frontliners?.length ? (
									<div className="frontliners">
										<b>Frontliners:</b>
										{frontlinerStr}
									</div>
								) : (
									<div className="frontliners">No Frontliners assigned</div>
								)}
							</div>
						) : (
							<div className="c-modal">
								<UpdateAreaPopup
									areaId={modalData._id}
									areaCode={modalData.code}
									areaName={modalData.name}
									areaLeader={modalData.areaLeader}
									assistantDirector={modalData.assistantDirector}
									facilitators={modalData.facilitators}
									frontliners={modalData.frontliners}
									closeHandler={this.onModalClose}
									updateTableDataHandler={this.updateTableDataHandler}
									userRole={user.userRole}
								/>
							</div>
						)}
					</Modal>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	areas: state.areaReducer.areas,
});

const mapDispatchToProps = {
	getAreasAction,
	showDialog,
	closeDialog,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(ManageAreaContainer));
