import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	MenuItem,
	Modal,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import React from 'react'
import BlessedIcon from '../../../assets/icons/Blessed'
import { calculateTotal, getAmounts } from '../utils'
import '../style.scss'
import { EVENTS_UI_CONFIG } from '../../../data/event'

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	modal: {
		display: 'flex',
		padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		borderRadius: '8px',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})
class AdditionalInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			transportation: true,
			accomodation: 0,
			donation: 0,
			outfit: 0,
			donationModal: false,
			event: props.event,
			tokenAmount: 500,
		}
	}
	a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	onChange = (name, value) => {
		const { updateAmount } = this.props
		const amounts = getAmounts({ ...this.state, [name]: value })
		updateAmount(calculateTotal(amounts))
		this.setState({
			[name]: value,
		})
	}

	componentDidMount() {
		const { updateAmount } = this.props
		const amounts = getAmounts({ ...this.state })
		updateAmount(calculateTotal(amounts))
	}

	render() {
		const {
			transportation,
			accomodation,
			outfit,
			donation,
			donationModal,
			tokenAmount,
		} = this.state
		const { classes, event } = this.props
		const eventId = this.props.eventId
		const eventUiconfig = EVENTS_UI_CONFIG.find(
			(event) => event.uuid === eventId
		)
		const accomodationOptions =
			event?.accomodationCategory &&
			Object.keys(event.accomodationCategory)
		return (
			<>
				<div className={classes.root}>
					<Modal
						open={donationModal}
						onClose={() =>
							this.setState({
								donationModal: false,
							})
						}
						className={classes.modal}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div className={classes.paper}>
							<BlessedIcon />
							<Typography
								variant="h3"
								style={{
									marginBottom: '20px',
									textAlign: 'center',
								}}
							>
								Your little contribution can help other devotees
								to take part in Viplava Fest. Kindly volunteer
								in this act of goodwill.
							</Typography>
							<form
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<FormControl>
									<FormLabel>Donation</FormLabel>
									<RadioGroup
										aria-label="donation"
										name="donation"
										value={donation}
										onChange={(e) =>
											this.setState({
												donation: +e.target.value,
											})
										}
										row
									>
										<FormControlLabel
											value={100}
											control={<Radio />}
											label="₹100"
										/>
										<FormControlLabel
											value={500}
											control={<Radio />}
											label="₹500"
										/>
										<FormControlLabel
											value={1000}
											control={<Radio />}
											label="₹1000"
										/>
									</RadioGroup>
								</FormControl>
								<TextField
									label="Any other donation amount"
									variant="outlined"
									defaultValue={donation}
									TabPa
									value={donation}
									name="donation"
									onChange={(e) =>
										this.onChange(
											e.target.name,
											+e.target.value
										)
									}
									fullWidth
									type="number"
								/>
								<Button
									variant="contained"
									color="primary"
									onClick={() =>
										this.props.onSubmit({
											...getAmounts(this.state),
											accomodationCategory: accomodation,
											outfitCount: outfit,
										})
									}
									style={{ marginTop: '20px' }}
								>
									Add Donation of ₹{donation}
								</Button>
							</form>
						</div>
					</Modal>
				</div>
				<div>
					<Typography variant={'h5'}>Accomodation</Typography>
					<p>
						<b>
							Dormitory ( Hall - 10 To 100 occupancy , Common
							Bathrooms)
						</b>
					</p>
					<p>
						<b>
							Room (Non AC Room, 3 To 4 occupancy ,Attached
							Bathroom)
						</b>
					</p>
					{/* <p>
						<b>
							Premium Room( AC Room , Double Occupancy , attached
							bathroom)
						</b>
					</p> */}
				</div>
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="demo-simple-select-outlined-label">
						Accomodation
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={accomodation}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						label="Accomodation"
						name="accomodation"
					>
						{accomodationOptions?.map((key) => (
							<MenuItem
								value={event?.accomodationCategory[key].id}
							>
								{event?.accomodationCategory[key].name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Typography variant={'h5'}>Transportation</Typography>
				<p>
					<b>{event.transportationDescription}</b>
				</p>
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="demo-simple-select-outlined-label">
						Transportation
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={transportation}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						label="Transportation"
						name="transportation"
					>
						<MenuItem value={true}>Yes</MenuItem>
						<MenuItem value={false}>No</MenuItem>
					</Select>
				</FormControl>
				{!!event.outfitAmount && (
					<Typography variant={'h5'}>Outfit</Typography>
				)}
				{!!event.outfitCutAmount && (
					<p>
						<b>
							Dhoti Kurta worth{' '}
							<strike>₹{event.outfitCutAmount}</strike> is now{' '}
							{event.outfitAmount === 0
								? 'FREE'
								: `at ₹${event.outfitAmount}`}{' '}
							for you!{' '}
						</b>
					</p>
				)}
				{!!event.outfitAmount && (
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="demo-simple-select-outlined-label">
							No. of Dhoti Kurta Set needed
						</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							value={outfit}
							onChange={(e) =>
								this.onChange(e.target.name, e.target.value)
							}
							label="No. of Dhoti Kurta Set needed"
							name="outfit"
						>
							<MenuItem value={0}>0</MenuItem>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
						</Select>
					</FormControl>
				)}
				{eventUiconfig.priceTallyImg && (
					<img src={eventUiconfig.priceTallyImg} />
				)}
				{eventUiconfig.allowPartialPayment && (
					<>
						<Typography variant={'h5'}>
							Booking Confirmation Amount
						</Typography>
						<p>
							<b>
								You can pay atleast Rs.500 to confirm your seat,
								rest you can pay later on.
							</b>
						</p>
						<TextField
							label="Booking Confirmation Amount"
							variant="outlined"
							defaultValue={tokenAmount}
							value={tokenAmount}
							name="tokenAmount"
							disabled={this.disabled}
							onChange={(e) =>
								this.onChange(e.target.name, +e.target.value)
							}
							error={!tokenAmount || tokenAmount < 500}
							helperText={
								!!(!tokenAmount || tokenAmount < 500) && (
									<span>
										Please pay atleast Rs.500 for seat
										confirmation
									</span>
								)
							}
							fullWidth
						/>
					</>
				)}
				{/* <TextField
            label="Donation"
            variant="outlined"
            defaultValue={donation}
            TabPa
            value={donation}
            name="donation"
            onChange={(e) => this.onChange(e.target.name, e.target.value)}
            fullWidth
            type="number"
          /> */}
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						if (eventUiconfig.allowDonation) {
							this.setState({
								donationModal: true,
							})
						} else {
							if (tokenAmount >= 500) {
								this.props.onSubmit({
									...getAmounts(this.state),
									accomodationCategory: accomodation,
									outfitCount: outfit,
									transportationAmount: transportation
										? 1
										: 0,
									tokenAmount,
								})
							}
						}
					}}
				>
					Proceed to pay
				</Button>
			</>
		)
	}
}

export default withStyles(styles)(AdditionalInfo)
