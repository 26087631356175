import React, { Component } from "react";
import "./style.scss";
import NavbarComponent from "../Navbar";

class FooterComponent extends Component {

	render() {
		return (
			<footer className="app-footer">
				<NavbarComponent {...this.props} showLogin={false} />
			</footer>
		);
	}
}

export default FooterComponent;
